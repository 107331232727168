@charset "UTF-8";
div.header-container {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 10%, transparent);
  height: 65px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
}
div.header-container.background {
  background-color: var(--bg-color);
}
div.header-container div.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.header-container div.wrapper div.menuIconContainer {
  display: none;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.menuIconContainer {
    display: block;
    width: 30%;
  }
  div.header-container div.wrapper div.menuIconContainer svg {
    color: var(--text-color);
    width: 30px !important;
    height: 30px !important;
  }
}
div.header-container div.wrapper div.leftContainer {
  display: flex;
  align-items: center;
  width: 50%;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.leftContainer {
    width: 70%;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.leftContainer {
    width: 30%;
    justify-content: center;
  }
}
div.header-container div.wrapper div.leftContainer div.logoContainer {
  margin-inline-end: 40px;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.leftContainer div.logoContainer {
    margin-inline-end: 0;
  }
}
div.header-container div.wrapper div.leftContainer div.logoContainer h1 {
  width: 100px;
  max-height: 40px;
}
@media all and (max-width: 480px) {
  div.header-container div.wrapper div.leftContainer div.logoContainer h1 {
    width: 100px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.leftContainer div.logoContainer h1 {
    width: 100px;
  }
}
div.header-container div.wrapper div.leftContainer div.logoContainer h1 img {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 3;
  -o-object-fit: contain;
     object-fit: contain;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.leftContainer nav {
    display: none;
  }
}
div.header-container div.wrapper div.leftContainer nav ul.navLinks {
  display: flex;
}
div.header-container div.wrapper div.leftContainer nav ul.navLinks li.link {
  font-size: 14px;
  margin-inline-end: 18px;
  color: var(--text-color);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-weight: 300;
}
div.header-container div.wrapper div.leftContainer nav ul.navLinks li.link:hover {
  color: var(--text-hover);
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.leftContainer ul.hide.hide {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  transition: 0.2s ease-in-out;
  position: relative;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.rightContainer {
    width: 40%;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer {
    width: 30%;
  }
}
div.header-container div.wrapper div.rightContainer div.inputContainer {
  position: relative;
  width: 5%;
  margin-inline-end: 20px;
  margin-top: 5px;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.inputContainer input {
  border: none;
  outline: none;
  width: 85%;
  margin-inline-end: auto;
  background-color: transparent;
  font-size: 13px;
  color: var(--grey-color);
  caret-color: var(--grey-color);
  display: none;
}
div.header-container div.wrapper div.rightContainer div.inputContainer input::-moz-placeholder {
  color: #cac9c8;
  font-size: 13px;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
div.header-container div.wrapper div.rightContainer div.inputContainer input::placeholder {
  color: #cac9c8;
  font-size: 13px;
  transition: 0.3s ease-in-out;
}
div.header-container div.wrapper div.rightContainer div.inputContainer.showInput {
  background-color: var(--black-color);
  padding: 8px 6px;
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: 1px solid var(--white-color);
  margin-top: 0;
}
div.header-container div.wrapper div.rightContainer div.inputContainer.showInput input {
  display: block;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer.showInput {
    display: none;
  }
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer.showInput {
    width: 70%;
  }
  div.header-container div.wrapper div.rightContainer div.inputContainer.showInput input {
    display: block;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer {
    width: 12%;
    justify-content: center;
    padding: 10px 8px;
  }
  div.header-container div.wrapper div.rightContainer div.inputContainer.showInput {
    width: 57%;
    justify-content: space-between;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.inputContainer:hover {
  border-color: #c5c5c8;
}
div.header-container div.wrapper div.rightContainer div.inputContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color) !important;
  margin-inline-end: 6px;
  cursor: pointer;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer {
  position: absolute;
  top: 47px;
  z-index: 9;
  background-color: var(--transparent-color);
  padding: 15px;
  width: 100%;
  inset-inline-start: 0;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection {
  margin-bottom: 20px;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection h6.heading {
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 10px;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection h6.heading span.count {
  font-weight: normal;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list {
  display: flex;
  margin-bottom: 3px;
  cursor: pointer;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer {
  display: flex;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.left {
  margin-inline-end: 10px;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.left div.imageContainer {
  width: 50px;
  height: 50px;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.left div.imageContainer img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.right h1.title {
  font-size: 14px;
  color: var(--text-color);
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.right span.director {
  font-size: 12px;
  color: var(--grey-color);
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer div.seeAll {
  text-align: center;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
  margin-top: 20px;
  cursor: pointer;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchSuggestionsContainer div.seeAll button {
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 700;
  cursor: pointer;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.closeIcon {
  position: absolute;
  inset-inline-end: 5px;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.closeIcon svg {
  width: 16px !important;
  height: 16px !important;
}
div.header-container div.wrapper div.rightContainer div.inputContainer div.searchLoading {
  position: absolute;
  inset-inline-end: 30px;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  color: white;
  padding: 13px 0;
  padding-inline-end: 26px;
}
@media (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.languageDropdown {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.languageDropdown:hover {
  color: gray;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.language {
  font-size: 14px;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 210px;
  background-color: var(--transparent-color);
  padding-top: 15px;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions::before {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList {
  display: flex;
  flex-direction: column;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption {
  font-size: 12px;
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true] {
  color: var(--gradient-left-color);
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true]:after {
  content: "✔";
  margin-inline-start: auto;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption:hover {
  color: var(--gradient-left-color);
}
div.header-container div.wrapper div.rightContainer ul.browse {
  border-inline-end: 1px solid var(--border-color);
  padding: 13px 0;
}
div.header-container div.wrapper div.rightContainer ul.browse li {
  cursor: pointer;
  padding: 0 30px;
}
div.header-container div.wrapper div.rightContainer ul.browse li:hover span {
  color: var(--text-hover);
}
div.header-container div.wrapper div.rightContainer ul.browse li.active span {
  color: var(--text-hover);
}
div.header-container div.wrapper div.rightContainer ul.browse li span {
  font-size: 12px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
  color: var(--text-color);
}
div.header-container div.wrapper div.rightContainer div.userIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
div.header-container div.wrapper div.rightContainer div.userIcon:hover {
  padding-bottom: 20px;
  padding-top: 20px;
}
div.header-container div.wrapper div.rightContainer div.userIcon:hover svg {
  transform: rotate(180deg);
}
div.header-container div.wrapper div.rightContainer div.userIcon:hover div.menuListContainer {
  display: block;
  animation: fadeIn 0.2s ease-in-out;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.imageContainer {
  width: 28px;
  margin-inline-end: 2px;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.header-container div.wrapper div.rightContainer div.userIcon.noActive svg {
  transform: rotate(0deg);
}
div.header-container div.wrapper div.rightContainer div.userIcon div.downArrow {
  line-height: 0;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.downArrow svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer {
  width: 17rem;
  position: absolute;
  inset-inline-end: 0;
  top: 56px;
  padding: 10px 0 0;
  background-color: var(--transparent-color);
  border: 1px solid var(--border-color);
  line-height: 0;
  margin-inline-start: 20px;
  animation: fadeOut 0.3s ease-in-out;
  display: none;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer.noActive {
  display: none;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer div.arrow {
  position: absolute;
  top: -18px;
  inset-inline-end: 20px;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer div.arrow svg {
  width: 25px !important;
  height: 25px !important;
  transform: rotate(180deg);
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer svg {
  width: 18px !important;
  height: 18px !important;
  color: var(--text-color);
  cursor: pointer;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer ul li {
  cursor: pointer;
  padding: 8px 10px;
  display: flex;
  align-items: center;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer ul li.logOut, div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer ul li.logIn {
  padding: 20px 15px;
  border-top: 1px solid var(--border-color);
  justify-content: center;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer ul li div.icon {
  width: 18px;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer ul li div.icon img {
  width: 100%;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer ul li div.icon {
  margin-inline-end: 10px;
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer ul li span {
  font-size: 12px;
  color: var(--text-color);
}
div.header-container div.wrapper div.rightContainer div.userIcon div.menuListContainer ul li:hover span {
  text-decoration: underline;
}
div.header-container div.wrapper div.rightContainer h4 {
  color: var(--text-color);
  font-size: 12px;
  margin-inline-start: 10px;
}
div.header-container div.wrapper div.rightContainer div.buttonContainer {
  margin-inline-start: 30px;
}
@media all and (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 15px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 7px;
  }
}
div.header-container div.wrapper div.rightContainer div.buttonContainer button.signIn {
  background-color: transparent;
  font-weight: 700;
  font-size: 12px;
  color: var(--text-color);
  cursor: pointer;
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer button.signIn {
    font-size: 12px;
  }
}

div.userInfoContainer {
  position: fixed;
  inset-inline-end: 30px;
  width: 20rem;
  top: 15%;
  background-color: rgb(37, 42, 52);
  border-radius: 8px;
  display: none;
  z-index: 9999;
}
div.userInfoContainer.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.userInfoContainer {
    top: 15%;
  }
}
@media all and (max-width: 640px) {
  div.userInfoContainer {
    width: 84%;
  }
}
div.userInfoContainer div.top {
  padding: 20px 20px 0;
}
div.userInfoContainer div.top ul li {
  border-bottom: 1px solid var(--border-color);
  padding: 15px 0;
  cursor: pointer;
}
div.userInfoContainer div.top ul li:first-child {
  padding-top: 0;
}
div.userInfoContainer div.top ul li:last-child {
  border-bottom: none;
}
div.userInfoContainer div.top ul li span {
  color: var(--white-color);
  font-weight: 600;
  font-size: 15px;
}
div.userInfoContainer div.bottom {
  background: linear-gradient(180deg, #10141f, rgba(16, 20, 31, 0));
  padding: 15px;
}
div.userInfoContainer div.bottom div.signOutContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: var(--border-color);
  border-radius: 8px;
  cursor: pointer;
}
div.userInfoContainer div.bottom div.signOutContainer svg {
  color: var(--white-color);
  width: 22px !important;
  height: 22px !important;
  margin-inline-end: 10px;
}
div.userInfoContainer div.bottom div.signOutContainer span {
  color: var(--white-color);
  font-weight: 600;
  font-size: 15px;
}

@media all and (max-width: 768px) {
  div.overlayOne.active {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--overlay);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
  }
}

div.overlayOne.active {
  display: flex;
}

div.browseMore {
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMore {
    display: none;
  }
}
div.browseMore div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}
div.browseMore div.browseMoreContainer {
  animation: fadeIn 0.7s ease-in-out;
  position: fixed;
  top: 68px;
  inset-inline-start: 8%;
  background-color: var(--transparent-color);
  display: flex;
  border-radius: 6px;
  z-index: 9999;
}
div.browseMore div.browseMoreContainer div.arrow {
  position: absolute;
  top: -18px;
  inset-inline-end: 40px;
}
@media all and (max-width: 2500px) {
  div.browseMore div.browseMoreContainer div.arrow {
    inset-inline-end: 70px;
  }
}
@media all and (max-width: 1600px) {
  div.browseMore div.browseMoreContainer div.arrow {
    inset-inline-end: 60px;
  }
}
@media all and (max-width: 1200px) {
  div.browseMore div.browseMoreContainer div.arrow {
    inset-inline-end: 50px;
  }
}
div.browseMore div.browseMoreContainer div.arrow svg {
  width: 25px !important;
  height: 25px !important;
  transform: rotate(180deg);
  color: var(--text-color);
}
@media all and (max-width: 2500px) {
  div.browseMore div.browseMoreContainer {
    top: 68px;
    inset-inline-start: 6%;
  }
}
@media all and (max-width: 1400px) {
  div.browseMore div.browseMoreContainer {
    top: 68px;
  }
}
div.browseMore div.browseMoreContainer h1.categoryName {
  font-size: 14px;
  margin-bottom: 20px;
  color: var(--grey-color);
}
div.browseMore div.browseMoreContainer ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
div.browseMore div.browseMoreContainer ul li {
  margin-bottom: 8px;
  cursor: pointer;
}
div.browseMore div.browseMoreContainer ul li:hover span {
  color: var(--text-hover);
}
div.browseMore div.browseMoreContainer ul li span {
  font-size: 14px;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
}
div.browseMore div.browseMoreContainer div.left {
  padding: 30px 25px;
  overflow-y: scroll;
  height: 500px;
}
div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar {
  width: 8px;
}
div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar-thumb:hover {
  background-color: rgb(125, 128, 128);
}
div.browseMore div.browseMoreContainer div.center {
  width: 40%;
  background-color: #f0f3f5;
  padding: 30px 25px;
}
div.browseMore div.browseMoreContainer div.center div.sections {
  display: flex;
  justify-content: space-between;
}
div.browseMore div.browseMoreContainer div.center div.sections div.leftSection {
  width: 50%;
}
div.browseMore div.browseMoreContainer div.center div.sections div.rightSection {
  width: 50%;
}
div.browseMore div.browseMoreContainer div.right {
  width: 40%;
  padding: 30px 25px;
}

div.browseMore.active {
  display: block;
}
@media all and (max-width: 768px) {
  div.browseMore.active {
    display: none;
  }
}

div.browseMoreResponsive div.searchArea {
  display: none;
  z-index: 9999999;
  position: fixed;
  width: 50%;
  background: var(--transparent-color);
  padding: 15px;
  top: 0;
  inset-inline-start: -100%;
  border-bottom: 1px solid var(--border-color);
  transition: 0.3s ease-in-out;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.searchArea {
    display: block;
  }
  div.browseMoreResponsive div.searchArea.active {
    inset-inline-start: 0;
  }
  div.browseMoreResponsive div.searchArea div.inputContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    position: relative;
  }
  div.browseMoreResponsive div.searchArea div.inputContainer input {
    display: block;
    outline: none;
    border: none;
    background-color: transparent;
    width: 93%;
    color: var(--text-color);
    font-size: 16px;
  }
  div.browseMoreResponsive div.searchArea div.inputContainer svg {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 640px) {
  div.browseMoreResponsive div.searchArea {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.browseMoreResponsive div.searchArea {
    width: 75%;
  }
}
div.browseMoreResponsive div.searchArea div.closeIcon {
  position: absolute;
  inset-inline-end: 5px;
  top: 30%;
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.searchArea div.closeIcon {
    display: block;
  }
}
div.browseMoreResponsive div.searchArea div.closeIcon svg {
  width: 16px !important;
  height: 16px !important;
  color: var(--text-color);
}
div.browseMoreResponsive div.searchArea div.searchLoading {
  position: absolute;
  inset-inline-end: 30px;
  bottom: 30px;
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.searchArea div.searchLoading {
    display: block;
  }
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium {
  position: absolute;
  top: 47px;
  z-index: 99999;
  background-color: var(--transparent-color);
  padding: 15px;
  width: 100%;
  inset-inline-start: 0;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium {
    display: block;
  }
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium h6.heading {
  font-size: 12px;
  color: var(--grey-color);
  margin-bottom: 10px;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium h6.heading span.count {
  font-weight: normal;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list {
  display: flex;
  margin-bottom: 3px;
  cursor: pointer;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.left {
  margin-inline-end: 10px;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.left div.imageContainer {
  width: 50px;
  height: 50px;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.left div.imageContainer img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.right h1.title {
  font-size: 13px;
  color: var(--text-color);
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.right span.director {
  font-size: 12px;
  color: var(--grey-color);
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium div.seeAll {
  text-align: center;
  border-top: 1px solid var(--border-color);
  padding: 10px 0;
  margin-top: 20px;
  cursor: pointer;
  background-color: var(--transparent-color);
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium div.seeAll button {
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 700;
  cursor: pointer;
}
div.browseMoreResponsive div.container {
  position: fixed;
  inset-inline-start: -100%;
  width: 35%;
  background: var(--transparent-color);
  padding: 70px 30px 35px;
  z-index: 999999;
  top: 0;
  bottom: 0;
  overflow: scroll;
  transition: 0.3s ease-in-out;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.browseMoreResponsive div.container::-webkit-scrollbar {
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.container.active {
    inset-inline-start: 0;
  }
}
@media all and (max-width: 980px) {
  div.browseMoreResponsive div.container {
    width: 45%;
  }
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.container {
    width: 50%;
    inset-inline-start: -100%;
  }
}
@media all and (max-width: 640px) {
  div.browseMoreResponsive div.container {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.browseMoreResponsive div.container {
    width: 75%;
  }
}
div.browseMoreResponsive div.container div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.browseMoreResponsive div.container div.head div.left h1 {
  color: var(--text-hover);
  font-size: 15px;
  font-weight: 700;
}
div.browseMoreResponsive div.container div.head div.right svg {
  color: var(--text-color);
  width: 23px !important;
  height: 23px !important;
}
div.browseMoreResponsive div.container ul.nav {
  margin-top: 20px;
}
div.browseMoreResponsive div.container ul.nav li {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 14px;
}
div.browseMoreResponsive div.container div.top {
  margin-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}
div.browseMoreResponsive div.container div.top h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--grey-color);
  font-weight: 700;
}
div.browseMoreResponsive div.container div.top ul li {
  margin-bottom: 8px;
}
div.browseMoreResponsive div.container div.top ul li span {
  font-size: 14px;
  color: var(--text-color);
}
div.browseMoreResponsive div.container div.top ul li:hover span {
  color: var(--text-hover);
}
div.browseMoreResponsive div.container div.center {
  padding: 30px 0;
  border-bottom: 1px solid var(--border-color);
}
div.browseMoreResponsive div.container div.center h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--grey-color);
  font-weight: 700;
}
div.browseMoreResponsive div.container div.center ul li {
  margin-bottom: 8px;
}
div.browseMoreResponsive div.container div.center ul li span {
  font-size: 14px;
  color: var(--text-color);
}
div.browseMoreResponsive div.container div.bottom {
  padding: 30px 0;
}
div.browseMoreResponsive div.container div.bottom h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--grey-color);
  font-weight: 700;
}
div.browseMoreResponsive div.container div.bottom ul li {
  margin-bottom: 8px;
}
div.browseMoreResponsive div.container div.bottom ul li span {
  font-size: 14px;
  color: #fff;
}
div.browseMoreResponsive div.container div.languageDropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  color: white;
  padding: 15px 0;
}
div.browseMoreResponsive div.container div.languageDropdown:hover {
  color: gray;
}
div.browseMoreResponsive div.container div.languageDropdown h1.language {
  font-size: 15px;
  font-weight: bold;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 210px;
  background: var(--transparent-color);
  padding-top: 15px;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions::before {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList {
  display: flex;
  flex-direction: column;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption {
  font-size: 14px;
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true] {
  color: var(--gradient-left-color);
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true]:after {
  content: "✔";
  margin-inline-start: auto;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption:hover {
  color: var(--gradient-left-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
div.featured {
  overflow: hidden;
  width: 100%;
  position: relative;
  animation: fadeIn 0.6s ease-in-out;
}
div.featured .swiper {
  height: 100%;
}
div.featured .swiper-button-disabled {
  display: none !important;
}
div.featured .swiper-pagination-bullet {
  background-color: var(--heading-color) !important;
  opacity: 0.8 !important;
}
div.featured .swiper-pagination-bullet-active {
  background-color: var(--grey-color) !important;
  opacity: 1 !important;
}
div.featured div.bgImageContainer {
  width: 100%;
  display: block;
  height: 100%;
}
div.featured div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.featured div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.featured div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.featured div.bottomVignette {
  background-color: transparent;
  background-image: linear-gradient(180deg, hsla(0, 0%, 8%, 0) 0, hsla(0, 0%, 8%, 0.15) 15%, hsla(0, 0%, 8%, 0.35) 29%, hsla(0, 0%, 8%, 0.58) 44%, #141414 68%, #141414);
  background-position: 0 top;
  background-repeat: repeat-x;
  background-size: 100% 100%;
  bottom: -11px;
  height: 100px;
  opacity: 1;
  top: auto;
  width: 100%;
  z-index: 9;
  position: absolute;
}
div.featured div.contents {
  position: absolute;
  bottom: 30px;
  width: 92%;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
div.featured div.contents span.line {
  height: 1px;
  background-color: var(--border-color);
  width: 30%;
  display: block;
  margin-bottom: 20px;
}
@media all and (max-width: 768px) {
  div.featured div.contents span.line {
    display: none;
  }
}
div.featured div.contents h1.title {
  font-size: 55px;
  color: var(--white-color);
  margin-bottom: 30px;
  animation: slideUp 0.5s ease-in-out;
}
@media all and (max-width: 768px) {
  div.featured div.contents h1.title {
    font-size: 36px;
    margin-bottom: 0;
  }
}
div.featured div.contents div.containers {
  display: flex;
  align-items: center;
}
div.featured div.contents div.containers div.left {
  width: 8%;
  margin-inline-end: 30px;
}
@media all and (max-width: 1200px) {
  div.featured div.contents div.containers div.left {
    width: 12%;
  }
}
@media all and (max-width: 980px) {
  div.featured div.contents div.containers div.left {
    width: 14%;
  }
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.left {
    display: none;
  }
}
div.featured div.contents div.containers div.left div.activeThumb {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.featured div.contents div.containers div.right {
  width: 78%;
}
div.featured div.contents div.containers div.right div.metaData {
  padding: 35px 0;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData {
    padding: 20px 0;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas {
  animation: slideUp 0.5s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.datas span {
  color: var(--grey-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData div.datas span {
    font-size: 15px;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas span.year {
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration {
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration::before {
  content: "·";
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.certificate {
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 12px;
  font-weight: 700;
  color: var(--white-color);
  border-radius: 4px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres {
  margin-top: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span {
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span::before {
  content: "·";
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:first-child::before {
  display: none;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:last-child {
  margin-inline-end: 0;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer {
  margin-top: 30px;
  background: var(--text-hover);
  padding: 12px 24px;
  width: 13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  color: var(--white-color);
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer.no-flex {
  justify-content: start;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer svg {
  margin-inline-end: 10px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer:hover {
  background: var(--button-hover);
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span {
  color: var(--white-color);
  font-weight: 700;
  font-size: 15px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span.free {
  display: inline-block;
  padding: 1px 6px;
  color: #000;
  background-color: var(--white-color);
  border-radius: 30px;
  font-size: 10px;
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

div.homePage {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: var(--bg-color);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-bottom: 49px;
}
div.homePage::-webkit-scrollbar {
  display: none;
}
div.homePage .css-iz3z40-MuiDialog-container {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
div.homePage .css-iz3z40-MuiDialog-container::-webkit-scrollbar {
  display: none !important;
}
div.homePage .swiper {
  overflow: visible;
}
div.homePage div.allCategories {
  margin-top: 25px;
}
@media all and (max-width: 1500px) {
  div.homePage div.allCategories {
    margin-top: -226px;
  }
}
@media all and (max-width: 4500px) {
  div.homePage div.allCategories {
    margin-top: -226px;
  }
}
@media all and (max-width: 1400px) {
  div.homePage div.allCategories {
    margin-top: -180px;
  }
}
@media all and (max-width: 1200px) {
  div.homePage div.allCategories {
    margin-top: -160px;
  }
}
@media all and (max-width: 980px) {
  div.homePage div.allCategories {
    margin-top: -147px;
  }
}
@media all and (max-width: 768px) {
  div.homePage div.allCategories {
    margin-top: -25px;
  }
}
@media all and (max-width: 640px) {
  div.homePage div.allCategories {
    margin-top: -11px;
  }
}
@media all and (max-width: 480px) {
  div.homePage div.allCategories {
    margin-top: -37px;
  }
}
div.homePage div.allCategoriesWithoutBanner {
  margin-top: 230px;
}
@media all and (max-width: 980px) {
  div.homePage div.allCategoriesWithoutBanner {
    margin-top: 70px;
  }
}
div.homePage div.showInformationContainer {
  position: fixed;
  z-index: 999999999;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--overlay);
  padding-top: 100px;
  display: none;
  overflow-y: scroll;
}
div.homePage div.showInformationContainer.active {
  display: block;
  animation: fadeIn 0.6s ease-in-out;
}
div.homePage div.showInformationContainer.active div.info {
  transform: scale(1);
}
div.homePage div.showInformationContainer div.info {
  transform: scale(0);
  transition: 1s ease-in-out;
  background-color: var(--bg-color);
  width: 50%;
  margin: 0 auto;
  animation: pop 0.3s linear 1;
}
div.homePage div.showInformationContainer div.info::-webkit-scrollbar {
  border-radius: 8px;
  width: 6px;
}
div.homePage div.showInformationContainer div.info::-webkit-scrollbar-thumb {
  background-color: var(--button-color);
  border-radius: 30px;
}
div.homePage div.showInformationContainer div.info::-webkit-scrollbar-track {
  background-color: var(--overlay);
}
div.homePage div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}
div.homePage div.LoadMoreButtonContainer {
  text-align: center;
  margin-top: -130px;
}
@media all and (max-width: 980px) {
  div.homePage div.LoadMoreButtonContainer {
    margin-top: 20px;
  }
}
div.homePage div.LoadMoreButtonContainer button {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin: 0 auto;
}
div.homePage div.LoadMoreButtonContainer button:hover {
  background-color: var(--secondary-hover-color);
}
div.homePage div.LoadMoreButtonContainer button span {
  font-size: 14px;
}
@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}

div.categoriesContainer {
  position: relative;
  z-index: 9;
}
div.categoriesContainer.landscape.rowCount3 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 653px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 576px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 589px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 503px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 461px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 520px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 417px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 488px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 378px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 461px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 330px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 423px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 286px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 375px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 251px !important;
  }
}
div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 29rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    width: 5.5rem;
    height: 29rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 23rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 21rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 19rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 16.9rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 15.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 10.9rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 29rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    width: 5.5rem;
    height: 29rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 23rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 21rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 19rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 16.9rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 15.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 10.9rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount4 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 550px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 449px !important;
    margin-top: -157px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 499px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 398px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 363px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 440px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 342px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 407px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 301px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 386px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 268px !important;
    margin-top: -157px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 360px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 243px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 338px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 194px !important;
  }
}
div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 23rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    width: 5.5rem;
    height: 20rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 18rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 16rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 14rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 11.9rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 11.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 8.9rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 23rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    width: 5.5rem;
    height: 20rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 18rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 16rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 14rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 11.9rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 11.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 8.9rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount5 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 498px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 366px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 447px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 329px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 300px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 397px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 270px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 373px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 244px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 365px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 218px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 337px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 186px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 301px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 162px !important;
  }
}
div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 18rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    width: 5.5rem;
    height: 16rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 14rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 12rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 9.9rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 9.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 7.9rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 18rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    width: 5.5rem;
    height: 16rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 14rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 12rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 9.9rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 9.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 7.9rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount6 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 435px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 321px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 411px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 283px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 268px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 361px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 231px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 339px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 211px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 330px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 191px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 311px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 170px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 290px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 147px !important;
  }
}
div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 15rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    width: 5.5rem;
    height: 13rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 12rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 10rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 8.9rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 7.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 6.9rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 15rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    width: 5.5rem;
    height: 13rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 12rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 10rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 8.9rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 7.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 6.9rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount7 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 386px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 321px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 365px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 283px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 268px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 325px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 231px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 339px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 211px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 300px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 191px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 280px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 170px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 290px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 147px !important;
  }
}
div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 15rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    width: 5.5rem;
    height: 13rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 12rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 9rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 8rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 7.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 6rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 15rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    width: 5.5rem;
    height: 13rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 12rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 9rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 8rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 7.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 6rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount8 {
  margin-bottom: 280px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 360px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 321px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 355px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 283px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 320px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 268px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 300px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 231px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 315px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 211px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 300px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 191px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 285px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 170px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 290px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 147px !important;
  }
}
div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 11rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    width: 5.5rem;
    height: 11rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 8rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 7rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 8rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 7rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 6rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 5rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 11rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    width: 5.5rem;
    height: 11rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 8rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 7rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 8rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 7rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 6rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 5rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    display: none;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape .swiper-button-next, div.categoriesContainer.landscape .swiper-button-prev {
    display: none;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
div.categoriesContainer.portrait.rowCount3 {
  margin-bottom: 325px;
  margin-top: -161px;
}
div.categoriesContainer.portrait.rowCount3.lastChild {
  margin-bottom: 1000px !important;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1387px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1375px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1248px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1214px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1228px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1144px !important;
  }
}
@media all and (max-width: 2080px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1138px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1100px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1116px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1054px !important;
  }
}
@media all and (max-width: 1860px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1033px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 995px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1024px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 931px !important;
  }
}
@media all and (max-width: 1680px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 956px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 892px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 920px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 822px !important;
  }
}
@media all and (max-width: 1480px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 864px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 782px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 836px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 738px !important;
  }
}
@media all and (max-width: 1300px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 775px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 681px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 730px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 625px !important;
  }
}
@media all and (max-width: 1080px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 672px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 572px !important;
  }
}
div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 77rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    width: 5.5rem;
    height: 67rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 61rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 55rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 50rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 43.9rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 36.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 31.9rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 77rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    width: 5.5rem;
    height: 67rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 61rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 55rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 50rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 43.9rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 36.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 31.9rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount4 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 1125px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 1037px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 1018px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 915px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 931px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 829px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 840px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 751px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 787px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 690px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 709px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 598px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 658px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 521px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 559px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 433px !important;
  }
}
div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 59rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    width: 5.5rem;
    height: 51rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 46rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 41rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 37rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 32.9rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 28.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 23.9rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 59rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    width: 5.5rem;
    height: 51rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 46rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 41rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 37rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 32.9rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 28.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 23.9rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount5 {
  margin-bottom: 593px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 955px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 833px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 851px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 733px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 798px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 678px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 735px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 606px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 678px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 559px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 615px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 496px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 564px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 422px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 502px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 345px !important;
  }
}
div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
  right: -54px;
  height: 425px;
  width: 4%;
  margin-top: -22px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 46rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    width: 5.5rem;
    height: 41rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 37rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 34rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 31rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 26.9rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 24.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 18.9rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
  left: -54px;
  height: 422px;
  width: 4%;
  top: 5%;
  margin-top: -21px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 46rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    width: 5.5rem;
    height: 41rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 37rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 34rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 31rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 26.9rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 24.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 18.9rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount6 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 820px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 700px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 731px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 640px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 672px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 600px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 635px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 570px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 575px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 500px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 537px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 450px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 520px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 400px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 439px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 320px !important;
  }
}
div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
  right: -54px;
  height: 351px;
  width: 4%;
  top: 5%;
  margin-top: -22px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 38rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    width: 5.5rem;
    height: 36rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 31rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 29rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 25rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 22rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 20.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 17rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
  left: -54px;
  height: 352px;
  width: 4%;
  top: 5%;
  margin-top: -24px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 38rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    width: 5.5rem;
    height: 36rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 31rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 29rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 25rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 22rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 20.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 17rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount7 {
  margin-bottom: 325px;
  margin-top: 150px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 525px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 498px;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 435px;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 395px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 350px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 315px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 275px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 235px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
  right: -54px;
  height: 300px;
  width: 4%;
  margin-top: 0px;
  top: 43% !important;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 32rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    width: 5.5rem;
    height: 31rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 27rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 25rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 22rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 20rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 17rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 14.9rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
  left: -54px;
  height: 300px;
  width: 4%;
  top: 5%;
  margin-top: -23px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 32rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    width: 5.5rem;
    height: 31rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 27rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 25rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 22rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 20rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 17rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 14.9rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount8 {
  margin-bottom: 356px;
  margin-top: 130px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 465px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 430px;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 390px;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 360px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 320px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 285px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 240px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 232px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
  right: -54px;
  height: 261px;
  width: 4%;
  top: 4%;
  margin-top: -22px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 28rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    width: 5.5rem;
    height: 27rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 24rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 21rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 19rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 17rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 15rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 13rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
  left: -54px;
  height: 260px;
  width: 4%;
  top: 5%;
  margin-top: -22px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 28rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    width: 5.5rem;
    height: 27rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 24rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 21rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 19rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 17rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 15rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 13rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait .swiper-button-next, div.categoriesContainer.portrait .swiper-button-prev {
  right: 20px;
  margin-top: 50px;
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait .swiper-button-next, div.categoriesContainer.portrait .swiper-button-prev {
    display: none;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
div.categoriesContainer:hover {
  z-index: 99;
}
div.categoriesContainer.hideArrow div.itemsContainer div.swiper div.swiper-wrapper {
  z-index: 99;
}
div.categoriesContainer .swiper-slide {
  z-index: 9;
}
@media all and (min-width: 981px) {
  div.categoriesContainer .swiper-slide.landscape.hover:hover {
    z-index: 99 !important;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square {
    border-radius: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6666666667);
    transform: translateY(-60px) scale(1.4);
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square div.imageContainer img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square div.continueWatchingBar {
    display: none;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square div.continueWatchingContainer {
    display: flex;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover:first-child div.show div.showCardContainer div.square {
    transform: translateY(-60px) scale(1.4) !important;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover:last-child div.show div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.4);
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover.rtl:last-child div.show div.showCardContainer div.square {
    transform: translateY(-60px) scale(1.4) !important;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover.rtl:first-child div.show div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.4);
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover {
    z-index: 99 !important;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square {
    border-radius: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6666666667);
    transform: translateY(-60px) scale(1.2);
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square div.imageContainer img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square div.continueWatchingBar {
    display: none;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square div.continueWatchingContainer {
    display: flex;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover:first-child div.show div.showCardContainer div.square {
    transform: translateY(-60px) scale(1.2) !important;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover:last-child div.show div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover.rtl:last-child div.show div.showCardContainer div.square {
    transform: translateY(-60px) scale(1.2) !important;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover.rtl:first-child div.show div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
div.categoriesContainer:hover .swiper-button-next::after {
  opacity: 1;
}
div.categoriesContainer:hover .swiper-button-prev::after {
  opacity: 1;
}
div.categoriesContainer .swiper-button-next::after {
  opacity: 0;
}
div.categoriesContainer .swiper-button-prev::after {
  opacity: 0;
}
div.categoriesContainer div.wrapper:hover div.itemsContainer div.categoryNameContainer span.seeAll {
  visibility: visible;
}
div.categoriesContainer div.itemsContainer {
  position: relative;
  width: 93%;
  margin: 0 auto;
  visibility: hidden;
}
div.categoriesContainer div.itemsContainer.showSlider {
  visibility: visible;
  animation: fadeIn 1.5s ease-in-out;
}
div.categoriesContainer div.itemsContainer:hover div.categoryNameContainer div.exploreAll div.rightArrow {
  opacity: 1;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer {
  cursor: pointer;
  display: inline-block;
  align-items: center;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer h1 {
  font-size: 19px;
  color: var(--text-color);
  max-width: 99%;
  font-weight: 500;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.categoryNameContainer h1 {
    font-size: 16px;
  }
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer:hover div.exploreAll div.seeAll {
  opacity: 1;
  max-width: 100%;
  transform: translateX(10px);
  padding-right: 35%;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer:hover div.exploreAll div.rightArrow svg {
  width: 10px !important;
  height: 10px !important;
  transform: translateX(10px) rotate(-90deg);
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer:hover div.exploreAll div.rightArrow svg.rtl, div.categoriesContainer div.itemsContainer div.categoryNameContainer:hover div.exploreAll div.rightArrow svg.ltr {
  transform: rotate(90deg);
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.content {
  display: flex;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll {
  color: #54b9c5;
  display: flex;
  align-items: center;
  margin-top: -6px;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.seeAll {
  cursor: pointer;
  display: inline-block;
  font-size: 0.9vw;
  line-height: 0.8vw;
  margin-inline-end: 4px;
  max-width: 0;
  opacity: 0;
  font-weight: 500;
  transition: max-width 1s, opacity 1s, transform 0.75s;
  vertical-align: bottom;
  white-space: nowrap;
  transform: translateX(-10px);
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.seeAll {
    font-size: 1vw;
  }
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.rightArrow {
  opacity: 0;
  transition: 0.3s ease-in-out;
  line-height: 0;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.rightArrow svg {
  transform: rotate(-90deg);
  transition: 0.2s ease-in-out;
  width: 20px !important;
  height: 20px !important;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.rightArrow svg.rtl, div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.rightArrow svg.ltr {
  transform: rotate(90deg);
}
div.categoriesContainer div.itemsContainer div.show {
  transition: 0.4s ease-in-out;
  position: absolute;
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.show {
    position: unset;
  }
}
@media all and (min-width: 320px) {
  div.categoriesContainer div.itemsContainer div.show {
    width: 100%;
  }
}
div.categoriesContainer div.itemsContainer div.showMoreCard {
  background-color: var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  cursor: pointer;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    width: 250px;
    height: 259px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 225px;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 200px;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 180px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 162px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 142px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 120px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 100px;
    width: 150px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    transform: translateY(50%);
    height: 100px;
  }
}
@media all and (max-width: 640px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    transform: none;
    min-height: 208px;
    width: 200px;
  }
}
div.categoriesContainer div.itemsContainer div.showMoreCard:hover h2 {
  text-decoration: underline;
}
div.categoriesContainer div.itemsContainer div.showMoreCard h2 {
  color: var(--white-color);
  font-size: 18px;
  letter-spacing: 0.3px;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard h2 {
    font-size: 15px;
  }
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.hover.ltr:last-child div.show.visibleShowAllButton div.showCardContainer div.square {
  transform: none !important;
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.hover.ltr:last-child div.show.visibleShowAllButton div.showCardContainer div.square div.metaData {
  display: none !important;
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.hover.ltr:last-child div.show.visibleShowAllButton div.showCardContainer div.square div.metaData {
    display: block !important;
  }
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.hover.rtl:first-child div.show.visibleShowAllButton div.showCardContainer div.square {
  transform: none !important;
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.hover.rtl:first-child div.show.visibleShowAllButton div.showCardContainer div.square div.metaData {
  display: none !important;
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.hover.rtl:first-child div.show.visibleShowAllButton div.showCardContainer div.square div.metaData {
    display: block !important;
  }
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.ltr:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay {
  background-color: var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 99;
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.ltr:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay:hover h2 {
  text-decoration: underline;
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.ltr:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2 {
  color: var(--white-color);
  font-size: 20px;
  letter-spacing: 1px;
}
@media all and (max-width: 1400px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.ltr:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2 {
    font-size: 18px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.ltr:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2 {
    font-size: 15px;
  }
}
@media all and (max-width: 360px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.ltr:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2 {
    font-size: 18px;
  }
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.rtl:first-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay {
  background-color: var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 99;
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.rtl:first-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay:hover h2 {
  text-decoration: underline;
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.rtl:first-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2 {
  color: var(--white-color);
  font-size: 20px;
  letter-spacing: 1px;
}
@media all and (max-width: 1400px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.rtl:first-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2 {
    font-size: 18px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.rtl:first-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2 {
    font-size: 15px;
  }
}
@media all and (max-width: 360px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.rtl:first-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2 {
    font-size: 18px;
  }
}

div.categoriesContainer.ltr {
  direction: ltr;
  text-align: left;
}
div.categoriesContainer.ltr div.itemsContainer {
  text-align: right;
}
div.categoriesContainer.ltr div.itemsContainer div.categoryNameContainer {
  direction: rtl;
  text-align: right;
}

div.showCardContainer {
  width: 100%;
  cursor: pointer;
  position: relative;
}
div.showCardContainer.noPointer {
  cursor: default;
}
div.showCardContainer div.showMoreOverlay {
  display: none;
}
div.showCardContainer .square {
  width: 100%;
  transition: transform 500ms ease-out, border-radius 200ms ease-out;
}
div.showCardContainer .square.hide div.metaData {
  display: none;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square.hide div.metaData {
    display: block;
  }
}
div.showCardContainer .square div.metaData {
  display: none;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square div.metaData {
    display: block;
  }
}
div.showCardContainer .square div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.showCardContainer .square div.imageContainer img {
  width: 100%;
  display: block;
  transition: 0.2s ease-in-out;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
div.showCardContainer .square div.imageContainer div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer .square div.imageContainer div.premiumStatus div.premium {
    width: 26px;
  }
}
div.showCardContainer .square div.imageContainer div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.showCardContainer .square div.imageContainer div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.showCardContainer .square div.imageContainer div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer .square div.imageContainer div.premiumStatus div.free {
    width: 30px;
  }
}
div.showCardContainer .square div.imageContainer div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.showCardContainer .square div.imageContainer div.extraActions {
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.showCardContainer .square div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.showCardContainer .square div.continueWatchingBar {
  height: 3px;
  background-color: var(--grey-color);
  width: 64%;
  margin: 10px auto 0;
}
div.showCardContainer .square div.continueWatchingBar div.line {
  height: 100%;
  background-color: var(--secondary-color);
}
div.showCardContainer .square div.continueWatchingContainer {
  display: none;
  align-items: center;
  margin: 5px 0;
}
div.showCardContainer .square div.continueWatchingContainer div.continueWatchingBarHover {
  height: 2px;
  background-color: var(--grey-color);
  width: 70%;
  margin: 0 auto;
}
div.showCardContainer .square div.continueWatchingContainer div.continueWatchingBarHover div.line {
  height: 100%;
  background-color: var(--secondary-color);
}
div.showCardContainer .square div.continueWatchingContainer div.watchedInfo span {
  font-size: 10px;
  display: block;
  color: var(--text-color);
}
div.showCardContainer .square div.metaData {
  background: #181818;
  color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 10px;
  box-sizing: border-box;
  visibility: hidden;
  transition: 0.9s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square div.metaData {
    visibility: visible;
  }
}
div.showCardContainer .square div.metaData h1.title {
  font-size: 11px;
  color: var(--text-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.showCardContainer .square div.metaData div.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
div.showCardContainer .square div.metaData div.buttons span.material-symbols-outlined {
  font-weight: 300 !important;
  font-size: 20px !important;
}
div.showCardContainer .square div.metaData div.buttons svg {
  width: 100% !important;
  height: 15px !important;
}
div.showCardContainer .square div.metaData div.buttons button {
  background-color: rgba(42, 42, 42, 0.6);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
}
div.showCardContainer .square div.metaData div.buttons button.play {
  background-color: var(--white-color);
  margin-inline-end: 4px;
  transition: 0.2s ease-in-out;
}
div.showCardContainer .square div.metaData div.buttons button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.showCardContainer .square div.metaData div.buttons button.play svg {
  color: var(--black-color);
}
div.showCardContainer .square div.metaData div.buttons button.addToList, div.showCardContainer .square div.metaData div.buttons button.moreInfo {
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
}
div.showCardContainer .square div.metaData div.buttons button.addToList:hover, div.showCardContainer .square div.metaData div.buttons button.moreInfo:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.showCardContainer .square div.metaData div.buttons button.moreInfo:hover span.tooltip, div.showCardContainer .square div.metaData div.buttons button.addToList:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square div.metaData div.buttons button.moreInfo:hover span.tooltip, div.showCardContainer .square div.metaData div.buttons button.addToList:hover span.tooltip {
    display: none;
  }
}
div.showCardContainer .square div.metaData div.buttons button.moreInfo span.tooltip, div.showCardContainer .square div.metaData div.buttons button.addToList span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: 0;
  inset-inline-start: 28px;
  width: 7rem;
  padding: 3px 8px 4px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.showCardContainer .square div.metaData div.buttons button.moreInfo span.tooltip.remove, div.showCardContainer .square div.metaData div.buttons button.addToList span.tooltip.remove {
  width: 9rem;
}
div.showCardContainer .square div.metaData div.buttons button.moreInfo span.tooltip::after, div.showCardContainer .square div.metaData div.buttons button.addToList span.tooltip::after {
  content: "";
  position: absolute;
  top: 26%;
  inset-inline-start: -5px;
  margin-inline-start: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
  transform: rotate(90deg);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.showCardContainer .square div.metaData div.buttons div.left {
  display: flex;
  align-items: center;
}
div.showCardContainer .square div.metaData div.extraInfo {
  margin-bottom: 6px;
}
div.showCardContainer .square div.metaData div.extraInfo span {
  font-size: 10px;
}
div.showCardContainer .square div.metaData div.extraInfo span.duration {
  color: var(--duration-color);
  margin-inline-end: 6px;
  font-weight: 500;
}
div.showCardContainer .square div.metaData div.extraInfo span.rating {
  padding: 1px 6px;
  border: 1px solid var(--border-color);
  font-size: 9px;
  margin-inline-end: 6px;
}
div.showCardContainer .square div.metaData div.categories {
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square div.metaData div.categories {
    display: none;
  }
}
div.showCardContainer .square div.metaData div.categories div.item {
  padding-inline-end: 4px;
  display: flex;
  margin-bottom: 3px;
}
div.showCardContainer .square div.metaData div.categories div.item:first-child span.dot {
  display: none;
}
div.showCardContainer .square div.metaData div.categories div.item span {
  color: var(--text-color);
  font-size: 10px;
}
div.showCardContainer .square div.metaData div.categories div.item span.dot {
  font-size: 9px;
}
div.showCardContainer .square div.metaData div.categories div.item span.dot::before {
  color: #646464;
  content: "•";
  display: inline-block;
  margin-inline-end: 4px;
}

div.footerContainer {
  padding: 30px 0;
  position: relative;
  z-index: 9;
  background-color: var(--bg-color);
  border-top: 1px solid var(--border-color);
}
div.footerContainer div.topContainer {
  display: flex;
  justify-content: space-between;
}
div.footerContainer div.topContainer div.left {
  width: 20%;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
}
div.footerContainer div.topContainer div.left div.logoContainer {
  width: 100px;
}
div.footerContainer div.topContainer div.left div.logoContainer img {
  display: block;
  width: 100%;
}
div.footerContainer div.topContainer div.right {
  width: 20%;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.footerContainer div.topContainer div.right {
    width: 35%;
  }
}
div.footerContainer div.topContainer div.right ul.social {
  display: flex;
  align-items: center;
}
div.footerContainer div.topContainer div.right ul.social li {
  margin-inline-end: 10px;
  cursor: pointer;
}
div.footerContainer div.topContainer div.right ul.social li:last-child {
  margin-inline-end: 0;
}
div.footerContainer div.topContainer div.right ul.social li:hover svg {
  opacity: 0.6;
  color: var(--text-hover);
}
div.footerContainer div.topContainer div.right ul.social li svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.footerContainer div.centerContainer div.informationContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
}
div.footerContainer div.centerContainer div.informationContainer div.items {
  width: 20%;
  color: var(--text-color);
}
@media all and (max-width: 768px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 25%;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 33%;
  }
}
div.footerContainer div.centerContainer div.informationContainer div.items h6.heading {
  font-size: 12px;
  color: var(--inner-text-color);
  margin-bottom: 10px;
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span {
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span:hover {
  opacity: 0.6;
  color: var(--text-hover);
}
div.footerContainer div.bottomContainer {
  border-top: 1px solid var(--border-color);
  padding: 40px 0;
}
div.footerContainer div.bottomContainer div.stores {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
div.footerContainer div.bottomContainer div.stores svg {
  margin-inline-end: 10px;
  width: 120px !important;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.footerContainer div.bottomContainer div.stores svg:last-child {
  margin-inline-end: 0;
}
div.footerContainer div.bottomContainer div.stores svg:hover {
  opacity: 0.8;
}
div.footerContainer div.bottomContainer p {
  text-align: center;
  font-size: 12px;
  color: var(--grey-color);
  opacity: 0.7;
}
div.footerContainer div.bottomContainer div.typeOfPayments {
  color: var(--grey-color) !important;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i {
  margin-inline-end: 6px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i:last-child {
  margin-inline-end: 0;
}

div.detailsScreen {
  padding: 50px 0 0;
}
div.detailsScreen div.topBackgroundContainer {
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100%;
  background-size: cover;
  padding-bottom: 20px;
}
div.detailsScreen div.topBackgroundContainer div.contents {
  display: flex;
  justify-content: space-between;
}
div.detailsScreen div.topBackgroundContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0.8) 100%);
}
div.detailsScreen div.topBackgroundContainer div.left {
  padding-top: 220px;
  width: 23%;
  z-index: 9;
  padding-inline-start: 5px;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    padding-inline-start: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    padding-inline-start: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    padding-inline-start: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    width: 24%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    width: 100%;
    padding-top: 280px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left h1.title {
  color: var(--white-color);
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
div.detailsScreen div.topBackgroundContainer div.left h2.director {
  color: var(--white-color);
  font-size: 13px;
  font-weight: normal;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left h2.director {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left h2.director span.name {
  font-weight: 700;
  font-size: 14px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left h2.director span.name {
    font-size: 15px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left span.year {
  font-size: 13px;
  color: var(--white-color);
  margin-bottom: 10px;
  display: inline-block;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo {
  display: flex;
  color: var(--text-color);
  align-items: center;
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon {
  margin-inline-end: 5px;
  line-height: 0;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg {
  width: 16px !important;
  height: 16px !important;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg path {
  fill: var(--text-color) !important;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration {
  display: flex;
  align-items: center;
  margin-inline-end: 10px;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
  font-size: 13px;
  font-weight: 500;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.language {
  display: flex;
  align-items: center;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.language span {
  font-size: 13px;
  font-weight: 500;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.language span {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left div.watchListButton {
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.left div.watchListButton svg {
  width: 25px !important;
  height: 25px !important;
}
div.detailsScreen div.topBackgroundContainer div.categoryName {
  background-color: #68abff;
  padding: 10px 6px;
  width: 8rem;
  text-transform: uppercase;
  color: var(--white-color);
  font-weight: 500;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: auto;
  position: absolute;
  inset-inline-end: 0;
  top: 20px;
  z-index: 9;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.categoryName {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.top {
  position: absolute;
  inset-inline-end: 0;
  text-align: end;
  padding-top: 60px;
  z-index: 9;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    inset-inline-end: 100px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    inset-inline-end: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    inset-inline-end: 60px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    padding-top: 20px;
    inset-inline-end: 5px;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-inline-end: 20px;
  margin-top: 10px;
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings div.icon {
  margin-inline-end: 5px;
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings div.icon svg {
  width: 18px !important;
  height: 18px !important;
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating {
  font-size: 22px;
  color: var(--white-color);
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating {
    font-size: 23px;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf {
    font-size: 15px;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.trailer {
  padding-inline-end: 5px;
  padding-top: 90px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top div.trailer {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn {
  background-color: transparent;
  border: 1px solid var(--white-color);
  width: 5rem;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 6px;
  color: var(--white-color);
  margin-inline-start: auto;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn:hover {
  border-color: var(--button-hover);
  background-color: var(--button-hover);
}
div.detailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn div.icon {
  line-height: 0;
  margin-inline-start: 8px;
}
div.detailsScreen div.topBackgroundContainer div.top span.totalRatings {
  color: var(--text-color);
  font-size: 13px;
  font-weight: 700;
  padding-inline-end: 3px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top span.totalRatings {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.right {
  width: 73%;
  padding-top: 270px;
  z-index: 8;
  display: flex;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    padding-inline-end: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    padding-inline-end: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    padding-inline-end: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    width: 70%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.right div.synopsis {
  color: var(--white-color);
  margin-inline-end: 40px;
  width: 50%;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right div.synopsis {
    margin-inline-end: 0;
    margin-bottom: 20px;
  }
}
div.detailsScreen div.topBackgroundContainer div.right div.synopsis h1.head {
  font-size: 16px;
}
div.detailsScreen div.topBackgroundContainer div.right div.synopsis p.synopis {
  font-size: 13px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right div.synopsis p.synopis {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.right div.ourTake {
  color: var(--white-color);
  width: 50%;
}
div.detailsScreen div.topBackgroundContainer div.right div.ourTake h1.head {
  font-size: 16px;
}
div.detailsScreen div.topBackgroundContainer div.right div.ourTake p.ourTake {
  font-size: 13px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right div.ourTake p.ourTake {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.pageDown {
  z-index: 9;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.pageDown:hover {
  bottom: -10px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.pageDown {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.pageDown svg {
  width: 35px !important;
  height: 35px !important;
  transform: rotate(-90deg);
  fill: var(--text-color);
}
div.detailsScreen div.topBackgroundContainer div.playIconContainer {
  position: absolute;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid var(--white-color);
  transition: 0.3s ease-in-out;
  z-index: 9;
  left: 50%;
  top: 23%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.playIconContainer {
    top: 30%;
  }
}
div.detailsScreen div.topBackgroundContainer div.playIconContainer svg {
  width: 30px !important;
  height: 30px !important;
  color: var(--white-color);
}
div.detailsScreen div.topBackgroundContainer div.playIconContainer:hover {
  background-color: var(--button-hover);
  border-color: var(--button-hover);
}
div.detailsScreen div.topBackgroundContainer div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 9;
  padding-inline-start: 5px;
  padding-bottom: 30px;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions {
    padding-inline-start: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions {
    padding-inline-start: 30px;
  }
}
div.detailsScreen div.topBackgroundContainer div.subscriptions div.items {
  z-index: 9;
  cursor: pointer;
  margin-inline-end: 10px;
  background-color: var(--yellow-color);
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions div.items {
    font-size: 12px;
  }
}
div.detailsScreen div.topBackgroundContainer div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer {
  position: absolute;
  inset-inline-end: 5px;
  z-index: 9;
  bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer span {
  color: var(--white-color);
  font-size: 14px;
  margin-inline-end: 15px;
  transition: 0.2s ease-in-out;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer span.active {
  transform: translateX(-10px);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.icon {
  line-height: 0;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.icon svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.icon .closeIcon {
  stroke: var(--white-color) !important;
  display: block;
  width: 11px !important;
  height: 11px !important;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons {
  line-height: 0;
  animation: fadeIn 0.9s;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook {
  margin-inline-end: 15px;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook div.icon svg {
  color: var(--white-color);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter {
  margin-inline-end: 15px;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter div.icon svg {
  color: var(--white-color);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard {
  margin-inline-end: 15px;
  display: inline;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard svg {
  fill: var(--white-color);
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreen div.responsiveContent {
  padding-bottom: 25px;
  display: none;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.responsiveContent {
    display: block;
  }
}
div.detailsScreen div.responsiveContent div.categoryNameSM {
  background-color: rgb(242, 89, 89);
  padding: 12px 16px;
  text-transform: uppercase;
  color: var(--white-color);
  font-weight: 700;
  font-size: 15px;
}
div.detailsScreen div.responsiveContent div.synopsisSM {
  padding: 25px 15px;
  color: var(--text-color);
}
div.detailsScreen div.responsiveContent div.synopsisSM h1.heading {
  font-size: 16px;
  margin-bottom: 8px;
}
div.detailsScreen div.responsiveContent div.synopsisSM p.synopsis {
  font-size: 16px;
}
div.detailsScreen div.responsiveContent div.ourTakeSM {
  padding: 0 15px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.detailsScreen div.responsiveContent div.ourTakeSM h1.heading {
  font-size: 16px;
  margin-bottom: 8px;
}
div.detailsScreen div.responsiveContent div.ourTakeSM p.ourTake {
  font-size: 16px;
}
div.detailsScreen div.responsiveContent div.trailer {
  padding-inline-end: 15px;
}
div.detailsScreen div.responsiveContent div.trailer button.trailerBtn {
  background-color: transparent;
  border: 1px solid var(--text-color);
  width: 5.5rem;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 7px 10px;
  color: var(--text-color);
  margin-inline-start: auto;
  cursor: pointer;
}
div.detailsScreen div.responsiveContent div.trailer button.trailerBtn:hover {
  border-color: var(--button-hover);
  background-color: var(--button-hover);
}
div.detailsScreen div.responsiveContent div.trailer button.trailerBtn div.icon {
  line-height: 0;
  margin-inline-start: 8px;
}
div.detailsScreen div.categories {
  padding: 30px 4px 0;
  background-color: var(--secondary-bg-color);
}
div.detailsScreen div.categories h1.heading {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 20px;
}
div.detailsScreen div.categories div.categoryLists ul {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.categories div.categoryLists ul li.list {
  display: flex;
  margin-inline-end: 10px;
  width: 24%;
  background-color: var(--white-color);
  margin-bottom: 10px;
  cursor: pointer;
}
div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.categories div.categoryLists ul li.list {
    width: 32.6%;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(4n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.categories div.categoryLists ul li.list {
    width: 49%;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.categories div.categoryLists ul li.list {
    width: 100%;
    margin-inline-end: 0;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.detailsScreen div.categories div.categoryLists ul li.list div.left div.imageContainer {
  width: 70px;
}
div.detailsScreen div.categories div.categoryLists ul li.list div.left div.imageContainer img {
  display: block;
  width: 100%;
}
div.detailsScreen div.categories div.categoryLists ul li.list div.right {
  padding: 10px;
}
div.detailsScreen div.categories div.categoryLists ul li.list div.right h1.title {
  font-size: 14px;
  text-transform: uppercase;
}
div.detailsScreen div.awardsAndFestivals {
  padding: 30px 4px 0;
  background-color: var(--secondary-bg-color);
}
div.detailsScreen div.awardsAndFestivals h1.heading {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 20px;
}
div.detailsScreen div.awardsAndFestivals ul.lists {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list {
  margin-inline-end: 10px;
  width: 24%;
  margin-bottom: 20px;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.awardsAndFestivals ul.lists li.list {
    width: 32.6%;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.awardsAndFestivals ul.lists li.list {
    width: 49%;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.awardsAndFestivals ul.lists li.list {
    width: 100%;
    margin-inline-end: 0;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.imageContainer {
  width: 40px;
  filter: grayscale(100%);
  background: rgb(200, 200, 200);
  margin-bottom: 20px;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.imageContainer img {
  display: block;
  width: 100%;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list h1.title {
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 10px;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.descriptionAndYear {
  display: flex;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.descriptionAndYear span.year {
  margin-inline-end: 6px;
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.descriptionAndYear p.description {
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.detailsScreen div.castAndCrew {
  background-color: var(--secondary-bg-color);
  padding: 30px 4px 0;
}
div.detailsScreen div.castAndCrew h1.heading {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 15px;
}
div.detailsScreen div.castAndCrew ul.lists {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.castAndCrew ul.lists li.list {
  width: 12%;
  background-color: var(--white-color);
  margin-inline-end: 7px;
  margin-bottom: 20px;
  cursor: pointer;
}
div.detailsScreen div.castAndCrew ul.lists li.list:hover div.info h1.name {
  color: var(--grey-color);
}
div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(8n) {
  margin-inline-end: 0;
}
div.detailsScreen div.castAndCrew ul.lists li.list:last-child {
  margin-inline-end: 0 !important;
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 13.7%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 16%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 24%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 32%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 49%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 47.8%;
  }
}
div.detailsScreen div.castAndCrew ul.lists li.list div.imageContainer {
  filter: grayscale(100%);
  background: rgb(200, 200, 200);
  width: 100%;
}
div.detailsScreen div.castAndCrew ul.lists li.list div.imageContainer img {
  width: 100%;
  display: block;
}
div.detailsScreen div.castAndCrew ul.lists li.list div.info {
  padding: 10px 10px 30px;
}
div.detailsScreen div.castAndCrew ul.lists li.list div.info h1.name {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--text-color);
}
div.detailsScreen div.castAndCrew ul.lists li.list div.info p.role {
  font-size: 11px;
  color: var(--grey-color);
  text-transform: uppercase;
}
div.detailsScreen div.relatedFilms {
  background-color: var(--secondary-bg-color);
  padding: 30px 4px 0;
}
div.detailsScreen div.relatedFilms h1.heading {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 15px;
}
div.detailsScreen div.relatedFilms div.films {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.relatedFilms div.films div.show {
  margin-inline-end: 10px;
  margin-bottom: 10px;
}
div.detailsScreen div.relatedFilms div.films div.show.portrait {
  width: 16%;
}
div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1300px) {
  div.detailsScreen div.relatedFilms div.films div.show.portrait {
    width: 19%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(6n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(5n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.relatedFilms div.films div.show.portrait {
    width: 24%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(5n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.relatedFilms div.films div.show.portrait {
    width: 32%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(4n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.relatedFilms div.films div.show.portrait {
    width: 48%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.detailsScreen div.relatedFilms div.films div.show.landscape {
  width: 24.3%;
}
div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 32.5%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(4n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 32%;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 49%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 48%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div.loginPage {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
}
div.loginPage span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.loginPage div.updatesContainer {
  text-align: center;
  padding-top: 100px;
  width: 50%;
  margin: 0 auto -90px;
}
@media all and (max-width: 1200px) {
  div.loginPage div.updatesContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.loginPage div.updatesContainer {
    width: 90%;
  }
}
div.loginPage div.updatesContainer p {
  background-color: #fff;
  font-size: 14px;
  color: var(--error-color);
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  border-bottom: 4px solid var(--error-color);
}
div.loginPage div.loginContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 130px 0;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer {
    width: 95%;
  }
}
div.loginPage div.loginContainer div.top div#appleid-signin {
  height: 60px;
  cursor: pointer;
  padding: 5px;
  border: transparent;
  border-radius: 10px;
  transition: border 5s ease;
}
div.loginPage div.loginContainer div.top div#appleid-signin:hover {
  border: 1px solid transparent;
}
div.loginPage div.loginContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.loginPage div.loginContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.top h1 {
  color: var(--text-color);
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer div.top h1 {
    font-size: 25px;
  }
}
div.loginPage div.loginContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-inline-end: 50px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.right span {
  color: var(--text-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-inline-end: 50px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.loginPage div.loginContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line:first-child {
  margin-inline-end: 8px;
}
div.loginPage div.loginContainer div.top div.seperate span.line:last-child {
  margin-inline-start: 8px;
}
div.loginPage div.loginContainer div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.loginPage div.loginContainer div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.loginPage div.loginContainer div.seperate span.line:first-child {
  margin-inline-end: 8px;
}
div.loginPage div.loginContainer div.seperate span.line:last-child {
  margin-inline-start: 8px;
}
div.loginPage div.loginContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.loginPage div.loginContainer div.bottom {
  margin-top: 15px;
}
div.loginPage div.loginContainer div.bottom h3 {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.loginPage div.loginContainer div.bottom form {
  margin-bottom: 20px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer {
  background-color: var(--text-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input {
  background: transparent;
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer {
  background-color: var(--text-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input {
  background: transparent;
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.loginPage div.loginContainer div.bottom form h4.forgotPassword {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom form h4 {
  font-size: 15px;
  color: #d5d5d6;
  font-weight: 400;
}
div.loginPage div.loginContainer div.bottom form h4 span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom p {
  font-size: 11px;
  color: var(--inner-text-color);
  line-height: 1.6em;
}
div.loginPage div.loginContainer div.bottom p span {
  color: var(--text-color);
  cursor: pointer;
}

div.registerPage {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
}
div.registerPage span.error {
  color: #e72a31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 130px 0 100px;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer {
    width: 95%;
  }
}
div.registerPage div.registerContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.registerPage div.registerContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
}
div.registerPage div.registerContainer div.top h1 {
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer div.top h1 {
    font-size: 25px;
  }
}
div.registerPage div.registerContainer div.top h6 {
  font-size: 15px;
  color: var(--inner-text-color);
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-inline-end: 50px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.right span {
  color: var(--text-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-inline-end: 50px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.registerPage div.registerContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line:first-child {
  margin-inline-end: 8px;
}
div.registerPage div.registerContainer div.top div.seperate span.line:last-child {
  margin-inline-start: 8px;
}
div.registerPage div.registerContainer div.bottom {
  margin-top: 15px;
}
div.registerPage div.registerContainer div.bottom h3 {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.registerPage div.registerContainer div.bottom form {
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne {
  background-color: var(--text-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input {
  background: transparent;
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  padding: 17px 15px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo label {
  font-size: 12px;
  color: var(--inner-text-color);
  text-align: start;
  display: block;
  font-weight: 600;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers {
  display: flex;
  height: 100%;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input {
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-outer-spin-button,
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input[type=number] {
  -moz-appearance: textfield;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left {
  margin-inline-end: 10px;
  width: 70%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left input[type=date] {
  padding: 17px 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right {
  width: 30%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer {
  background-color: var(--text-color);
  border-radius: 8px;
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer svg {
  position: absolute;
  inset-inline-end: 5px;
  width: 15px !important;
  height: 15px !important;
  color: var(--inner-text-color);
  top: 50%;
  transform: translateY(-50%);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select {
  padding: 17px 15px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select option {
  padding: 17px 15px;
  background-color: var(--text-color);
  color: var(--black-color);
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.registerPage div.registerContainer div.bottom form h4.forgotPassword {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom h4.signIn {
  font-size: 12px;
  color: #d5d5d6;
  font-weight: 400;
}
div.registerPage div.registerContainer div.bottom h4.signIn span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom p {
  font-size: 11px;
  color: var(--inner-text-color);
  line-height: 1.6em;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom p span {
  color: var(--text-color);
  cursor: pointer;
}

div.commonPage {
  padding: 60px 5px 40px;
  background-color: var(--bg-color);
}
div.commonPage div.top.inside {
  position: absolute;
  top: 60px;
  z-index: 8;
  inset-inline-start: 60px;
}
@media all and (max-width: 640px) {
  div.commonPage div.top.inside {
    top: 50px;
    z-index: 8;
    inset-inline-start: 20px;
  }
}
div.commonPage div.top.inside h1.heading {
  text-align: start;
  color: var(--white-color);
}
div.commonPage div.top h1.heading {
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--heading-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.commonPage div.top h1.heading {
    font-size: 22px;
    margin-top: 50px;
  }
}
div.commonPage div.top p.desc {
  font-size: 18px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.commonPage div.top p.desc {
    font-size: 14px;
  }
}
div.commonPage div.bgImageContainer {
  width: 100%;
  display: block;
  height: 508px;
  position: relative;
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer {
    height: 556px;
  }
}
div.commonPage div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.commonPage div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.commonPage div.bottom {
  margin-top: 40px;
}
div.commonPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.commonPage div.bottom div.itemsContainer div.items.hidden {
  visibility: hidden;
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.hidden {
    display: none;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19.3%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 19.3%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 16%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount7 {
  width: 13.9%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7 {
    width: 19.3%;
  }
}
@media all and (max-width: 2600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 6.5px;
  }
}
@media all and (max-width: 2500px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 5.5px;
  }
}
@media all and (max-width: 2200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.9%;
    margin-inline-end: 6.5px;
  }
}
@media all and (max-width: 1980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 4.5px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 4px;
  }
}
@media all and (max-width: 1600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-inline-end: 7.5px;
  }
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.6%;
    margin-inline-end: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-inline-end: 8px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8 {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 2600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12.1%;
    margin-inline-end: 10px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 2500px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12.2%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 1600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 5px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.commonPage div.LoadMoreButtonContainer {
  text-align: center;
}
@media all and (max-width: 980px) {
  div.commonPage div.LoadMoreButtonContainer {
    margin-top: 20px;
  }
}
div.commonPage div.LoadMoreButtonContainer button {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin: 0 auto;
}
div.commonPage div.LoadMoreButtonContainer button:hover {
  background-color: var(--secondary-hover-color);
}
div.commonPage div.LoadMoreButtonContainer button span {
  font-size: 14px;
}

div.failed {
  padding: 178px 0;
}
div.failed div.failedContainer {
  background-color: #fff;
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
@media all and (max-width: 1200px) {
  div.failed div.failedContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.failed div.failedContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.failed div.failedContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.failed div.failedContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.failed div.failedContainer {
    width: 68%;
  }
}
div.failed div.failedContainer h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.failed div.failedContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.failed div.failedContainer button {
  width: 100%;
  outline: none;
  border: 1px solid #e50914;
  background-color: #e50914;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.failed div.failedContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.failed div.failedContainer div.crossIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.failed div.failedContainer div.crossIcon svg {
  padding: 20px;
  background-color: #e50914;
  border-radius: 50%;
}

/* div.successContainer {
    padding: 100px 0;
    background-color: #fff;
    width: 30%;
    margin: 0 auto;
} */
div.success {
  padding: 178px 0;
}
div.success div.successContainer {
  background-color: #fff;
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
div.success div.successContainer p.checking {
  color: #28a745;
}
@media all and (max-width: 1200px) {
  div.success div.successContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.success div.successContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.success div.successContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.success div.successContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.success div.successContainer {
    width: 68%;
  }
}
div.success div.successContainer h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.success div.successContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.success div.successContainer button {
  width: 100%;
  outline: none;
  border: 1px solid #28a745;
  background-color: #28a745;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.success div.successContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.success div.successContainer div.checkIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.success div.successContainer div.checkIcon svg {
  padding: 20px;
  background-color: #28a745;
  border-radius: 50%;
}

div.forgotPassword {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
}
div.forgotPassword span.error {
  color: #e72a31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.forgotPassword div.forgotPasswordContainer {
  padding: 200px 0;
}
div.forgotPassword div.forgotPasswordContainer h1.heading {
  font-size: 34px;
  color: var(--white-color);
  text-align: center;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer h1.heading {
    font-size: 26px;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer {
  text-align: center;
  width: 35rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 25rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 100%;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input {
  background: transparent;
  outline: none;
  border: 1px solid var(--grey-color);
  text-align: start;
  width: 100%;
  padding: 20px 15px;
  caret-color: var(--white-color);
  color: var(--white-color);
  border-radius: 5px;
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input:focus {
  border: 1px solid var(--white-color);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 10rem;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer div.background {
  background: hsla(226, 7%, 61%, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 1;
  border-radius: 5px;
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.resetPassword {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
  padding: 150px 0;
}
div.resetPassword span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.resetPassword span.success {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.resetPassword h1.heading {
  font-size: 34px;
  padding-bottom: 10px;
  text-align: center;
  color: var(--white-color);
}
@media all and (max-width: 640px) {
  div.resetPassword h1.heading {
    font-size: 22px;
    margin: 0 20px;
  }
}
div.resetPassword div.contents {
  margin-top: 40px;
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents {
    padding: 0 10px;
  }
}
div.resetPassword div.contents div.formSection {
  width: 30%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.resetPassword div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.resetPassword div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection {
    width: 100%;
  }
}
div.resetPassword div.contents div.formSection form div.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
div.resetPassword div.contents div.formSection form div.inputContainer:nth-child(3) {
  margin-bottom: 60px;
}
div.resetPassword div.contents div.formSection form div.inputContainer label {
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--white-color);
}
div.resetPassword div.contents div.formSection form div.inputContainer input {
  outline: none;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--grey-color);
  caret-color: var(--white-color);
  color: var(--white-color);
  margin-bottom: 10px;
  border-radius: 5px;
}
div.resetPassword div.contents div.formSection form div.inputContainer input:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.inputContainer input {
    padding: 16px 15px;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 10rem;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.resetPassword div.contents div.formSection form div.buttonContainer div.background {
  background: hsla(226, 7%, 61%, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 1;
  border-radius: 5px;
}
div.resetPassword div.contents div.formSection form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

header.accountsPageHeader {
  height: 100px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
  margin: 0 auto;
}
header.accountsPageHeader div.left div.logoContainer h1 {
  width: 120px;
}
@media all and (max-width: 480px) {
  header.accountsPageHeader div.left div.logoContainer h1 {
    width: 72px;
  }
}
@media all and (max-width: 360px) {
  header.accountsPageHeader div.left div.logoContainer h1 {
    width: 72px;
  }
}
header.accountsPageHeader div.left div.logoContainer h1 img {
  display: block;
  width: 100%;
}
header.accountsPageHeader div.right button {
  background: transparent;
  color: var(--white-color);
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

div.otpModal div.overlayOtp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.otpModal div.otpContainer {
  z-index: 99;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.otpModal div.otpContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.otpModal div.otpContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.otpModal div.otpContainer {
    width: 90%;
  }
}
div.otpModal div.otpContainer label {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer h4 {
  font-size: 30px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer h6 {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.otpModal div.otpContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--text-color);
  caret-color: var(--white-color);
  margin-bottom: 10px;
  color: var(--white-color);
}
div.otpModal div.otpContainer input:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer input {
    padding: 16px 15px;
  }
}
div.otpModal div.otpContainer input::-webkit-outer-spin-button,
div.otpModal div.otpContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.otpModal div.otpContainer input[type=number] {
  -moz-appearance: textfield;
}
div.otpModal div.otpContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer div.buttonContainer {
    padding: 10px 20px;
  }
}
div.otpModal div.otpContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.otpModal div.otpContainer div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 99;
  border-radius: 4px;
}
div.otpModal div.otpContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 999;
  cursor: pointer;
}
div.otpModal div.otpContainer div.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
div.otpModal div.otpContainer div.buttonsContainer button {
  background: var(--button-color);
  color: var(--white-color);
  position: relative;
  z-index: 999;
  cursor: pointer;
  width: 100px;
  height: 40px;
  border: transparent;
  border-radius: 3px;
  font-size: medium;
  font-weight: 400;
  margin: 5px;
}
div.otpModal div.otpContainer div.buttonsContainer button:hover {
  background: var(--white-color);
  color: var(--button-color);
}
div.otpModal div.otpContainer div.buttonsContainer .cancel {
  background: transparent;
  border: 1px solid;
}
div.otpModal div.otpContainer div.buttonsContainer .cancel:hover {
  color: rgba(0, 0, 0, 0.7);
}

div.continueWatching div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.continueWatching div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.continueWatching div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.continueWatching div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.continueWatching div.contents {
    width: 90%;
  }
}
div.continueWatching div.contents h1 {
  color: var(--text-color);
  font-size: 22px;
}
div.continueWatching div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.continueWatching div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.continueWatching div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.continueWatching div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.continueWatching div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.continueWatching div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.continueWatching div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.continueWatching div.contents div.buttons div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.continueWatching div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.currentPasswordModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.currentPasswordModal div.currentPasswordContainer {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.currentPasswordModal div.currentPasswordContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 90%;
  }
}
div.currentPasswordModal div.currentPasswordContainer label {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--heading-color);
  text-transform: uppercase;
  font-weight: 700;
}
div.currentPasswordModal div.currentPasswordContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.currentPasswordModal div.currentPasswordContainer input {
  outline: none;
  font-size: 14px;
  background: var(--black-color);
  text-align: start;
  padding: 18px 15px;
  border: 1px solid var(--text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.currentPasswordModal div.currentPasswordContainer input:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.currentPasswordModal div.currentPasswordContainer input {
    padding: 16px 15px;
  }
}
div.currentPasswordModal div.currentPasswordContainer input::-webkit-outer-spin-button,
div.currentPasswordModal div.currentPasswordContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.currentPasswordModal div.currentPasswordContainer input[type=number] {
  -moz-appearance: textfield;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer {
  margin-top: 20px;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer button {
  background-color: var(--button-color);
  border-radius: 3px;
  padding: 10px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
}

div.watchWithoutAds div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.watchWithoutAds div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.watchWithoutAds div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.watchWithoutAds div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents {
    width: 90%;
  }
}
div.watchWithoutAds div.contents h1 {
  color: var(--text-color);
  font-size: 22px;
}
div.watchWithoutAds div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.watchWithoutAds div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.logoutAll div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.logoutAll div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 9999999;
}
div.logoutAll div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.logoutAll div.contents {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.logoutAll div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.logoutAll div.contents {
    width: 90%;
  }
}
div.logoutAll div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.logoutAll div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.logoutAll div.contents h1 {
  color: var(--text-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents h1 {
    font-size: 16px;
  }
}
div.logoutAll div.contents div.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
div.logoutAll div.contents div.buttonContainer button {
  background-color: var(--button-color);
  padding: 10px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
  border-radius: 5px;
}
div.logoutAll div.contents div.buttonContainer button:first-child {
  margin-inline-end: 6px;
}

div.cancelSub div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.cancelSub div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999999;
  cursor: pointer;
}
div.cancelSub div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  stroke: #E72A31 !important;
}
div.cancelSub div.contents {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.cancelSub div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents {
    width: 90%;
  }
}
div.cancelSub div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.cancelSub div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.cancelSub div.contents h1 {
  color: var(--text-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents h1 {
    font-size: 16px;
  }
}
div.cancelSub div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.cancelSub div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.cancelSub div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child div.background {
  background: var(--error-color);
}
@media all and (max-width: 640px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.cancelSub div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.cancelSub div.contents div.buttons div.buttonContainer div.background {
  background: var(--success-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.cancelSub div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.loginRequest {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
  background-repeat: no-repeat;
  height: 100vh;
}

div.playNextEpisode div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.playNextEpisode div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.playNextEpisode div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.playNextEpisode div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents {
    width: 90%;
  }
}
div.playNextEpisode div.contents h1 {
  color: var(--text-color);
  font-size: 22px;
}
div.playNextEpisode div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.playNextEpisode div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.playNextEpisode div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.accountSettings {
  padding: 80px 5px;
  position: relative;
  display: flex;
  background-color: var(--account-settings);
}
@media all and (max-width: 768px) {
  div.accountSettings {
    flex-direction: column;
  }
}
div.accountSettings span.error {
  font-size: 14px;
  color: #e72a31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}
div.accountSettings div.leftContainer {
  width: 18%;
  position: relative;
  margin-inline-end: 20px;
}
@media all and (max-width: 1300px) {
  div.accountSettings div.leftContainer {
    width: 25%;
  }
}
@media all and (max-width: 980px) {
  div.accountSettings div.leftContainer {
    width: 31%;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.leftContainer {
    width: 60%;
    margin: 0 auto;
  }
}
@media all and (max-width: 640px) {
  div.accountSettings div.leftContainer {
    width: 90%;
    margin: 0 auto 15px;
  }
}
div.accountSettings div.leftContainer ul.tabs {
  position: fixed;
}
@media all and (max-width: 768px) {
  div.accountSettings div.leftContainer ul.tabs {
    position: unset;
  }
}
div.accountSettings div.leftContainer ul.tabs li {
  font-size: 13px;
  color: var(--text-color);
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #c8c8c8;
  margin-bottom: 10px;
  cursor: pointer;
}
div.accountSettings div.leftContainer ul.tabs li.active {
  color: var(--secondary-color);
}
div.accountSettings div.rightContainer {
  width: 80%;
}
@media all and (max-width: 1300px) {
  div.accountSettings div.rightContainer {
    width: 70%;
  }
}
@media all and (max-width: 980px) {
  div.accountSettings div.rightContainer {
    width: 65%;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.rightContainer {
    width: 90%;
    margin: 0 auto;
  }
}
div.accountSettings div.rightContainer div.top {
  background-color: var(--bg-color);
  padding: 8px 15px;
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.top h1 {
  color: var(--text-color);
  font-size: 17px;
}
div.accountSettings div.rightContainer div.bottom {
  background-color: var(--bg-color);
  padding: 30px 25px;
}
div.accountSettings div.rightContainer div.bottom div.profileSection {
  padding-bottom: 40px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  text-align: center;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer input {
  display: none;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer label {
  font-size: 13px;
  color: var(--text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  transition: 0.3s ease-in-out;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer label:hover {
  color: var(--secondary-color);
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row {
  padding-bottom: 25px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row h1.heading {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField {
    width: 100%;
  }
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField.noMargin {
  margin-bottom: 0;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField label {
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField input {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: 1px solid #c8c8c8;
  padding: 10px;
  border-radius: 5px;
  color: var(--white-color);
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField input:focus {
  border: 2px solid var(--white-color);
  border-radius: 5px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField input:disabled {
  color: var(--grey-color);
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField select {
  width: 100%;
  background-color: var(--bg-color);
  outline: none;
  border: 1px solid #c8c8c8;
  padding: 10px;
  color: var(--white-color);
  border-radius: 5px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField select:focus {
  border: 2px solid var(--white-color);
  border-radius: 5px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row span.language {
  color: var(--secondary-color);
  font-size: 14px;
  display: inline-block;
  margin: 10px 0;
  cursor: pointer;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.buttonContainer button {
  background-color: var(--button-color);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  border-radius: 3px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.buttonContainer button:disabled,
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.buttonContainer button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: var(--text-color);
  cursor: default;
}
div.accountSettings div.rightContainer div.planDetails {
  background-color: var(--bg-color);
  padding: 30px 25px;
}
div.accountSettings div.rightContainer div.planDetails h1.heading {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.planDetails h2.planName {
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.planDetails p.cancelled {
  color: var(--error-color);
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.planDetails div.buttonContainer {
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.planDetails div.buttonContainer button {
  background-color: var(--button-color);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
}
div.accountSettings div.rightContainer div.planDetails span.link {
  display: block;
  font-size: 12px;
  color: var(--button-hover);
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;
}
div.accountSettings div.rightContainer div.streamingActivity {
  background-color: var(--bg-color);
  padding: 30px 25px;
}
@media all and (max-width: 1200px) {
  div.accountSettings div.rightContainer div.streamingActivity {
    width: 100%;
  }
}
div.accountSettings div.rightContainer div.streamingActivity p {
  font-size: 18px;
  color: var(--text-color);
  margin-bottom: 30px;
}
div.accountSettings div.rightContainer div.streamingActivity ul.recentDeviceContainer li {
  border-bottom: 1px dashed var(--heading-color);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
div.accountSettings div.rightContainer div.streamingActivity ul.recentDeviceContainer li span {
  color: var(--text-color);
  display: block;
  font-size: 14px;
}
div.accountSettings div.rightContainer div.streamingActivity ul.recentDeviceContainer li span:first-child {
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer {
    width: 10rem;
  }
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.billingAndSubscription {
  padding: 80px 0;
  background-color: var(--bg-color);
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription {
    width: 100%;
  }
}
div.billingAndSubscription h1.heading {
  font-size: 24px;
  color: var(--heading-color);
  margin-bottom: 30px;
}
div.billingAndSubscription div.billingDetails {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.billingDetails {
    width: 90%;
  }
}
div.billingAndSubscription div.billingDetails h6.subHeading {
  font-size: 14px;
  color: var(--text-color);
  font-weight: normal;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer {
  border: 3px solid #c8c8c8;
  padding: 20px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer span.head {
  display: block;
  color: var(--text-color);
  margin-bottom: 10px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer span.value {
  display: block;
  color: var(--text-color);
  font-size: 14px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer span.value.bold {
  font-weight: 600;
  margin-bottom: 20px;
}
div.billingAndSubscription div.subscriptionDetails {
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.subscriptionDetails {
    width: 90%;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li.head {
  display: flex;
  color: var(--heading-color);
  font-size: 14px;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li.head {
    display: none;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li {
  border-bottom: 1px solid #c8c8c8;
  display: flex;
  padding: 6px;
  color: var(--text-color);
  font-size: 14px;
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left {
  display: flex;
  width: 80%;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left {
    flex-direction: column;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
  width: 17%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
    width: 100%;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
  width: 25%;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 30%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 100%;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.right {
  width: 20%;
  text-align: end;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.right {
    width: 8%;
  }
}
div.billingAndSubscription div.bottom {
  width: 80%;
  margin: 30px auto 0;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.bottom {
    width: 90%;
  }
}
div.billingAndSubscription div.bottom p {
  color: var(--text-color);
  font-size: 12px;
}
div.billingAndSubscription div.bottom div.buttonContainer {
  margin-top: 20px;
  text-align: center;
}
div.billingAndSubscription div.bottom div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 20rem;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.bottom div.buttonContainer button {
    width: 100%;
  }
}

div.liveSection {
  width: 100%;
  margin-top: 30px;
}
div.liveSection h1.heading {
  font-size: 28px;
  color: var(--white-color);
  margin-bottom: 10px;
}
div.liveSection div.videoContainer {
  width: 100%;
  position: relative;
}
div.liveSection div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (min-width: 768px) {
  div.liveSection div.videoContainer:hover div.bottom div.details {
    transform: translateY(0);
  }
}
div.liveSection div.videoContainer div.bottom {
  width: 90%;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
div.liveSection div.videoContainer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.liveSection div.videoContainer div.bottom div.details h1.heading {
  font-size: 28px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details h1.heading {
    font-size: 22px;
  }
}
div.liveSection div.videoContainer div.bottom div.details h1.title {
  font-size: 20px;
  color: var(--text-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.liveSection div.videoContainer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.liveSection div.videoContainer div.bottom div.details p.description {
  color: var(--white-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.liveSection div.videoContainer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.liveSection div.videoContainer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details div.right {
    width: 100%;
  }
}

div.showsRow {
  background-color: var(--secondary-bg-color);
  padding: 40px 5px 0;
}
div.showsRow h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.showsRow .swiper-button-prev {
  left: 0 !important;
  top: 50% !important;
}
div.showsRow .swiper-button-prev::after {
  left: 20px !important;
}
div.showsRow .swiper-button-disabled {
  display: none;
}
div.showsRow .swiper-button-next {
  right: 0 !important;
  top: 50% !important;
}
div.showsRow .swiper-button-next::after {
  right: 20px !important;
}
div.showsRow div.seasonContainer {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 20rem;
  margin-bottom: 20px;
  background: transparent;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: var(--white-color);
  cursor: pointer;
}
@media all and (max-width: 480px) {
  div.showsRow div.seasonContainer {
    width: 100%;
  }
}
div.showsRow div.seasonContainer svg {
  width: 26px !important;
  height: 26px !important;
  transition: 0.3s ease-in-out;
}
div.showsRow div.seasonContainer svg.active {
  transform: rotate(-180deg);
}
div.showsRow div.seasonContainer div.list {
  position: absolute;
  top: 55px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9;
  cursor: pointer;
  height: 100px;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}
div.showsRow div.seasonContainer div.list::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.list::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.list::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.showsRow div.seasonContainer div.list.active {
  display: block;
}
div.showsRow div.seasonContainer div.list div.item {
  width: 100%;
  padding: 20px 10px;
  border-bottom: 1px solid var(--border-color);
  background-color: #1c1f29;
}
div.showsRow div.seasonContainer div.list div.item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
div.showsRow div.seasonContainer div.list div.item span {
  font-size: 16px;
  color: var(--white-color);
}

div.subcriptionPage {
  padding: 150px 5px;
  background-color: var(--secondary-bg-color);
}
div.subcriptionPage h1.heading {
  text-align: center;
  font-size: 34px;
  color: var(--white-color);
}
div.subcriptionPage div.subscriptionContainer {
  width: 90%;
  margin: 30px auto 0;
  overflow-x: scroll;
}
div.subcriptionPage div.subscriptionContainer table {
  width: 100%;
  border-collapse: collapse;
}
div.subcriptionPage div.subscriptionContainer table thead tr th {
  width: 80%;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 30px;
  color: var(--white-color);
  cursor: pointer;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.active {
  background-color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.no-active {
  background-color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td {
  padding: 10px 0;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.big span {
  color: var(--white-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span {
  display: block;
  text-align: center;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active {
  color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active svg {
  stroke: var(--text-color) !important;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active {
  color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active svg {
  stroke: var(--text-hover) !important;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 50px auto 0;
}
@media all and (max-width: 640px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 10rem;
  }
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.subscriptionList {
  padding: 100px 5px;
  background-color: var(--sub-bg-color);
}
div.subscriptionList h1.heading {
  font-size: 28px;
  text-align: center;
  color: var(--sub-heading-color);
}
div.subscriptionList div.wrapper {
  width: 90%;
}
div.subscriptionList div.subscriptionDetails {
  border: 1px solid var(--border-color);
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
  padding: 15px 28px;
  margin-top: 50px;
  color: var(--text-color);
}
div.subscriptionList div.subscriptionDetails h2.heading {
  font-size: 22px;
  color: var(--sub-heading-color);
  text-align: center;
}
div.subscriptionList div.subscriptionDetails ul.conditions {
  list-style: square;
  margin-top: 30px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li {
  line-height: 28px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li span {
  font-size: 16px;
}
div.subscriptionList div.pricing {
  margin-top: 30px;
}
div.subscriptionList div.pricing ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
div.subscriptionList div.pricing ul li {
  margin-inline-end: 20px;
  margin-bottom: 30px;
  padding: 60px 60px;
  border: 1px solid var(--border-color);
  width: 20rem;
  text-align: center;
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
}
div.subscriptionList div.pricing ul li:last-child {
  margin-inline-end: 0;
}
div.subscriptionList div.pricing ul li button {
  display: block;
  border: none;
  outline: none;
  padding: 10px 25px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  margin: 0 auto 20px;
  color: #fff;
  border-radius: 30px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
div.subscriptionList div.pricing ul li button:hover {
  background-color: var(--sub-heading-color);
  border: 1px solid var(--sub-heading-color);
  color: var(--white-color);
}
div.subscriptionList div.pricing ul li span.category {
  display: block;
  margin-bottom: 10px;
  color: var(--white-color);
}
div.subscriptionList div.pricing ul li span.price {
  display: block;
  color: var(--white-color);
}
div.subscriptionList div.pricing ul li span.price.discount span.priceCut {
  margin-inline-end: 10px;
  text-decoration: line-through;
  display: block;
}

/* div.subscriptionList div.subscriptionDetails ul.conditions li::before { 
    content: "-";
    margin-right: 4px;
} */
@media all and (max-width: 1200px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 20px;
  }
  div.subscriptionList div.subscriptionDetails ul.conditions li {
    font-size: 15px;
  }
}
@media all and (max-width: 980px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 19px;
  }
}
@media all and (max-width: 768px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.subscriptionList div.pricing ul {
    flex-direction: column;
  }
  div.subscriptionList div.pricing ul li {
    margin-inline-end: 0;
    width: 80%;
    margin: 0 auto 20px;
  }
  div.subscriptionList div.pricing ul li:last-child {
    margin-bottom: 0;
  }
  div.subscriptionList div.pricing ul li button {
    margin: 0 auto 20px;
  }
}
@media all and (max-width: 480px) {
  div.subscriptionList div.pricing ul li {
    width: 100%;
  }
  div.subscriptionList h1.heading {
    font-size: 25px;
  }
}
div.ideabiz-containter {
  min-height: 100vh;
}

div.paymentScreen {
  padding: 100px 0;
  background-color: var(--sub-bg-color);
}
div.paymentScreen .paypal-button-container {
  min-width: 326px !important;
  max-width: 284px !important;
}
div.paymentScreen span.success {
  color: var(--success-color);
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper {
    width: 90%;
  }
}
div.paymentScreen div.wrapper h1.heading {
  text-align: center;
  font-size: 30px;
  color: var(--white-color);
  margin-bottom: 30px;
  text-transform: uppercase;
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper h1.heading {
    font-size: 25px;
  }
}
div.paymentScreen div.wrapper div.mainContainer {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
div.paymentScreen div.wrapper div.mainContainer.centerDiv {
  justify-content: center;
}
div.paymentScreen div.wrapper div.mainContainer.modifyDiv {
  justify-content: space-evenly;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer {
    flex-direction: column;
    width: 80%;
  }
  div.paymentScreen div.wrapper div.mainContainer.modifyDiv {
    align-items: center;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer {
    width: 100%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left {
  width: 50%;
  border-inline-end: 2px solid var(--border-color);
}
div.paymentScreen div.wrapper div.mainContainer div.left.small {
  width: 33.3%;
}
div.paymentScreen div.wrapper div.mainContainer div.left.no-border {
  border-inline-end: none;
}
@media all and (max-width: 1200px) {
  div.paymentScreen div.wrapper div.mainContainer div.left.small {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.left {
    width: 100%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left.small {
    width: 90%;
    border-inline-end: none;
    border-bottom: 2px solid var(--border-color);
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer label {
  font-size: 20px;
  display: block;
  color: var(--text-color);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 25rem;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
  width: 25rem;
}
@media all and (max-width: 1600px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 23rem;
  }
}
@media all and (max-width: 1400px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 25rem;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 21rem;
  }
}
@media all and (max-width: 1200px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 24rem;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 18rem;
  }
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 22rem;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 90%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 90%;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    flex-direction: column;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    flex-direction: column;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: #000;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid transparent;
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
  margin-inline-end: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
    padding: 16px 15px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
    margin-bottom: 20px;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 15rem;
    padding: 16px 20px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 10rem;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer {
  margin-top: 30px;
  margin-bottom: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer h2.heading {
  font-size: 20px;
  color: var(--text-color);
  font-weight: 400;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways {
  display: flex;
  flex-wrap: wrap;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
  margin: 5px;
  width: 90px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 70px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 20%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer {
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer:hover {
  transform: scale(1.1);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer img {
  width: 100%;
  display: block;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer img.ideabiz-icon {
  border-radius: 5px;
}
div.paymentScreen div.wrapper div.mainContainer div.center {
  width: 33.3%;
  padding: 0 40px 0;
  border-inline-end: 2px solid var(--border-color);
}
div.paymentScreen div.wrapper div.mainContainer div.center.small {
  width: 33.3%;
}
div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
  border-inline-end: none;
  display: flex;
  width: 50%;
  justify-content: center;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.center {
    width: 90%;
    padding: 40px 0 0;
    border-inline-start: none;
    border-inline-end: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.small {
    width: 90%;
    border-inline-end: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
    border-bottom: none;
    width: 90%;
    justify-content: start;
  }
}
@media all and (max-width: 768px) {
  div.paymentScreen div.wrapper div.mainContainer div.center {
    width: 100%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.small {
    width: 90%;
    border-inline-end: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
    border-bottom: none;
    width: 100%;
    justify-content: start;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.center.small {
    width: 90%;
    border-inline-end: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
    border-bottom: none;
    width: 90%;
    justify-content: start;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.center iframe {
  z-index: 2 !important;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer {
  margin-bottom: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer h2.heading {
  font-size: 20px;
  color: var(--text-color);
  font-weight: 400;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li {
  margin-inline-end: 20px;
  width: 250px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li {
    width: 70px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li {
    width: 20%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer {
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer:hover {
  transform: scale(1.1);
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer img {
  width: 100%;
  display: block;
}
div.paymentScreen div.wrapper div.mainContainer div.right {
  width: 50%;
  padding: 0 40px 0;
}
div.paymentScreen div.wrapper div.mainContainer div.right.small {
  width: 33.3%;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 90%;
    padding: 40px 0 0;
    border-inline-start: none;
  }
  div.paymentScreen div.wrapper div.mainContainer div.right.small {
    width: 90%;
  }
}
@media all and (max-width: 768px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 100%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.right.small {
    width: 90%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.right iframe {
  z-index: 2 !important;
}

div.paymentProcessingScreen {
  padding: 100px 0;
  background-color: var(--sub-bg-color);
}
div.paymentProcessingScreen .paypal-button-container {
  min-width: 326px !important;
  max-width: 284px !important;
}
div.paymentProcessingScreen span.success {
  color: var(--success-color);
}
@media all and (max-width: 640px) {
  div.paymentProcessingScreen div.wrapper {
    width: 90%;
  }
}
div.paymentProcessingScreen div.paymentContainer {
  width: 90%;
  height: 50rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

div.searchPage {
  padding: 100px 5px 40px;
  background-color: var(--bg-color);
}
div.searchPage div.top {
  border-bottom: 1px solid var(--border-color);
}
div.searchPage div.top h1.heading {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--heading-color);
}
@media all and (max-width: 640px) {
  div.searchPage div.top h1.heading {
    font-size: 26px;
  }
}
div.searchPage div.top p.desc {
  font-size: 18px;
  color: var(--heading-color);
  margin-bottom: 15px;
}
@media all and (max-width: 640px) {
  div.searchPage div.top p.desc {
    font-size: 14px;
  }
}
div.searchPage div.bottom {
  margin-top: 10px;
}
div.searchPage div.bottom div.categories {
  display: flex;
  margin-bottom: 20px;
}
div.searchPage div.bottom div.categories button {
  background-color: transparent;
  outline: none;
  color: var(--white-color);
  padding: 8px 30px;
  border-radius: 30px;
  font-size: 16px;
  margin-inline-end: 10px;
  cursor: pointer;
  border: 1px solid transparent;
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 15px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 6px;
    font-size: 13px;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 8px;
    font-size: 10px;
  }
}
@media all and (max-width: 360px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 5px;
  }
}
div.searchPage div.bottom div.categories button:last-child {
  margin-inline-end: 0;
}
div.searchPage div.bottom div.categories button.active {
  border: 1px solid var(--grey-color);
  background-color: var(--secondary-btn-hover);
  color: var(--white-color);
}
div.searchPage div.bottom div.categories button:hover {
  background-color: var(--button-hover);
  color: var(--grey-color);
}
div.searchPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.searchPage div.bottom div.itemsContainer div.items.hidden {
  visibility: hidden;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19.3%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 19.3%;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 16%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount7 {
  width: 13.9%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-inline-end: 0;
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 2600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 7px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(7n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 2500px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 6.5px;
  }
}
@media all and (max-width: 2200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 5px;
  }
}
@media all and (max-width: 1768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 4.8px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.8%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-inline-end: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-inline-end: 8px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-inline-end: 0;
}
@media all and (max-width: 2500px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 2200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 9px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 8px;
  }
}
@media all and (max-width: 1768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 11.9%;
    margin-inline-end: 5px;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
  width: 11%;
  margin-inline-end: 21px;
}
@media all and (max-width: 2600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 2500px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 8px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-inline-end: 6px;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}

div.myList {
  padding: 50px 0 0;
}
div.myList div.top {
  background-color: var(--bg-color);
  padding: 20px 0;
}
div.myList div.top h1.heading {
  font-size: 30px;
  color: var(--heading-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.myList div.top h1.heading {
    font-size: 25px;
  }
}
div.myList div.top p.desc {
  font-size: 18px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.myList div.top p.desc {
    font-size: 14px;
  }
}
div.myList div.bottom {
  background-color: var(--bg-color);
  padding: 20px 5px 0;
}
div.myList div.bottom div.noContentInfo {
  width: 20rem;
  margin: 0 auto;
  padding: 50px 5px 200px;
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.noContentInfo {
    width: 100%;
  }
}
div.myList div.bottom div.noContentInfo h4.heading {
  font-size: 15px;
  color: var(--heading-color);
  margin-bottom: 10px;
  text-align: center;
}
div.myList div.bottom div.noContentInfo p {
  font-size: 14px;
  color: var(--heading-color);
  line-height: 1.8em;
  text-align: center;
}
div.myList div.bottom div.noContentInfo p svg {
  width: 12px !important;
  height: 12px !important;
  fill: var(--text-color);
}
div.myList div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.myList div.bottom div.itemsContainer div.items.hidden {
  visibility: hidden;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19.3%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5 {
    width: 19.3%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount6 {
  width: 16%;
  margin-inline-end: 6px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount7 {
  width: 13.6%;
  margin-inline-end: 11px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7 {
    width: 16%;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7 {
    width: 16%;
  }
}
@media all and (max-width: 2600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13%;
    margin-inline-end: 29px;
  }
}
@media all and (max-width: 2500px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13%;
    margin-inline-end: 25px;
  }
}
@media all and (max-width: 2200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.8%;
    margin-inline-end: 8px;
  }
}
@media all and (max-width: 1980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.9%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.9%;
    margin-inline-end: 5px;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.8%;
    margin-inline-end: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.8%;
    margin-inline-end: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.7%;
    margin-inline-end: 5px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.8%;
    margin-inline-end: 7px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(7n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-inline-end: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-inline-end: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-inline-end: 8px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-inline-end: 0;
}
@media all and (max-width: 2500px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 2200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 9px;
  }
}
@media all and (max-width: 1980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 8px;
  }
}
@media all and (max-width: 1768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-inline-end: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 11.9%;
    margin-inline-end: 5px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
  width: 11%;
  margin-inline-end: 21px;
}
@media all and (max-width: 2600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 2500px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 1980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 8px;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-inline-end: 6px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 24.3%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 24%;
    margin-inline-end: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(6n) {
    margin-inline-end: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}

div.privacyPolicy {
  padding: 130px 0;
  background-color: var(--bg-color);
}
div.privacyPolicy h1.noData {
  text-align: center;
  color: var(--heading-color);
}

div.termsAndConditions {
  padding: 130px 0;
  background-color: var(--bg-color);
}
div.termsAndConditions h1.noData {
  text-align: center;
  color: var(--heading-color);
}

div.contactUs {
  padding: 130px 0;
}

div.contactSupport {
  padding: 130px 0;
  background-color: var(--bg-color);
}
div.contactSupport h1.heading {
  font-size: 28px;
  margin-bottom: 30px;
  color: var(--text-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.contactSupport h1.heading {
    font-size: 28px;
  }
}
div.contactSupport div.formContainer {
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.contactSupport div.formContainer {
    width: 100%;
  }
}
div.contactSupport div.formContainer form div.full {
  margin-bottom: 20px;
}
div.contactSupport div.formContainer form div.full input,
div.contactSupport div.formContainer form div.full select {
  color: var(--white-color) !important;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--text-color);
  caret-color: var(--white-color);
  display: block;
  width: 100%;
  color: var(--white-color);
}
div.contactSupport div.formContainer form div.full input option,
div.contactSupport div.formContainer form div.full select option {
  color: #000 !important;
}
div.contactSupport div.formContainer form div.full input:focus,
div.contactSupport div.formContainer form div.full select:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.contactSupport div.formContainer form div.full input,
  div.contactSupport div.formContainer form div.full select {
    padding: 16px 15px;
  }
}
div.contactSupport div.formContainer form div.full label {
  color: var(--text-color);
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
}
div.contactSupport div.formContainer form div.full.message {
  display: flex;
  flex-direction: column;
}
div.contactSupport div.formContainer form div.full.message textarea {
  height: 150px;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  caret-color: var(--text-color);
  color: var(--white-color);
  padding: 20px 15px;
}
div.contactSupport div.formContainer form div.full.message textarea:focus {
  border: 1px solid var(--white-color);
}
div.contactSupport div.formContainer form div.full div.buttonContainer {
  margin-bottom: 20px;
  text-align: center;
}
div.contactSupport div.formContainer form div.full div.buttonContainer button {
  background-color: var(--button-color);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  border-radius: 3px;
  width: 10rem;
}

div.aboutUs {
  padding: 130px 0;
  background-color: var(--bg-color);
}
div.aboutUs h1.noData {
  text-align: center;
  color: var(--heading-color);
}

div.subscription-plan-containter {
  min-height: 300vh;
  margin-top: 0px;
  position: relative;
}
@media all and (max-width: 768px) {
  div.subscription-plan-containter {
    min-height: 500vh;
  }
}
div.subscription-plan-containter #page-container {
  overflow-y: hidden;
}

div.help-center-containter {
  min-height: 500vh;
  margin-top: 0px;
  position: relative;
}
@media all and (max-width: 900px) and (orientation: landscape) {
  div.help-center-containter {
    min-height: 1000vh;
  }
}
div.help-center-containter #page-container {
  overflow-y: hidden;
}

/* Demo */
/* Animation */
div.loadingContent {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loadingContent div.loadingOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loadingContent div.loading-container {
  width: 256px;
  height: 256px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loadingContent .loader {
  position: relative;
  width: 54px;
  height: 54px;
  display: block;
}
div.loadingContent .loader:before, div.loadingContent .loader:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2) inset;
}
div.loadingContent .loader:after {
  box-shadow: 0 2px 0 var(--button-color) inset;
  animation: rotate 0.9s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

div.newsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--bg-color);
  transition: 0.4s ease-in-out;
}
div.newsCard:hover {
  background-color: var(--secondary-bg-color);
}
@media all and (max-width: 980px) {
  div.newsCard {
    flex-direction: column;
  }
}
div.newsCard div.left {
  width: 25%;
}
@media all and (max-width: 1400px) {
  div.newsCard div.left {
    width: 30%;
  }
}
@media all and (max-width: 1200px) {
  div.newsCard div.left {
    width: 34%;
  }
}
@media all and (max-width: 980px) {
  div.newsCard div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.newsCard div.left div.imageContainer {
  width: 100%;
}
div.newsCard div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.newsCard div.right {
  width: 72%;
}
@media all and (max-width: 1400px) {
  div.newsCard div.right {
    width: 66%;
  }
}
@media all and (max-width: 1200px) {
  div.newsCard div.right {
    width: 63%;
  }
}
@media all and (max-width: 980px) {
  div.newsCard div.right {
    width: 100%;
  }
}
div.newsCard div.right h1.title {
  font-size: 22px;
  margin-bottom: 20px;
  color: var(--white-color);
}
@media all and (max-width: 1200px) {
  div.newsCard div.right h1.title {
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right h1.title {
    font-size: 20px;
  }
}
div.newsCard div.right p {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right p {
    font-size: 13px;
  }
}
div.newsCard div.right div.dateSection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right div.dateSection {
    margin-bottom: 10px;
  }
}
div.newsCard div.right div.dateSection span.icon {
  width: 20px;
  display: inline-block;
  margin-inline-end: 10px;
}
div.newsCard div.right div.dateSection span.icon img {
  width: 100%;
  display: block;
}
div.newsCard div.right div.dateSection span.date {
  font-size: 14px;
  color: var(--text-hover);
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.dateSection span.date {
    font-size: 13px;
  }
}
div.newsCard div.right div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 20px 0 0;
}
@media all and (max-width: 768px) {
  div.newsCard div.right div.buttonContainer {
    margin: 20px 0 0;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.buttonContainer {
    width: 12rem;
  }
}
@media all and (max-width: 480px) {
  div.newsCard div.right div.buttonContainer {
    width: 15rem;
  }
}
div.newsCard div.right div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.newsCard div.right div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.newsCard div.right div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.latestNews {
  padding: 130px 0 80px;
  position: relative;
}
div.latestNews div.head {
  display: flex;
  justify-content: end;
}
@media all and (max-width: 640px) {
  div.latestNews div.head {
    padding-inline-end: 20px;
  }
}
div.latestNews div.head div.logoContainer {
  width: 150px;
}
@media all and (max-width: 640px) {
  div.latestNews div.head div.logoContainer {
    width: 120px;
  }
}
div.latestNews div.head div.logoContainer img {
  display: block;
  width: 100%;
}
div.latestNews div.body h1.heading {
  font-size: 34px;
  color: var(--white-color);
  margin-bottom: 30px;
}
div.latestNews div.body div.gradientBg {
  background: url(./assets/Icons/gradient.svg) repeat-x center bottom;
  background-size: contain;
  position: absolute;
  inset-inline-start: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
}
div.latestNews div.body div.gradientBg div.buttonContainer {
  text-align: center;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
  border: 1px solid var(--text-hover);
  background-color: transparent;
  color: var(--text-hover);
  padding: 6px 20px;
  font-size: 14px;
  border-radius: 6px;
  width: 15%;
  transition: 0.3s ease-in-out;
  margin: 140px auto 0;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 20%;
  }
}
@media all and (max-width: 980px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 25%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 35%;
  }
}
@media all and (max-width: 480px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 45%;
  }
}
@media all and (max-width: 360px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 50%;
  }
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore svg {
  transition: 0.3s ease-in-out;
  margin-inline-start: 6px;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore:hover {
  background-color: var(--text-hover);
  color: var(--white-color);
}

div.articleInfo {
  padding: 130px 0 80px;
}
div.articleInfo div.wrapper {
  width: 80%;
}
div.articleInfo div.wrapper h1.heading {
  font-size: 32px;
  color: var(--white-color);
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper h1.heading {
    padding-inline-start: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper div.dateSection {
    padding-inline-start: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection span.icon {
  width: 20px;
  display: block;
  margin-inline-end: 10px;
}
div.articleInfo div.wrapper div.dateSection span.icon img {
  display: block;
  width: 100%;
}
div.articleInfo div.wrapper div.dateSection span.date {
  font-size: 12px;
  color: var(--text-hover);
}
div.articleInfo div.wrapper div.articleDetails {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 20px auto 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails {
    width: 90%;
  }
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails {
    flex-direction: column;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left {
  width: 100%;
  margin-bottom: 30px;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer {
  width: 100%;
  margin: 0 auto;
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.articleInfo div.wrapper div.articleDetails div.right {
  width: 100%;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.right {
    width: 100%;
  }
}
div.articleInfo div.wrapper div.articleDetails div.right p {
  font-size: 15px;
  margin-bottom: 20px;
  color: var(--text-color);
}
div.articleInfo div.wrapper div.articleDetails div.right p:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails div.right p {
    font-size: 13px;
  }
}
div.articleInfo div.wrapper div.videoContainer {
  width: 80%;
  margin: 90px auto 0;
}
div.articleInfo div.wrapper div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.videoContainer {
    width: 90%;
  }
}
div.articleInfo div.wrapper div.buttonContainer {
  text-align: center;
  margin-top: 50px;
}
div.articleInfo div.wrapper div.buttonContainer button {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 6px 10px;
  width: 15%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 35%;
    padding: 6px 15px;
  }
}
div.articleInfo div.wrapper div.buttonContainer button span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button svg {
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button:hover {
  border-color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover span {
  color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover svg {
  fill: var(--icons-color);
}

div.landingScreen {
  padding: 70px 0 0;
}
div.landingScreen h1.heading {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 45px;
  font-weight: 500;
}
@media all and (max-width: 1400px) {
  div.landingScreen h1.heading {
    font-size: 38px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen h1.heading {
    font-size: 31px;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen h1.heading {
    font-size: 27px;
  }
}
div.landingScreen p.description {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 23px;
}
@media all and (max-width: 1400px) {
  div.landingScreen p.description {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen p.description {
    font-size: 17px;
  }
}
div.landingScreen div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer {
    margin: 20px 0 0;
    width: 13rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.buttonContainer {
    width: 12rem;
    margin: 0 auto;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.buttonContainer {
    width: 15rem;
  }
}
div.landingScreen div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.landingScreen div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.landingScreen div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer button {
    font-size: 13px;
  }
}
div.landingScreen div.spotlight {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.spotlight.rightType {
  position: relative;
}
div.landingScreen div.spotlight.rightType div.left {
  width: 45%;
  position: absolute;
  inset-inline-start: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    inset-inline-start: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -44%;
  }
}
div.landingScreen div.spotlight.rightType div.right {
  width: 60%;
  position: absolute;
  inset-inline-end: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.right {
    inset-inline-start: unset;
    top: 0;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.watchNowSection {
  display: flex;
  color: var(--black-color);
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection {
    flex-direction: column;
    height: 269px;
    margin-top: 99px;
  }
}
div.landingScreen div.watchNowSection div.left {
  width: 60%;
  height: 100%;
  position: absolute;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.left {
    inset-inline-start: unset;
    top: 0;
    width: 100%;
    height: 55%;
  }
}
div.landingScreen div.watchNowSection div.left div.imageContainer {
  height: 100%;
  width: 100%;
}
div.landingScreen div.watchNowSection div.left div.imageContainer img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  width: 100%;
}
div.landingScreen div.watchNowSection div.right {
  width: 50%;
  position: absolute;
  inset-inline-end: 0;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to left, #000 87%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    inset-inline-end: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -47%;
  }
}
div.landingScreen div.watchNowSection div.right h1,
div.landingScreen div.watchNowSection div.right p,
div.landingScreen div.watchNowSection div.right div.buttonContainer {
  margin-inline-start: 80px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right h1,
  div.landingScreen div.watchNowSection div.right p,
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin-inline-start: 0;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin: 0 auto;
  }
}
div.landingScreen div.watchNowSection div.right p {
  font-size: 20px;
}
@media all and (max-width: 1400px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 17px;
  }
}
div.landingScreen div.other.rightType {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.other.rightType div.left {
  width: 45%;
  position: absolute;
  inset-inline-start: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    inset-inline-start: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -91%;
  }
}
div.landingScreen div.other.rightType div.right {
  width: 60%;
  position: absolute;
  inset-inline-end: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.right {
    inset-inline-start: unset;
    top: 129px;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.other.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.other.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.device {
  display: flex;
  background: var(--white-color);
  padding-top: 30px;
  justify-content: space-between;
  padding-bottom: 100px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.device {
    margin-top: 245px;
    flex-direction: column;
  }
}
div.landingScreen div.device div.left {
  width: 40%;
  padding-inline-start: 60px;
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.left {
    padding-inline-start: 30px;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.landingScreen div.device div.left h1.heading {
  color: var(--black-color);
}
div.landingScreen div.device div.left p.description {
  color: var(--text-color);
}
div.landingScreen div.device div.right {
  width: 40%;
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right {
    width: 43%;
  }
}
@media all and (max-width: 1600px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 1400px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right {
    width: 56%;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right {
    width: 91%;
    margin: 0 auto;
  }
}
div.landingScreen div.device div.right ul.devices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
div.landingScreen div.device div.right ul.devices li {
  cursor: pointer;
  width: 17rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 6px;
  margin-bottom: 6px;
  background: rgb(54, 124, 160);
  background: linear-gradient(90deg, rgb(54, 124, 160) 0%, rgb(14, 64, 115) 35%, rgb(0, 41, 97) 100%);
  transition: 0.2s ease-in-out;
}
div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 2200px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 15rem;
  }
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
    height: 9rem;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 12rem;
    height: 7rem;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 360px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 100%;
    margin-inline-end: 0;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-inline-end: 0;
  }
}
div.landingScreen div.device div.right ul.devices li:hover {
  box-shadow: 0px 1px 6px 4px #333;
}
div.landingScreen div.device div.right ul.devices li span {
  color: var(--white-color);
  font-weight: 700;
  font-size: 30px;
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li span {
    font-size: 24px;
  }
}

div.landingScreen.rtl .other.rightType .left {
  background: none;
  background: linear-gradient(to left, #000 80%, transparent 100%);
}
div.landingScreen.rtl .spotlight.rightType .left {
  background: none;
  background: linear-gradient(to left, #000 80%, transparent 100%);
}
div.landingScreen.rtl .watchNowSection .right {
  background: none;
  background: linear-gradient(to right, #000 87%, transparent 100%);
}

div.notFound {
  padding: 60px 0;
}
@media all and (max-width: 768px) {
  div.notFound {
    padding: 80px 0;
  }
}
@media all and (max-width: 480px) {
  div.notFound {
    padding: 100px 0;
  }
}
div.notFound div.imageContainer {
  width: 40%;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.notFound div.imageContainer {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.imageContainer {
    width: 60%;
  }
}
div.notFound div.imageContainer img {
  width: 100%;
  display: block;
}
div.notFound div.content {
  text-align: center;
}
div.notFound div.content h1 {
  font-size: 80px;
  color: var(--text-hover);
  line-height: 0.8em;
}
@media all and (max-width: 768px) {
  div.notFound div.content h1 {
    font-size: 70px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content h1 {
    font-size: 60px;
  }
}
div.notFound div.content p {
  font-size: 25px;
  color: var(--text-color);
}
@media all and (max-width: 768px) {
  div.notFound div.content p {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content p {
    font-size: 16px;
  }
}
@media all and (max-width: 360px) {
  div.notFound div.content p {
    font-size: 14px;
  }
}
div.notFound div.content div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.notFound div.content div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content div.buttonContainer {
    width: 10rem;
  }
}
div.notFound div.content div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.notFound div.content div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.notFound div.content div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.playerShowCard {
  height: 100%;
}
div.playerShowCard div.imageContainer {
  width: 100%;
  position: relative;
  height: 100%;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer {
    height: 400px;
  }
}
div.playerShowCard div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer img {
    height: 400px;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
div.playerShowCard div.imageContainer div.playIconContainer {
  position: absolute;
  transform: translate(-50%, -50%);
  border: 1px solid var(--white-color);
  z-index: 999;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
div.playerShowCard div.imageContainer div.playIconContainer svg {
  color: var(--white-color);
  width: 30px !important;
  height: 30px !important;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.playIconContainer {
    padding: 9px;
  }
  div.playerShowCard div.imageContainer div.playIconContainer svg {
    width: 20px !important;
    height: 20px !important;
  }
}
div.playerShowCard div.imageContainer div.playIconContainer:hover {
  border: 1px solid var(--button-hover);
  background-color: var(--button-hover);
}
div.playerShowCard div.imageContainer div.metaData {
  position: absolute;
  z-index: 999;
  width: 100%;
  inset-inline-start: 50px;
  top: 30%;
  transition: 0.4s ease-in-out;
  visibility: hidden;
}
@media all and (max-width: 2600px) {
  div.playerShowCard div.imageContainer div.metaData {
    inset-inline-start: 77px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData {
    width: 90%;
  }
}
div.playerShowCard div.imageContainer div.metaData.show {
  visibility: visible;
  animation: fadeIn 1.5s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.playerShowCard div.imageContainer div.metaData {
    inset-inline-start: 42px;
  }
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData {
    inset-inline-start: 22px;
  }
}
div.playerShowCard div.imageContainer div.metaData.gagalin h1.showName {
  animation: fadeIn 0.5s ease-in-out;
  font-family: "Gagalin-Regular", sans-serif !important;
}
div.playerShowCard div.imageContainer div.metaData.gota h1.showName {
  animation: fadeIn 0.5s ease-in-out;
  font-size: 40px;
  font-family: "gota-Regular", sans-serif !important;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData.gota h1.showName {
    font-size: 25px;
  }
}
div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
  width: 30rem;
  transition: 0.9s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
  position: relative;
  inset-inline-start: 0;
  margin-bottom: 10px;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
@media all and (max-width: 480px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
div.playerShowCard div.imageContainer div.metaData h1.showName {
  font-size: 50px;
  font-weight: 700;
  color: var(--white-color);
  text-transform: uppercase;
  transition: 0.9s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
  position: relative;
  inset-inline-start: 0;
  margin-bottom: 10px;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName {
    inset-inline-start: 0;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName {
    inset-inline-start: 0;
  }
}
@media all and (max-width: 480px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName {
    inset-inline-start: 0;
  }
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName {
    font-size: 45px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName {
    font-size: 25px;
  }
}
div.playerShowCard div.imageContainer div.metaData p.description {
  color: var(--text-color);
  font-size: 16px;
  max-width: 70%;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData p.description {
    font-size: 13px;
    max-width: 59%;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData p.description {
    max-width: 100%;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer {
  display: flex;
  margin-top: 20px;
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button {
  display: flex;
  padding: 7px 8px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button:focus {
  border: 2px solid var(--white-color);
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button {
    padding: 3px 7px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button {
    padding: 5px 7px;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon {
  margin-inline-end: 10px;
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon svg {
  width: 27px !important;
  height: 27px !important;
  display: block;
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon svg {
    width: 20px !important;
    height: 28px !important;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon svg {
    width: 15px !important;
    height: 15px !important;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon img {
  width: 100%;
  display: block;
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button span {
  font-size: 17px;
  font-weight: 500;
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button span {
    font-size: 15px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button span {
    font-size: 12px;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play {
  width: 8rem;
  background-color: var(--primary-btn-color);
  margin-inline-end: 10px;
  border: 2px solid transparent;
  color: var(--black-color);
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play svg {
  color: var(--black-color);
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play {
    width: 7rem;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play {
    width: 6rem;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo {
  width: 10rem;
  background-color: var(--secondary-btn-color);
  color: var(--text-color);
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo:hover {
  background-color: var(--secondary-btn-hover);
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo {
    width: 8rem;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo {
    width: 7rem;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo div.icon svg {
  width: 25px !important;
  height: 25px !important;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo div.icon svg {
    width: 18px !important;
    height: 18px !important;
  }
}
div.playerShowCard div.imageContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}

div.categoriesShowCard {
  position: relative;
  height: 650px;
  margin-top: 30px;
}
div.categoriesShowCard div.backgroundImageContainer {
  width: 100%;
  display: block;
  height: 100%;
}
div.categoriesShowCard div.backgroundImageContainer img.bgImage {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
  position: absolute;
  top: 30px;
  inset-inline-start: 130px;
  z-index: 9;
}
@media all and (max-width: 1200px) {
  div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
    inset-inline-start: 77px;
  }
}
@media all and (max-width: 768px) {
  div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
    inset-inline-start: 57px;
  }
}
@media all and (max-width: 640px) {
  div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
    inset-inline-start: 30px;
  }
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo h1 {
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  cursor: pointer;
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo h1::first-line {
  color: rgb(255, 234, 0);
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo p.description {
  font-size: 14px;
  color: var(--white-color);
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows {
  position: absolute;
  bottom: 30px;
  width: 90%;
  inset-inline-end: 0;
  z-index: 9;
}
@media all and (max-width: 640px) {
  div.categoriesShowCard div.backgroundImageContainer div.AllShows {
    width: 84% !important;
    inset-inline-end: 38px;
  }
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows div.shows {
  width: 100%;
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-prev {
  background: transparent !important;
  left: -130px !important;
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-prev.swiper-button-disabled {
  display: none;
}
@media all and (max-width: 1200px) {
  div.categoriesShowCard div.backgroundImageContainer div.AllShows {
    width: 98%;
  }
  div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-next, div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-prev {
    display: none;
  }
}
div.categoriesShowCard div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}

div.trailerModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.trailerModal div.head {
  position: fixed;
  z-index: 9999;
  color: var(--white-color);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 20px;
  inset-inline-start: 0;
}
div.trailerModal div.head div.left h4.heading {
  font-size: 13px;
}
div.trailerModal div.head div.left h1.title {
  font-size: 22px;
  text-transform: uppercase;
}
div.trailerModal div.head div.left span.director {
  font-weight: 700;
  font-size: 14px;
  margin-inline-end: 5px;
}
div.trailerModal div.head div.left span.year {
  font-weight: normal;
  font-size: 13px;
}
div.trailerModal div.head div.right div.closeIcon {
  cursor: pointer;
}
div.trailerModal div.head div.right div.closeIcon svg {
  stroke: var(--white-color) !important;
}
div.trailerModal div.trailerPlayer {
  z-index: 9999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 1400px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.trailerModal div.trailerPlayer {
    top: 45%;
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.trailerModal div.trailerPlayer {
    width: 95%;
  }
}
div.trailerModal div.trailerPlayer video {
  width: 100%;
  display: block;
}

div.smallLoading div.loading-container {
  position: absolute;
  z-index: 99999999;
}
div.smallLoading .nf-loading-spinner {
  animation: load_spinner 0.9s linear infinite;
  background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/site-spinner.png);
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 1px;
  left: 1px;
  margin: auto;
  position: absolute;
  right: 1px;
  text-align: center;
  top: 1px;
  width: 20px;
  height: 20px;
}
@keyframes load_spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

div.videoPlayer {
  background-color: var(--black-color);
  height: 100vh;
  position: relative;
}
div.videoPlayer div.topContainer {
  position: absolute;
  left: 3%;
  top: 5%;
  z-index: 99;
  display: flex;
  align-items: center;
}
div.videoPlayer div.topContainer div.backButton {
  margin-right: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.videoPlayer div.topContainer div.backButton:hover {
  transform: scale(1.2);
}
div.videoPlayer div.topContainer div.backButton svg {
  color: var(--white-color) !important;
  width: 35px !important;
  height: 35px !important;
}
div.videoPlayer div.topContainer h1.title {
  color: var(--white-color);
  font-size: 28px;
}
div.videoPlayer video {
  height: 100% !important;
  display: block !important;
  width: 100% !important;
}
@media all and (max-width: 480px) {
  div.videoPlayer video.video-fullscreen {
    -o-object-fit: contain;
       object-fit: contain;
    transform: rotate(90deg) !important;
    margin-left: -65px;
    padding: 0;
    width: 130% !important;
    height: 100%;
    left: 0;
  }
}
@media all and (max-width: 360px) {
  div.videoPlayer video.video-fullscreen {
    -o-object-fit: contain;
       object-fit: contain;
    transform: rotate(90deg) !important;
    margin-left: -60px;
    padding: 0px;
    width: 140% !important;
    height: 100%;
  }
}
div.videoPlayer.fullscreen {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  background-color: var(--black-color);
}
@media all and (max-width: 480px) {
  div.videoPlayer.rotate-fullscreen {
    transform: rotate(90deg) !important;
    transform-origin: center center !important;
    width: 100vh !important;
    height: 100vw !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
  }
  div.videoPlayer.rotate-fullscreen .vjs-tech.video-rotate {
    transform: rotate(90deg);
    width: 150% !important;
    left: -24%;
    top: -2%;
  }
  div.videoPlayer.rotate-fullscreen .vjs-control-bar {
    transform: rotate(90deg);
    left: -62%;
    top: 46%;
    width: 150%;
  }
  div.videoPlayer.rotate-fullscreen .ima-ad-container,
  div.videoPlayer.rotate-fullscreen .ima-controls-div {
    transform: rotate(90deg);
  }
  div.videoPlayer.rotate-fullscreen .vjs-text-track-cue {
    transform: rotate(90deg);
    margin-left: -43%;
    margin-top: -86%;
    font: 20px sans-serif !important;
  }
}
@media all and (max-width: 360px) {
  div.videoPlayer.rotate-fullscreen {
    transform: rotate(90deg) !important;
    transform-origin: center center !important;
    width: 100vh !important;
    height: 100vw !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
  }
  div.videoPlayer.rotate-fullscreen .vjs-tech.video-rotate {
    transform: rotate(90deg);
    width: 160% !important;
    left: -27%;
  }
  div.videoPlayer.rotate-fullscreen .vjs-control-bar {
    transform: rotate(90deg);
    left: -68%;
    top: 46%;
    width: 160%;
  }
  div.videoPlayer.rotate-fullscreen .ima-ad-container,
  div.videoPlayer.rotate-fullscreen .ima-controls-div {
    transform: rotate(90deg);
  }
  div.videoPlayer.rotate-fullscreen .vjs-text-track-cue {
    transform: rotate(90deg);
    margin-left: -41%;
    margin-top: -100%;
  }
}
div.videoPlayer .vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 0 !important;
}
div.videoPlayer .vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 0 !important;
}
div.videoPlayer .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
  height: 100% !important;
}
@media all and (max-width: 480px) {
  div.videoPlayer .video-js.vjs-fluid:not(.vjs-audio-only-mode) video.video-fullscreen {
    -o-object-fit: contain;
       object-fit: contain;
    transform: rotate(90deg) !important;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    margin-left: 0%;
  }
}
@media all and (min-width: 768px) {
  div.videoPlayer:hover div.bottom div.details {
    transform: translateY(0);
  }
}
div.videoPlayer div.bottom {
  width: 90%;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  div.videoPlayer div.bottom {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
div.videoPlayer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.videoPlayer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.videoPlayer div.bottom div.details h1.heading {
  font-size: 28px;
  color: var(--text-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.videoPlayer div.bottom div.details h1.heading {
    font-size: 22px;
  }
}
div.videoPlayer div.bottom div.details h1.title {
  font-size: 20px;
  color: var(--text-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.videoPlayer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--inner-text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.videoPlayer div.bottom div.details p.description {
  color: var(--text-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.videoPlayer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.videoPlayer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.videoPlayer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.videoPlayer div.bottom div.details div.right {
    width: 100%;
  }
}

div.mainLoading {
  padding: 130px 0 350px 60px;
}
@media all and (max-width: 640px) {
  div.mainLoading {
    padding: 130px 0 350px 30px;
  }
}
div.mainLoading div.topLoader {
  width: 100px;
  height: 20px;
  margin-bottom: 20px;
  animation: skeleton-loading1 0.5s linear infinite alternate;
}
div.mainLoading div.lists {
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
div.mainLoading div.lists::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
div.mainLoading div.lists ul.loaders {
  display: flex;
  width: 2900px;
}
div.mainLoading div.lists ul.loaders li {
  width: 210px;
  height: 110px;
  background-color: var(--transparent-color);
  margin-inline-end: 3px;
  margin-bottom: 20px;
  opacity: 0;
  background-color: hsl(0, 0%, 16%);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@media all and (max-width: 2500px) {
  div.mainLoading div.lists ul.loaders li {
    width: 250px;
  }
}
@media all and (max-width: 1600px) {
  div.mainLoading div.lists ul.loaders li {
    width: 210px;
  }
}
@media all and (max-width: 980px) {
  div.mainLoading div.lists ul.loaders li {
    width: 170px;
    height: 100px;
  }
}
@media all and (max-width: 640px) {
  div.mainLoading div.lists ul.loaders li {
    width: 130px;
    height: 80px;
  }
}
@media all and (max-width: 480px) {
  div.mainLoading div.lists ul.loaders li {
    width: 110px;
    height: 60px;
  }
}
div.mainLoading div.lists ul.loaders li:nth-child(1) {
  opacity: 0.2;
  animation: skeleton-loading1 0.5s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(1) {
  opacity: 0.3;
  animation: skeleton-loading2 0.6s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(2) {
  opacity: 0.4;
  animation: skeleton-loading3 0.7s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(3) {
  opacity: 0.5;
  animation: skeleton-loading4 0.8s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(4) {
  opacity: 0.6;
  animation: skeleton-loading5 0.9s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(5) {
  opacity: 0.7;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(6) {
  opacity: 0.8;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(7) {
  opacity: 0.9;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(7) {
  opacity: 1;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(8) {
  opacity: 1.1;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(9) {
  opacity: 1.2;
  animation: skeleton-loading6 1s linear alternate;
}
@keyframes skeleton-loading1 {
  0% {
    background-color: hsl(210, 1%, 36%);
  }
  100% {
    background-color: hsl(0, 0%, 16%);
    opacity: 0.3;
  }
}
@keyframes skeleton-loading2 {
  0% {
    background-color: hsl(210, 1%, 36%);
  }
  100% {
    background-color: hsl(0, 0%, 16%);
    opacity: 0.4;
  }
}
@keyframes skeleton-loading3 {
  0% {
    background-color: hsl(210, 1%, 36%);
  }
  100% {
    background-color: hsl(0, 0%, 16%);
    opacity: 0.5;
  }
}
@keyframes skeleton-loading4 {
  0% {
    background-color: hsl(210, 1%, 36%);
  }
  100% {
    background-color: hsl(0, 0%, 16%);
    opacity: 0.6;
  }
}
@keyframes skeleton-loading5 {
  100% {
    opacity: 0.7;
  }
}
@keyframes skeleton-loading6 {
  100% {
    opacity: 0.8;
  }
}

.rtl {
  direction: rtl;
  text-align: right;
}

div.detailsScreenModal {
  padding-bottom: 60px;
}
div.detailsScreenModal div.imgVideoContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 450px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer {
    height: 326px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer {
    height: 252px;
  }
}
div.detailsScreenModal div.imgVideoContainer.noHeight {
  height: unset;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer.noHeight div.bottomVignette {
    bottom: -31px;
  }
}
div.detailsScreenModal div.imgVideoContainer video {
  z-index: 3;
}
div.detailsScreenModal div.imgVideoContainer img {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
div.detailsScreenModal div.imgVideoContainer img.show {
  display: block;
}
div.detailsScreenModal div.imgVideoContainer img.hide {
  display: none;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton {
  position: absolute;
  inset-inline-end: 10px;
  top: 10px;
  z-index: 9999;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton button {
  background-color: var(--bg-color);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 0;
  cursor: pointer;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton button svg {
  stroke: var(--white-color) !important;
}
div.detailsScreenModal div.imgVideoContainer div.bottomVignette {
  background-color: transparent;
  background-image: linear-gradient(180deg, hsla(0, 0%, 8%, 0) 0, hsla(0, 0%, 30%, 0.15) 15%, hsla(0, 0%, 8%, 0.35) 29%, hsla(0, 0%, 8%, 0.58) 40%, #141414 84%, #141414);
  background-position: 0 top;
  background-repeat: repeat-x;
  background-size: 100% 100%;
  bottom: -8px;
  height: 200px;
  opacity: 1;
  top: auto;
  width: 100%;
  z-index: 99;
  position: absolute;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.bottomVignette {
    bottom: 31px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions {
  position: absolute;
  bottom: 40px;
  z-index: 999;
  width: 100%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions h1.title {
  padding: 0 40px;
  animation: fadeIn 0.4s ease-in-out;
  font-size: 60px;
  z-index: 99;
  font-family: "Gagalin-Regular", sans-serif !important;
  color: var(--white-color);
  max-width: 80%;
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions h1.title {
    font-size: 40px;
    padding: 0 18px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions h1.title {
    font-size: 21px;
    margin-bottom: 6px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons {
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons {
    padding: 0 18px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left {
  display: flex;
  align-items: center;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play {
  display: flex;
  padding: 6px 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  width: 7.5rem;
  background-color: var(--primary-btn-color);
  margin-inline-end: 10px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play {
    width: 5.5rem;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon {
  margin-inline-end: 10px;
  line-height: 0;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon svg {
  width: 25px !important;
  height: 25px !important;
  color: var(--black-color);
  display: block;
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon svg {
    width: 16px !important;
    height: 16px !important;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon img {
  width: 100%;
  display: block;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play span {
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play span {
    font-size: 12px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  margin-inline-end: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe:hover {
  background-color: var(--secondary-hover-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe span {
  font-size: 14px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow {
  display: flex;
  padding: 6px 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  width: 8.5rem;
  background-color: var(--primary-btn-color);
  margin-inline-end: 10px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow:hover {
  background-color: var(--primary-btn-hover);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow {
    width: 6.5rem;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow div.icon {
  margin-inline-end: 10px;
  line-height: 0;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow div.icon svg {
  width: 25px !important;
  height: 25px !important;
  color: var(--black-color);
  display: block;
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow div.icon svg {
    width: 16px !important;
    height: 16px !important;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow div.icon img {
  width: 100%;
  display: block;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow span {
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.watchnow span {
    font-size: 12px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -50px;
  width: 10rem;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip.remove {
  width: 12rem;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  inset-inline-start: 50%;
  margin-inline-start: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share {
  position: relative;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
  margin-inline-start: 10px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
  position: absolute;
  top: -126px;
  inset-inline-start: -74px;
  z-index: 999;
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 2500px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    inset-inline-start: -89px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 200px;
    inset-inline-start: -74px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 232px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 11rem;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer.active {
  display: block;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
  content: "";
  bottom: -8px;
  width: 0;
  height: 0;
  border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
    left: 50%;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
    border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button {
  background-color: rgba(42, 42, 42, 0.6);
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.12em solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
  opacity: 0.3;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button:hover {
  opacity: 1;
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button:hover svg {
  color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button svg {
  transition: 0.2s ease-in-out;
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
}
div.detailsScreenModal div.info {
  padding: 0 40px;
  margin-top: 5px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info {
    padding: 0 18px;
  }
}
div.detailsScreenModal div.info div.metaData {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData {
    flex-direction: column;
  }
}
div.detailsScreenModal div.info div.metaData div.left {
  width: 60%;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.detailsScreenModal div.info div.metaData div.left div.top {
  display: flex;
  align-items: center;
}
div.detailsScreenModal div.info div.metaData div.left div.top span {
  font-size: 14px;
  margin-inline-end: 10px;
}
div.detailsScreenModal div.info div.metaData div.left div.top span.duration {
  color: var(--duration-color);
}
div.detailsScreenModal div.info div.metaData div.left div.top span.rating {
  padding: 1px 6px;
  border: 1px solid var(--border-color);
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio {
  font-size: 14px;
  position: relative;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -41px;
  width: 145px;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9;
  inset-inline-start: -40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  inset-inline-start: 50%;
  margin-inline-start: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom {
  margin-top: 30px;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom p.description {
  font-size: 14px;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button {
  margin-inline-end: 6px;
  margin-bottom: 6px;
  padding: 6px 8px;
  background-color: var(--secondary-color);
  display: block;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button:hover {
  background-color: var(--secondary-hover-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free {
  background-color: var(--freeBtn-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free:hover {
  background-color: var(--freeBtn-hover);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free span {
  color: var(--white-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button span {
  color: var(--white-color);
  font-size: 12px;
  display: block;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button:last-child {
  margin-inline-end: 0;
}
div.detailsScreenModal div.info div.metaData div.right {
  width: 35%;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData div.right {
    width: 100%;
  }
}
div.detailsScreenModal div.info div.metaData div.right span.heading {
  font-size: 12px;
  color: var(--grey-color);
  margin-inline-end: 3px;
}
div.detailsScreenModal div.info div.metaData div.right div.cast,
div.detailsScreenModal div.info div.metaData div.right div.crew,
div.detailsScreenModal div.info div.metaData div.right div.categories, div.detailsScreenModal div.info div.metaData div.right div.director {
  margin-bottom: 10px;
}
div.detailsScreenModal div.info div.metaData div.right div.cast span,
div.detailsScreenModal div.info div.metaData div.right div.crew span,
div.detailsScreenModal div.info div.metaData div.right div.categories span, div.detailsScreenModal div.info div.metaData div.right div.director span {
  font-size: 13px;
  margin-inline-end: 4px;
}
div.detailsScreenModal div.info div.metaData div.right :last-child {
  margin-bottom: 0;
}
div.detailsScreenModal div.info div.showTrailerContainer {
  margin-top: 20px;
}
div.detailsScreenModal div.info div.showTrailerContainer h1.heading {
  color: var(--heading-color);
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 15px;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer {
  width: 200px;
  position: relative;
  cursor: pointer;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer:hover div.playIconContainer {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 3px;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer div.playIconContainer {
  align-items: center;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  background: rgba(30, 30, 20, 0.5);
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 9;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer div.playIconContainer svg {
  width: 25px !important;
  height: 25px !important;
}
div.detailsScreenModal div.info div.similiarShowsContainer {
  margin-top: 40px;
}
div.detailsScreenModal div.info div.similiarShowsContainer h1.heading {
  color: var(--heading-color);
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 20px;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
  width: 31.7%;
  margin-inline-end: 17px;
  margin-bottom: 20px;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:last-child {
  margin-inline-end: 0;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 48.7%;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-inline-end: 17px;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 48.4%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    margin-inline-end: 10px;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 100%;
    margin-inline-end: 0;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-inline-end: 0;
  }
}

div.episodeContainer {
  margin-top: 80px;
}
div.episodeContainer div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
div.episodeContainer div.head div.left h1.heading {
  font-size: 22px;
  color: var(--heading-color);
}
div.episodeContainer div.head div.right div.seasonContainer {
  border: 0.1em solid rgb(77, 77, 77);
  background-color: rgb(36, 36, 36);
  padding: 6px 12px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}
div.episodeContainer div.head div.right div.seasonContainer svg {
  width: 23px !important;
  height: 23px !important;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer {
  position: absolute;
  top: 45px;
  max-height: 150px;
  overflow-y: scroll;
  border: 0.1em solid rgb(77, 77, 77);
  background-color: rgb(36, 36, 36);
  width: 200px;
  inset-inline-end: 0;
  z-index: 9;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer.active {
  display: block;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer.noActive {
  display: none;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer::-webkit-scrollbar-thumb {
  background-color: var(--grey-color);
  border-radius: 30px;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer::-webkit-scrollbar-track {
  background-color: rgb(36, 36, 36);
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer li {
  padding: 8px;
  font-size: 16px;
  font-weight: 600;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer li:hover {
  background-color: var(--bg-color);
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer li span.episodes {
  font-size: 14px;
  font-weight: normal;
  margin-inline-start: 6px;
}

div.episodeCard {
  display: flex;
  padding: 25px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;
}
@media all and (max-width: 640px) {
  div.episodeCard {
    flex-direction: column;
  }
}
div.episodeCard.episodePortrait {
  align-items: center;
}
div.episodeCard:hover {
  background-color: #333;
}
div.episodeCard:hover div.leftContainer div.imageContainer div.playIconContainer {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.episodeCard div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.episodeCard div.premiumStatus div.premium {
    width: 26px;
  }
}
div.episodeCard div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.episodeCard div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.episodeCard div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  width: 30px;
}
@media all and (max-width: 1200px) {
  div.episodeCard div.premiumStatus div.free {
    width: 30px;
  }
}
div.episodeCard div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.episodeCard div.leftContainer {
  display: flex;
  align-items: center;
  width: 25%;
  margin-inline-end: 10px;
}
@media all and (max-width: 768px) {
  div.episodeCard div.leftContainer {
    margin-inline-end: 15px;
  }
}
@media all and (max-width: 640px) {
  div.episodeCard div.leftContainer {
    width: 80%;
    flex-direction: column;
    margin: 0 auto 20px;
  }
}
@media all and (max-width: 480px) {
  div.episodeCard div.leftContainer {
    width: 45%;
  }
}
div.episodeCard div.leftContainer span.sNo {
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.episodeCard div.leftContainer span.sNo {
    display: none;
  }
}
div.episodeCard div.leftContainer div.imageContainer {
  width: 110px;
  margin-inline-start: 18px;
  position: relative;
}
@media all and (max-width: 640px) {
  div.episodeCard div.leftContainer div.imageContainer {
    margin: 0 auto;
  }
}
div.episodeCard div.leftContainer div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 3px;
}
div.episodeCard div.leftContainer div.imageContainer div.playIconContainer {
  align-items: center;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  background: rgba(30, 30, 20, 0.5);
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 9;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.episodeCard div.leftContainer div.imageContainer div.playIconContainer svg {
  width: 25px !important;
  height: 25px !important;
}
div.episodeCard div.leftContainer div.imageContainer div.continueWatchingBar {
  position: absolute;
  bottom: 0;
  height: 0.2em;
  background-color: var(--grey-color);
  width: 100%;
  border-radius: 3px;
}
div.episodeCard div.leftContainer div.imageContainer div.continueWatchingBar div.line {
  height: 100%;
  border-radius: 3px;
  background-color: var(--secondary-color);
}
div.episodeCard div.rightContainer {
  width: 68%;
}
@media all and (max-width: 640px) {
  div.episodeCard div.rightContainer {
    width: 100%;
  }
}
div.episodeCard div.rightContainer div.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}
@media all and (max-width: 640px) {
  div.episodeCard div.rightContainer div.top div.left {
    margin-inline-end: 20px;
  }
}
div.episodeCard div.rightContainer div.top div.left h4 {
  font-size: 13px;
  font-weight: 600;
  color: var(--heading-color);
}
@media all and (max-width: 480px) {
  div.episodeCard div.rightContainer div.top div.left h4 {
    font-size: 11px;
  }
}
div.episodeCard div.rightContainer div.top div.right span {
  font-size: 15px;
}
div.episodeCard div.rightContainer div.bottom p.description {
  font-size: 12px;
  color: var(--text-color);
}
@media all and (max-width: 480px) {
  div.episodeCard div.rightContainer div.bottom p.description {
    font-size: 10px;
  }
}
div.episodeCard div.rightContainer div.bottom p.description span.moreLess {
  cursor: pointer;
  color: var(--secondary-color);
  font-size: 11px;
}
@media all and (max-width: 480px) {
  div.episodeCard div.rightContainer div.bottom p.description span.moreLess {
    font-size: 10px;
  }
}
div.episodeCard div.rightContainer div.bottom p.description span.moreLess:hover {
  text-decoration: underline;
}

div.similiarShowsCard {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  cursor: pointer;
}
div.similiarShowsCard:hover div.imageContainer div.playIconContainer {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.similiarShowsCard div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.similiarShowsCard div.premiumStatus div.premium {
    width: 26px;
  }
}
div.similiarShowsCard div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.similiarShowsCard div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.similiarShowsCard div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  width: 30px;
}
@media all and (max-width: 1200px) {
  div.similiarShowsCard div.premiumStatus div.free {
    width: 30px;
  }
}
div.similiarShowsCard div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.similiarShowsCard div.imageContainer {
  width: 100%;
  position: relative;
}
div.similiarShowsCard div.imageContainer::before {
  background-image: linear-gradient(198deg, rgba(0, 0, 0, 0.9), hsla(0, 0%, 9%, 0.5) 20%, transparent 28%);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
div.similiarShowsCard div.imageContainer img {
  width: 100%;
  display: block;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
div.similiarShowsCard div.imageContainer span.duration {
  position: absolute;
  top: 10px;
  inset-inline-end: 10px;
  font-size: 12px;
}
div.similiarShowsCard div.imageContainer div.playIconContainer {
  align-items: center;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  background: rgba(30, 30, 20, 0.5);
  cursor: pointer;
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 9;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.similiarShowsCard div.imageContainer div.playIconContainer svg {
  width: 31px !important;
  height: 31px !important;
}
div.similiarShowsCard div.metaInformation {
  padding: 15px 13px 30px;
  background-color: #2f2f2f;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-height: 217px;
  max-height: 217px;
}
div.similiarShowsCard div.metaInformation h1.showName {
  color: var(--duration-color);
  font-size: 14px;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.similiarShowsCard div.metaInformation div.extraInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.left span {
  font-size: 14px;
  margin-inline-end: 6px;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.left span.rating {
  padding: 1px 6px;
  border: 1px solid var(--border-color);
}
div.similiarShowsCard div.metaInformation div.extraInfo div.left span.year {
  margin-inline-end: 0;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList svg {
  width: 20px !important;
  height: 20px !important;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -47px;
  inset-inline-end: -18px;
  width: 10rem;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList span.tooltip.remove {
  width: 12rem;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  inset-inline-end: 31px;
  margin-inline-start: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.similiarShowsCard div.metaInformation p.description {
  font-size: 0.78em;
  color: var(--text-color);
  font-weight: 300;
  line-height: 1.7em;
  letter-spacing: 0.3px;
  word-wrap: break-word;
}

div.podcastModal .rhap_container {
  background-color: #303030 !important;
  border-radius: 5px;
}
div.podcastModal .rhap_time {
  color: #a29b9b !important;
}
div.podcastModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.podcastModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 9999999;
  cursor: pointer;
}
div.podcastModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.podcastModal div.contents {
  z-index: 99999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1400px) {
  div.podcastModal div.contents {
    width: 30%;
  }
}
@media all and (max-width: 1200px) {
  div.podcastModal div.contents {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.podcastModal div.contents {
    width: 75%;
  }
}
@media all and (max-width: 480px) {
  div.podcastModal div.contents {
    width: 90%;
  }
}
div.podcastModal div.contents div.podcastContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
div.podcastModal div.contents div.podcastContainer div.left {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.left {
    width: 100%;
    margin-inline-end: 0;
  }
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer {
  width: 100%;
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.podcastModal div.contents div.podcastContainer div.right {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.right {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right h1.title {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.podcastModal div.contents div.podcastContainer div.right div.audioContainer {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right div.audioContainer audio {
  width: 100%;
  display: block;
}

div.tvAppIndex {
  padding: 60px 0;
}
div.tvAppIndex div.tvActivationReverse div.topHeader {
  padding: 20px 10px;
  border-bottom: 1px solid var(--border-color);
}
div.tvAppIndex div.tvActivationReverse div.topHeader h1 {
  font-size: 22px;
  color: var(--heading-color);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer {
  padding: 50px 0 20px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer h2 {
  color: var(--text-color);
  font-size: 18px;
  margin-bottom: 20px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer p {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form label {
  color: var(--heading-color);
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
  display: block;
  outline: none;
  border: none;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 30%;
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
    width: 100%;
  }
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  margin-inline-end: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 13px;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button:hover {
  background-color: var(--secondary-hover-color);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms {
  font-size: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms span {
  color: rgb(63, 131, 242);
  cursor: pointer;
}
div.tvAppIndex div.linkTvApp {
  padding: 130px 0;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer {
  width: 35%;
  margin: 0 auto;
  background-color: #22293b;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
  border-radius: 10px;
  padding: 50px 60px;
}
@media all and (max-width: 1200px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 75%;
  }
}
@media all and (max-width: 480px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 90%;
    padding: 40px 40px;
  }
}
div.tvAppIndex div.linkTvApp div.authCodeContainer h1.heading {
  color: var(--text-color);
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 8px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer p {
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 30px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.loading {
  display: block;
  color: var(--success-color);
  margin-bottom: 20px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField {
  border: 1px solid var(--success-color);
  padding: 10px;
  font-size: 24px;
  color: var(--success-color);
  letter-spacing: 10px;
  font-weight: 700;
  margin-bottom: 15px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField.expired {
  border: 1px solid var(--error-color);
  color: var(--error-color);
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired span {
  color: #fff;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton {
  margin-top: 10px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton button {
  background-image: linear-gradient(0deg, #2175d9, #2789ff);
  width: 12rem;
  border-radius: 4px;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.29px;
  font-weight: 500;
  cursor: pointer;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.timer {
  color: #fff;
  font-size: 16px;
}

div.detailsScreenModal {
  padding-bottom: 60px;
  position: relative;
}
@media all and (max-width: 980px) {
  div.detailsScreenModal {
    position: unset;
  }
}
div.detailsScreenModal div.imgVideoContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 450px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer {
    height: 326px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer {
    height: 252px;
  }
}
div.detailsScreenModal div.imgVideoContainer.noHeight {
  height: unset;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer.noHeight div.bottomVignette {
    bottom: -31px;
  }
}
div.detailsScreenModal div.imgVideoContainer video {
  z-index: 3;
}
div.detailsScreenModal div.imgVideoContainer img {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
div.detailsScreenModal div.imgVideoContainer img.show {
  display: block;
}
div.detailsScreenModal div.imgVideoContainer img.hide {
  display: none;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton {
  position: absolute;
  inset-inline-end: 10px;
  top: 10px;
  z-index: 9999;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton button {
  background-color: var(--bg-color);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 0;
  cursor: pointer;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton button svg {
  stroke: var(--white-color) !important;
}
div.detailsScreenModal div.imgVideoContainer div.bottomVignette {
  background-color: transparent;
  background-image: linear-gradient(180deg, hsla(0, 0%, 8%, 0) 0, hsla(0, 0%, 30%, 0.15) 15%, hsla(0, 0%, 8%, 0.35) 29%, hsla(0, 0%, 8%, 0.58) 40%, #141414 84%, #141414);
  background-position: 0 top;
  background-repeat: repeat-x;
  background-size: 100% 100%;
  bottom: -8px;
  height: 200px;
  opacity: 1;
  top: auto;
  width: 100%;
  z-index: 99;
  position: absolute;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.bottomVignette {
    bottom: 31px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions {
  position: absolute;
  bottom: 40px;
  z-index: 999;
  width: 100%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions img.title {
  top: -110px;
  width: 30rem;
  inset-inline-start: 30px;
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions img.title {
    top: -65px;
    width: 15rem;
    inset-inline-start: 20px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions h1.title {
  padding: 0 40px;
  animation: fadeIn 0.4s ease-in-out;
  font-size: 60px;
  z-index: 99;
  font-family: "Gagalin-Regular", sans-serif !important;
  color: var(--white-color);
  max-width: 80%;
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions h1.title {
    font-size: 40px;
    padding: 0 18px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions h1.title {
    font-size: 21px;
    margin-bottom: 6px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons {
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons {
    padding: 0 18px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left {
  display: flex;
  align-items: center;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play {
  display: flex;
  padding: 6px 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  width: 7.5rem;
  background-color: var(--primary-btn-color);
  margin-inline-end: 10px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play {
    width: 5.5rem;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon {
  margin-inline-end: 10px;
  line-height: 0;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon svg {
  width: 25px !important;
  height: 25px !important;
  color: var(--black-color);
  display: block;
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon svg {
    width: 16px !important;
    height: 16px !important;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon img {
  width: 100%;
  display: block;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play span {
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play span {
    font-size: 12px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  margin-inline-end: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe:hover {
  background-color: var(--secondary-hover-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe span {
  font-size: 14px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -50px;
  width: 10rem;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip.remove {
  width: 12rem;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  inset-inline-start: 50%;
  margin-inline-start: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share {
  position: relative;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
  margin-inline-start: 10px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
  position: absolute;
  top: -126px;
  inset-inline-start: -74px;
  z-index: 999;
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 2500px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    inset-inline-start: -89px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 200px;
    inset-inline-start: -74px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 232px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 11rem;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer.active {
  display: block;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
  content: "";
  bottom: -8px;
  width: 0;
  height: 0;
  border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
    left: 50%;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
    border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button {
  background-color: rgba(42, 42, 42, 0.6);
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.12em solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
  opacity: 0.3;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button:hover {
  opacity: 1;
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button:hover svg {
  color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button svg {
  transition: 0.2s ease-in-out;
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
}
div.detailsScreenModal div.info {
  padding: 0 40px;
  margin-top: 5px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info {
    padding: 0 18px;
  }
}
div.detailsScreenModal div.info div.metaData {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData {
    flex-direction: column;
  }
}
div.detailsScreenModal div.info div.metaData div.left {
  width: 60%;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.detailsScreenModal div.info div.metaData div.left div.top {
  display: flex;
  align-items: center;
}
div.detailsScreenModal div.info div.metaData div.left div.top span {
  font-size: 14px;
  margin-inline-end: 10px;
}
div.detailsScreenModal div.info div.metaData div.left div.top span.duration {
  color: var(--duration-color);
}
div.detailsScreenModal div.info div.metaData div.left div.top span.ended {
  color: var(--duration-color);
  font-size: 18px;
}
div.detailsScreenModal div.info div.metaData div.left div.top span.ended span {
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
}
div.detailsScreenModal div.info div.metaData div.left div.top span.rating {
  padding: 1px 6px;
  border: 1px solid var(--border-color);
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio {
  font-size: 14px;
  position: relative;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -41px;
  width: 145px;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9;
  inset-inline-start: -40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  inset-inline-start: 50%;
  margin-inline-start: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom {
  margin-top: 30px;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom p.description {
  font-size: 14px;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button {
  margin-inline-end: 6px;
  margin-bottom: 6px;
  padding: 6px 8px;
  background-color: var(--secondary-color);
  display: block;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button:hover {
  background-color: var(--secondary-hover-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free {
  background-color: var(--freeBtn-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free:hover {
  background-color: var(--freeBtn-hover);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free span {
  color: var(--white-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button span {
  color: var(--white-color);
  font-size: 12px;
  display: block;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button:last-child {
  margin-inline-end: 0;
}
div.detailsScreenModal div.info div.metaData div.right {
  width: 35%;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData div.right {
    width: 100%;
  }
}
div.detailsScreenModal div.info div.metaData div.right span.heading {
  font-size: 12px;
  color: var(--grey-color);
  margin-inline-end: 3px;
}
div.detailsScreenModal div.info div.metaData div.right div.cast,
div.detailsScreenModal div.info div.metaData div.right div.crew,
div.detailsScreenModal div.info div.metaData div.right div.categories,
div.detailsScreenModal div.info div.metaData div.right div.director {
  margin-bottom: 10px;
}
div.detailsScreenModal div.info div.metaData div.right div.cast span,
div.detailsScreenModal div.info div.metaData div.right div.crew span,
div.detailsScreenModal div.info div.metaData div.right div.categories span,
div.detailsScreenModal div.info div.metaData div.right div.director span {
  font-size: 13px;
  margin-inline-end: 4px;
}
div.detailsScreenModal div.info div.metaData div.right :last-child {
  margin-bottom: 0;
}
div.detailsScreenModal div.info div.showTrailerContainer {
  margin-top: 20px;
}
div.detailsScreenModal div.info div.showTrailerContainer h1.heading {
  color: var(--heading-color);
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 15px;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer {
  width: 200px;
  position: relative;
  cursor: pointer;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer:hover div.playIconContainer {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 3px;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer div.playIconContainer {
  align-items: center;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  background: rgba(30, 30, 20, 0.5);
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 9;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer div.playIconContainer svg {
  width: 25px !important;
  height: 25px !important;
}
div.detailsScreenModal div.info div.similiarShowsContainer {
  margin-top: 40px;
}
div.detailsScreenModal div.info div.similiarShowsContainer h1.heading {
  color: var(--heading-color);
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 20px;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
  width: 31.7%;
  margin-inline-end: 17px;
  margin-bottom: 20px;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:last-child {
  margin-inline-end: 0;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 48.7%;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-inline-end: 17px;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 48.4%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    margin-inline-end: 10px;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 100%;
    margin-inline-end: 0;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-inline-end: 0;
  }
}
div.detailsScreenModal div.eventEndedOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
div.detailsScreenModal div.eventEndedOverlay h2 {
  font-size: 30px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.eventEndedOverlay h2 {
    font-size: 22px;
  }
}
div.detailsScreenModal div.eventEndedOverlay p {
  font-size: 20px;
  color: var(--secondary-color);
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.eventEndedOverlay p {
    text-decoration: underline;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.eventEndedOverlay p {
    font-size: 16px;
  }
}
div.detailsScreenModal div.eventEndedOverlay p:hover {
  text-decoration: underline;
}

div.openAppContainer {
  padding: 15px;
  position: fixed;
  bottom: 10px;
  width: 100%;
  background-color: var(--button-color);
  z-index: 100000000000000000;
  border-radius: 10px;
  width: 98%;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
}
div.openAppContainer h1 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 12px;
}
div.openAppContainer p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 10px;
}
div.openAppContainer div.buttons {
  display: flex;
  justify-content: space-between;
}
div.openAppContainer div.buttons button.notNow {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}
div.openAppContainer div.buttons button.openApp {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}

div.languageModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(32, 32, 38, 0.8);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.languageModal div.overlayModal.root {
  background: var(--bg-color);
}
div.languageModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
  cursor: pointer;
}
div.languageModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.languageModal div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  width: 30%;
  margin: 0 auto;
  padding: 25px 25px 25px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.languageModal div.contents {
    padding: 30px;
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.languageModal div.contents {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.languageModal div.contents {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.languageModal div.contents {
    width: 90%;
  }
}
@media all and (max-width: 480px) {
  div.languageModal div.contents {
    width: 90%;
  }
}
div.languageModal div.contents h1 {
  color: var(--button-color);
  font-size: 22px;
  margin-bottom: 10px;
}
div.languageModal div.contents div.inputContainer {
  width: 100%;
  margin-bottom: 20px;
}
div.languageModal div.contents div.inputContainer select {
  width: 100%;
  padding: 12px 10px;
  outline: none;
  border: 1px solid rgb(231, 238, 239);
  color: var(--button-color);
}
div.languageModal div.contents div.inputContainer select option {
  padding: 12px;
}
div.languageModal div.contents div.buttonContainer button {
  width: 100%;
  display: block;
  padding: 10px;
  background-color: var(--button-color);
  color: var(--white-color);
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.languageModal div.contents div.buttonContainer button:hover {
  background-color: transparent;
  color: var(--button-color);
}

div.affliatePage {
  padding: 30px 0 0;
  background-color: #fff;
}
div.affliatePage div.top {
  padding: 30px 0;
}
div.affliatePage div.top div.logoSection {
  width: 70%;
  margin: 0 auto;
}
@media all and (min-width: 980px) {
  div.affliatePage div.top div.logoSection {
    width: 30%;
  }
}
div.affliatePage div.top div.logoSection h1.image {
  width: 100%;
}
div.affliatePage div.top div.logoSection h1.image img {
  width: 100%;
  display: block;
}
div.affliatePage div.top h1 {
  font-size: 24px;
  text-align: center;
}
div.affliatePage div.bottom {
  background-color: #022230;
  padding: 136px 20px;
  border-top-left-radius: 91%;
  border-top-right-radius: 0;
}
div.affliatePage div.bottom div.buttons {
  margin-inline-start: 40px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer {
  text-align: center;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button {
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 30px;
  width: 10rem;
  color: #fff;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.useApp {
  background-color: #45c4af;
  margin-bottom: 20px;
  box-shadow: rgba(69, 196, 175, 0.35) 0px 5px 15px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.web {
  box-shadow: rgba(0, 0, 0, 0.03) 0px 16px 24px;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

div.emailForm div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 30px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.emailForm div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.emailForm div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.emailForm div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.emailForm div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.emailForm div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root {
    width: 100% !important;
    padding: 10px 0;
    margin: 20px !important;
  }
}
div.emailForm div.MuiPaper-root h1 {
  width: 110px;
  margin: 0 auto 10px;
}
div.emailForm div.MuiPaper-root h1 img {
  display: block;
  width: 100%;
}
div.emailForm div.MuiPaper-root h2 {
  text-align: center;
  color: var(--white-color);
}
div.emailForm div.MuiPaper-root form label {
  display: block;
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 6px;
}
div.emailForm div.MuiPaper-root form input {
  display: block;
  padding: 12px 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
div.emailForm div.MuiPaper-root form div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root form div.buttonContainer {
    padding: 10px 1px;
  }
}
div.emailForm div.MuiPaper-root form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.emailForm div.MuiPaper-root form div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.emailForm div.MuiPaper-root form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.verifyEmailModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.top {
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.top svg {
  width: 70px !important;
  height: 70px !important;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.top svg {
    width: 50px !important;
    height: 50px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom {
  color: var(--text-color);
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1 {
  font-size: 22px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1 {
    font-size: 18px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p {
  font-size: 15px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p {
    font-size: 14px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p span {
  color: rgb(100, 180, 241);
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p span {
    font-size: 14px;
  }
}

div.loginRequestModal {
  text-align: center;
}
div.loginRequestModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 30px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root {
  background-color: rgba(255, 255, 255, 0.11) !important;
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.24), transparent) !important;
}
div.loginRequestModal h4 {
  font-size: 17px;
  color: var(--white-color);
  margin-bottom: 8px;
}
div.loginRequestModal div.requestDetails {
  background-color: var(--secondary-bg-color);
  padding: 15px 70px 15px 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.requestDetails {
    padding: 15px 13px 15px 10px;
  }
}
div.loginRequestModal div.requestDetails div.oneRow {
  display: flex;
  justify-content: space-between;
}
div.loginRequestModal div.requestDetails div.oneRow span {
  color: var(--text-color);
  margin-bottom: 8px;
  font-size: 15px;
}
div.loginRequestModal div.requestDetails div.oneRow span.label {
  font-style: italic;
}
div.loginRequestModal p {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 14px;
}
div.loginRequestModal div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.buttonContainer {
    padding: 10px 1px;
  }
}
div.loginRequestModal div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginRequestModal div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginRequestModal div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.appLoginModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.appLoginModal div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.appLoginModal div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.appLoginModal div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.appLoginModal div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.appLoginModal div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root {
    width: 95% !important;
    padding: 0;
    margin: 20px !important;
  }
}
div.appLoginModal div.MuiPaper-root p {
  color: var(--text-color);
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root p {
    font-size: 14px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root div.buttonContainer {
    padding: 10px 1px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.appLoginModal div.MuiPaper-root div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.appLoginModal div.MuiPaper-root div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}
div.appLoginModal div.MuiPaper-root span {
  color: var(--white-color);
  text-align: center;
  font-size: 14px;
  display: block;
}

div.videoPlayerContainer {
  position: relative;
  padding: 100px 0 0;
}
div.videoPlayerContainer div.arrowContainer {
  width: 5%;
  cursor: pointer;
}
div.videoPlayerContainer div.arrowContainer h3 {
  width: 100%;
  background: transparent;
  cursor: pointer;
}
div.videoPlayerContainer div.arrowContainer h3 svg {
  width: 30px;
  height: 100%;
  fill: white !important;
}
div.videoPlayerContainer div.arrowContainer:hover {
  cursor: pointer;
  transform: scale(1.2);
}
div.videoPlayerContainer div.playerContainer {
  width: 90%;
  margin: 0 auto;
  background-color: var(--black-color);
  position: relative;
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.playerContainer {
    width: 100%;
  }
}
div.videoPlayerContainer div.playerContainer.fullscreen {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  background-color: var(--black-color);
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen {
    transform: rotate(90deg) !important;
    transform-origin: center center !important;
    width: 100vh !important;
    height: 100vw !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
  }
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .vjs-tech.video-rotate {
    transform: rotate(90deg);
    width: 145% !important;
    left: -20%;
  }
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .vjs-control-bar {
    transform: rotate(90deg);
    left: -65%;
    top: 48%;
    width: 145%;
  }
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .ima-ad-container,
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .ima-controls-div {
    transform: rotate(90deg);
  }
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .vjs-text-track-cue {
    transform: rotate(90deg);
    margin-left: -43%;
    margin-top: -86%;
    font: 20px sans-serif !important;
  }
}
@media all and (max-width: 360px) {
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen {
    transform: rotate(90deg) !important;
    transform-origin: center center !important;
    width: 100vh !important;
    height: 100vw !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
  }
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .vjs-tech.video-rotate {
    transform: rotate(90deg);
    width: 160% !important;
    left: -27%;
  }
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .vjs-control-bar {
    transform: rotate(90deg);
    left: -68%;
    top: 48%;
    width: 160%;
  }
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .ima-ad-container,
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .ima-controls-div {
    transform: rotate(90deg);
  }
  div.videoPlayerContainer div.playerContainer.rotate-fullscreen .vjs-text-track-cue {
    transform: rotate(90deg);
    margin-left: -41%;
    margin-top: -100%;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer {
  position: relative;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  text-align: center;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
    width: 100%;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
  text-align: center;
  color: var(--white-color);
  font-weight: 400;
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
    font-size: 25px;
  }
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
    font-size: 14px;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
  width: 12rem;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button:hover {
  background-color: transparent;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
    width: 8rem;
    padding: 8px 20px;
    font-size: 13px;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
    width: 6rem;
    padding: 5px 20px;
    font-size: 12px;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer img {
  display: block;
  width: 100%;
}
div.videoPlayerContainer .video-js {
  width: 100%;
  height: auto;
}
div.videoPlayerContainer .video-js.video-rotate {
  transform: rotate(90deg) !important;
  transform-origin: center center;
  width: 100vh;
  height: 100vw;
}
div.videoPlayerContainer video {
  height: 100% !important;
  display: block !important;
  width: 100% !important;
}
div.videoPlayerContainer div.multiAudio {
  padding: 10px 0;
  width: 90%;
  margin: 0 auto;
}
div.videoPlayerContainer div.multiAudio div.selectContainer {
  width: 40%;
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.multiAudio div.selectContainer {
    width: 100%;
  }
}
div.videoPlayerContainer div.videoMetaData {
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
}
div.videoPlayerContainer div.videoMetaData div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.videoMetaData div.head {
    flex-direction: column;
    align-items: flex-start;
  }
}
div.videoPlayerContainer div.videoMetaData div.head h1.videoTitle {
  color: var(--text-color);
  font-size: 30px;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer {
  display: flex;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer {
    justify-content: flex-start;
    margin-top: 10px;
  }
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button:hover {
  background-color: transparent;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button.myList {
  margin-right: 20px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category {
  font-size: 16px;
  color: var(--inner-text-color);
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category::after {
  content: "•";
  margin: 0 5px 0 10px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category:last-child::after {
  display: none;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category:hover {
  text-decoration: underline;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating span.rating {
  margin-left: 10px;
  padding: 2px 6px;
  background-color: var(--grey-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
}
div.videoPlayerContainer div.videoMetaData p.description {
  color: var(--inner-text-color);
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem {
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem h6.subHeading {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 10px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items {
  width: 30%;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow span.item {
  margin-right: 10px;
  margin-bottom: 0;
  display: block;
  color: var(--inner-text-color);
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow span.item:last-child span.comma {
  display: none;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items span.itemInfo {
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items div.item {
  display: flex;
  justify-content: space-between;
  color: var(--inner-text-color);
  margin-bottom: 6px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items div.item:last-child {
  margin-bottom: 0;
}
div.videoPlayerContainer div.upNextContainer {
  width: 90%;
  margin: 0 auto;
}
div.videoPlayerContainer div.upNextContainer h1.heading {
  text-align: left;
  color: var(--heading-color);
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer h1.heading {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer h1.heading {
    font-size: 24px;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext {
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 20px;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer div.upNext {
    width: 100%;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext div.item {
  width: 32.5%;
  margin-right: 10px;
}
div.videoPlayerContainer div.upNextContainer div.upNext div.item:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 32%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 48.5%;
    margin-bottom: 20px;
  }
  div.videoPlayerContainer div.upNextContainer div.upNext div.item:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 100%;
    margin-right: 0;
  }
  div.videoPlayerContainer div.upNextContainer div.upNext div.item:nth-child(2n) {
    margin-right: 0;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
  width: 15%;
  margin-right: 10px;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 20%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 23%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 46%;
  }
}
@media all and (max-width: 360px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 45%;
  }
}
div.videoPlayerContainer span.line {
  height: 1px;
  background-color: var(--border-color);
  width: 100%;
  display: block;
  margin-top: 70px;
}
div.videoPlayerContainer div.files {
  width: 90%;
  margin: 0 auto;
}
div.videoPlayerContainer div.commentsContainer {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 100%;
  }
}
div.videoPlayerContainer div.commentsContainer div.top div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
div.videoPlayerContainer div.commentsContainer div.top div.head span.commentsCount {
  color: var(--white-color);
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.top div.head span.commentsCount {
    font-size: 16px;
  }
}
div.videoPlayerContainer div.commentsContainer div.top div.head div.sortContainer select {
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  color: var(--white-color);
  font-size: 16px;
  border-radius: 6px;
}
div.videoPlayerContainer div.commentsContainer div.top div.head div.sortContainer select option {
  color: var(--black-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer {
  display: flex;
  padding: 40px 0 20px;
  border-bottom: 1px solid var(--border-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer {
  margin-right: 20px;
  width: 45px;
  height: 45px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer span.nameAvatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer {
  width: 90%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions {
  display: flex;
  justify-content: space-between;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions div.buttonContainer button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions div.buttonContainer button.submit:hover {
  background-color: transparent;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--white-color);
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}

div.autoplayComponent {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #000;
  height: 100%;
  z-index: 9;
}
div.autoplayComponent div.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
div.autoplayComponent div.content div.progressBar {
  width: 100px;
  height: 100px;
}
div.autoplayComponent div.content span.timer {
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
  color: var(--white-color);
}

div.replyField {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
}
div.replyField.child {
  width: 92%;
  margin: 0 0 0 auto;
}
div.replyField div.left {
  margin-right: 20px;
}
div.replyField div.left div.imageContainer {
  width: 45px;
  height: 45px;
}
div.replyField div.left div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.replyField div.left div.imageContainer span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.replyField div.right {
  width: 100%;
}
div.replyField div.right div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
div.replyField div.right div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.replyField div.right div.inputContainer div.actions {
  display: flex;
  justify-content: flex-end;
}
div.replyField div.right div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.replyField div.right div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.replyField div.right div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button:hover {
  background-color: transparent;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button.cancel {
  background-color: var(--grey-color);
  border: 1px solid var(--grey-color);
  margin-right: 20px;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}

.videoBanner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 95vh;
}
.videoBanner #background-video {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.videoBanner .bannerImage {
  height: 95vh;
}
@media all and (max-width: 480px) {
  .videoBanner .bannerImage {
    height: 50vh;
  }
}
.videoBanner button.bannerButton {
  position: absolute;
  padding: 15px 20px;
  inset-inline-end: 30px;
  bottom: 50px;
  border: none;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 7px;
  background: var(--white-color);
}
.videoBanner button.bannerButton:hover {
  background-color: var(--button-color);
  color: var(--white-color);
}
@media all and (max-width: 480px) {
  .videoBanner button.bannerButton {
    inset-inline-end: 20px;
    bottom: 60px;
    font-size: 0.8rem;
  }
}
@media all and (max-width: 480px) {
  .videoBanner {
    height: 50vh;
  }
}

div.deviceList {
  display: flex;
  background: var(--white-color);
  padding-top: 30px;
  justify-content: space-between;
  padding-bottom: 100px;
}
@media all and (max-width: 640px) {
  div.deviceList {
    flex-direction: column;
  }
}
div.deviceList div.left {
  width: 40%;
  padding-inline-start: 60px;
}
@media all and (max-width: 768px) {
  div.deviceList div.left {
    padding-inline-start: 30px;
  }
}
@media all and (max-width: 640px) {
  div.deviceList div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.deviceList div.left h1.heading {
  color: var(--black-color);
}
div.deviceList div.left p.description {
  color: var(--text-color);
}
div.deviceList div.right {
  width: 40%;
}
@media all and (max-width: 1980px) {
  div.deviceList div.right {
    width: 43%;
  }
}
@media all and (max-width: 1600px) {
  div.deviceList div.right {
    width: 46%;
  }
}
@media all and (max-width: 1400px) {
  div.deviceList div.right {
    width: 55%;
  }
}
@media all and (max-width: 1200px) {
  div.deviceList div.right {
    width: 46%;
  }
}
@media all and (max-width: 980px) {
  div.deviceList div.right {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.deviceList div.right {
    width: 56%;
  }
}
@media all and (max-width: 640px) {
  div.deviceList div.right {
    width: 91%;
    margin: 0 auto;
  }
}
div.deviceList div.right ul.devices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
div.deviceList div.right ul.devices li {
  cursor: pointer;
  width: 17rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 6px;
  margin-bottom: 6px;
  background: rgb(54, 124, 160);
  background: linear-gradient(90deg, rgb(54, 124, 160) 0%, rgb(14, 64, 115) 35%, rgb(0, 41, 97) 100%);
  transition: 0.2s ease-in-out;
}
div.deviceList div.right ul.devices li:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 2200px) {
  div.deviceList div.right ul.devices li {
    width: 15rem;
  }
}
@media all and (max-width: 1980px) {
  div.deviceList div.right ul.devices li {
    width: 13rem;
    height: 9rem;
  }
}
@media all and (max-width: 1200px) {
  div.deviceList div.right ul.devices li:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.deviceList div.right ul.devices li:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.deviceList div.right ul.devices li {
    width: 12rem;
    height: 7rem;
  }
}
@media all and (max-width: 768px) {
  div.deviceList div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 640px) {
  div.deviceList div.right ul.devices li {
    width: 13rem;
  }
}
@media all and (max-width: 480px) {
  div.deviceList div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 360px) {
  div.deviceList div.right ul.devices li {
    width: 100%;
    margin-inline-end: 0;
  }
  div.deviceList div.right ul.devices li:nth-child(3n) {
    margin-inline-end: 0;
  }
}
div.deviceList div.right ul.devices li:hover {
  box-shadow: 0px 1px 6px 4px #333;
}
div.deviceList div.right ul.devices li span {
  color: var(--white-color);
  font-weight: 700;
  font-size: 30px;
}
@media all and (max-width: 980px) {
  div.deviceList div.right ul.devices li span {
    font-size: 24px;
  }
}

div.scheduleDetailsModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(32, 32, 38, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.scheduleDetailsModal div.contents {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: var(--bg-color);
  width: 50%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
}
div.scheduleDetailsModal div.contents div.top {
  position: relative;
  width: 100%;
  height: 269px;
  background-size: cover;
  background-position: top;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-repeat: no-repeat;
}
div.scheduleDetailsModal div.contents div.top div.closeIcon {
  position: absolute;
  top: 10px;
  inset-inline-end: 10px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}
div.scheduleDetailsModal div.contents div.top div.closeIcon svg {
  color: var(--white-color);
  width: 20px !important;
  height: 20px !important;
}
div.scheduleDetailsModal div.contents div.bottom {
  background-color: var(--secondary-bg-color);
  padding: 40px 30px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 300px;
  overflow-y: scroll;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar {
  display: block;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo {
  width: 80px;
  height: 80px;
  background-color: rgba(150, 153, 163, 0.16);
  border-radius: 6px;
  margin-bottom: 10px;
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo div.logoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo div.logoContainer img {
  display: block;
  width: 40px;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData {
  color: var(--text-color);
}
div.scheduleDetailsModal div.contents div.bottom div.metaData h1.title {
  font-size: 30px;
  margin-bottom: 10px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData span.startTime, div.scheduleDetailsModal div.contents div.bottom div.metaData span.endTime {
  color: var(--inner-text-color);
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
  display: inline-block;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData div.additionalInfo span.caption {
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 8px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-inline-end: 15px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 3px;
  color: var(--white-color);
  font-size: 12px;
  padding: 1px 8px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData p.description {
  margin-top: 10px;
  font-size: 15px;
}

div.liveChannelsContainer {
  background-color: var(--secondary-bg-color);
  position: relative;
}
div.liveChannelsContainer div.topContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 580px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer {
    min-height: 508px;
  }
}
div.liveChannelsContainer div.topContainer div.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.wrapper {
    flex-direction: column-reverse;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.topContainer div.wrapper {
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.bgImage {
  padding: 200px 0;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  max-height: 800px;
  position: absolute;
  z-index: 3;
  bottom: 0;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.bgImage {
    padding: 200px 0 150px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.bgImage {
    display: none;
  }
}
div.liveChannelsContainer div.topContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 4;
  height: 500px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 94.15%);
}
div.liveChannelsContainer div.topContainer div.bannerContainer {
  position: relative;
  margin-bottom: 60px;
  width: 55%;
  margin-inline-end: 30px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.bannerContainer {
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info {
  color: var(--text-color);
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel {
  background-color: #f52d2d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 3.5rem;
  border-radius: 3px;
  margin-bottom: 10px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel svg {
  width: 16px !important;
  height: 16px !important;
  margin-inline-end: 6px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info h1.title {
  font-size: 30px;
  margin-bottom: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo h6.timeLeft {
  color: var(--white-color);
  font-size: 15px;
  margin-inline-end: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.caption {
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 10px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 3px;
  outline: none;
  border: none;
  margin-inline-end: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 4px;
  color: var(--white-color);
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  padding: 1px 8px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description {
  max-width: 100%;
  font-size: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore, div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess {
  color: var(--button-color);
  margin-inline-start: 6px;
  cursor: pointer;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore:hover, div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess:hover {
  text-decoration: underline;
}
div.liveChannelsContainer div.topContainer div.videoContainer {
  inset-inline-end: 40px;
  z-index: 9;
  width: 70%;
  top: 90px;
  border-radius: 15px;
}
@media all and (max-width: 1600px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 70%;
  }
}
@media all and (max-width: 1400px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    margin-bottom: 20px;
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
  padding-top: 0 !important;
  height: 408px;
  border-radius: 15px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 242px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 300px;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    position: relative;
    padding-top: 0 !important;
    width: 100%;
    height: 100%;
    border-radius: 0;
    -o-object-fit: cover;
       object-fit: cover;
    margin: 0;
    padding: 0;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer video {
  border-radius: 15px;
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.topContainer div.videoContainer video {
    border-radius: 0px;
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer {
  width: 100%;
  background: var(--bg-color);
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 92%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 15.22%, rgba(16, 20, 31, 0.4) 100%);
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer div.banner-image img {
  width: 100%;
}
div.liveChannelsContainer div.banner-text {
  height: 75px;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 40px 5px 0px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.banner-text {
    margin: 20px 5px 0px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.banner-text {
    margin: 30px 5px 5px;
  }
}
div.liveChannelsContainer div.banner-text h2 {
  font-size: 20px;
  color: var(--text-color);
  z-index: 9;
  font-weight: 400;
}
@media all and (max-width: 640px) {
  div.liveChannelsContainer div.banner-text h2 {
    font-size: 16px;
    margin-inline-start: 15px;
  }
}
@media all and (max-width: 360px) {
  div.liveChannelsContainer div.banner-text h2 {
    font-size: 13px;
  }
}
div.liveChannelsContainer div.banner-text button {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin-inline-start: 2%;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
  background-color: var(--button-color);
  color: var(--text-color);
  height: 3rem;
  z-index: 9;
}
@media all and (max-width: 640px) {
  div.liveChannelsContainer div.banner-text button {
    margin-inline-end: 3%;
  }
}
@media all and (max-width: 360px) {
  div.liveChannelsContainer div.banner-text button {
    margin-inline-end: 3%;
    width: 6rem;
    font-size: 16px;
  }
}
div.liveChannelsContainer div.banner-text button:hover {
  transform: scale(1.0476190476, 1.0357142857);
  background-color: var(--button-hover);
}
div.liveChannelsContainer div.banner-text button.fullscreen {
  width: 100vw;
  height: 100vh;
  top: 0;
  inset-inline-start: 0;
  position: fixed;
  z-index: 9999;
  background-color: var(--black-color);
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen {
    transform: rotate(90deg) !important;
    transform-origin: center center !important;
    width: 100vh !important;
    height: 100vw !important;
    position: fixed !important;
    top: 0 !important;
    inset-inline-start: 0 !important;
  }
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .vjs-tech.video-rotate {
    transform: rotate(90deg);
    width: 145%;
    left: -20%;
  }
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .vjs-control-bar {
    transform: rotate(90deg);
    left: -64%;
    top: 48%;
    width: 145%;
  }
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .ima-ad-container,
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .ima-controls-div {
    transform: rotate(90deg);
  }
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .vjs-text-track-cue {
    transform: rotate(90deg);
    margin-left: -23%;
    margin-top: -63%;
    font: 25px sans-serif !important;
  }
}
@media all and (max-width: 360px) {
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen {
    transform: rotate(90deg) !important;
    transform-origin: center center !important;
    width: 100vh !important;
    height: 100vw !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
  }
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .vjs-tech.video-rotate {
    transform: rotate(90deg);
  }
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .vjs-control-bar {
    transform: rotate(90deg);
    left: -65%;
    top: 48%;
  }
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .ima-ad-container,
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .ima-controls-div {
    transform: rotate(90deg);
  }
  div.liveChannelsContainer div.banner-text button.rotate-fullscreen .vjs-text-track-cue {
    transform: rotate(90deg);
    margin-left: -32%;
    margin-top: -80%;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.banner-text .videoContainer video.video-fullscreen {
    -o-object-fit: contain;
       object-fit: contain;
    transform: rotate(90deg) !important;
    margin: 0;
    padding: 0;
    width: 180%;
    height: 100%;
    margin-inline-start: -40%;
  }
}
@media all and (max-width: 360px) {
  div.liveChannelsContainer div.banner-text .videoContainer video.video-fullscreen {
    transform: rotate(90deg) !important;
  }
}
div.liveChannelsContainer div.wrapper {
  overflow: hidden;
}
div.liveChannelsContainer div.wrapper div.categories {
  margin-bottom: 30px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.liveChannelsContainer div.wrapper div.categories::-webkit-scrollbar {
  display: none;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons {
  width: 1200px;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button {
  background-color: var(--black-color);
  padding: 5px 10px;
  color: var(--white-color);
  margin-inline-end: 10px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 30px;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button.active {
  background-color: var(--white-color);
  color: var(--black-color);
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button:last-child {
  margin-inline-end: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleTime {
  width: 90%;
  margin: 0 0 6px auto;
  display: flex;
  align-items: center;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time {
  border-inline-start: 2px solid var(--border-color);
  padding-inline-start: 10px;
  width: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time span {
  color: var(--inner-text-color);
  font-size: 10px;
  font-weight: 700;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer {
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.rightGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-end: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(95deg, rgba(0, 0, 0, 0), #000 101.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  inset-inline-end: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 62.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel {
  display: flex;
  align-items: center;
  width: 3000px;
  height: 75px;
  margin-bottom: 4px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime {
  position: absolute;
  top: -26px;
  inset-inline-end: 0;
  background-color: #f52d2d;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 5rem;
  border-radius: 3px;
  color: var(--white-color);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon {
  line-height: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon svg {
  width: 16px !important;
  height: 16px !important;
  margin-inline-end: 6px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left {
  width: 5%;
  margin-inline-end: 4px;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 4px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right {
  display: flex;
  align-items: center;
  width: 95%;
  height: 100%;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem {
  background-color: rgb(25, 25, 25);
  padding: 25px 20px;
  border-radius: 4px;
  margin-inline-end: 4px;
  height: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active {
  border: 2px solid var(--text-hover);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress {
  display: block;
  position: absolute;
  background: linear-gradient(95deg, rgba(25, 25, 25, 0), #363434 101.15%);
  width: 10%;
  top: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress div.currentTime {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem:hover div.metaData {
  display: flex;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.progress {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem h1 {
  font-size: 16px;
  color: var(--white-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData {
  display: none;
  justify-content: space-between;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData p.description {
  position: absolute;
  z-index: 99;
  color: var(--inner-text-color);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon {
  text-align: end;
  position: absolute;
  z-index: 9999;
  inset-inline-end: 10px;
  top: 10px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon svg {
  color: var(--inner-text-color);
  width: 20px !important;
  height: 20px !important;
}
div.liveChannelsContainer div.categoryAll {
  padding: 1px 0px 20px 0px;
  background-color: var(--black-color);
}
div.liveChannelsContainer div.categoryAll div.categoryContainer {
  border: 1px solid var(--border-color);
  border-radius: 2px;
  width: 20rem;
  background: transparent;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: var(--text-color);
  cursor: pointer;
  margin-top: 2%;
  margin-inline-start: 20px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.categoryAll div.categoryContainer {
    margin-top: 5%;
    margin-inline-start: 2px;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.categoryAll div.categoryContainer {
    width: 98%;
    margin-top: 6%;
    margin-inline-start: 4px;
  }
}
@media all and (max-width: 360px) {
  div.liveChannelsContainer div.categoryAll div.categoryContainer {
    width: 97%;
    margin-top: 8%;
    margin-inline-start: 2px;
  }
}
div.liveChannelsContainer div.categoryAll div.categoryContainer svg {
  width: 26px !important;
  height: 26px !important;
  transition: 0.3s ease-in-out;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer svg.active {
  transform: rotate(-180deg);
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list {
  position: absolute;
  top: 55px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9;
  cursor: pointer;
  height: 300px;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list.active {
  display: block;
  z-index: 99999;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list div.item {
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid var(--border-color);
  background-color: #1c1f29;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list div.item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list div.item span {
  font-size: 16px;
  color: var(--grey-color);
}
div.liveChannelsContainer div.categoryAll div.categoryContainer div.list div.item span:hover {
  color: var(--white-color);
}

div.liveChannelsContainer.rtl {
  direction: ltr;
  text-align: left;
}
div.liveChannelsContainer.rtl .categoryAll {
  direction: rtl;
  text-align: right;
}
div.liveChannelsContainer.rtl .topContainer {
  direction: rtl;
  text-align: right;
}

div.LayoutsContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
div.LayoutsContainer div.routesContainer {
  flex: 1 0 auto;
}

div.subscriptionDetailsContainer {
  padding: 0 100px 10px 100px;
  color: whitesmoke;
}
div.subscriptionDetailsContainer div.planContainer {
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
  border-radius: 8px;
  margin: 20px auto;
  padding: 20px;
}
div.subscriptionDetailsContainer div.planContainer h3, div.subscriptionDetailsContainer div.planContainer h2 {
  text-align: center;
  padding: 15px;
}
div.subscriptionDetailsContainer div.planContainer h3 {
  font-weight: 200;
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer {
  width: 100%;
  overflow: auto;
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer table {
  border-collapse: collapse;
}
@media all and (min-width: 980px) {
  div.subscriptionDetailsContainer div.planContainer div.tableContainer table {
    width: 100%;
  }
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer tr:nth-child(2) td:nth-child(1) {
  min-width: 123px;
  text-align: center;
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer tr:nth-child(2n+1) {
  background-color: rgba(48, 50, 56, 0.3568627451);
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer tr:nth-child(2n) {
  background-color: rgba(32, 32, 33, 0.3568627451);
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer tr:nth-child(3) {
  background-color: rgba(233, 184, 95, 0.1882352941);
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer td {
  text-align: center;
  padding: 15px 20px;
  min-width: 123px;
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer td:nth-child(1) {
  min-width: 150px;
  text-align: start;
  border: none;
}
@media all and (max-width: 1024px) {
  div.subscriptionDetailsContainer {
    padding: 0 0 10px;
  }
}

div.ceyFlixSubscriptionPage {
  padding: 100px 0 0;
}
div.ceyFlixSubscriptionPage div.subscriptionFeatures {
  padding: 10px 100px 100px;
  color: var(--text-color);
}
div.ceyFlixSubscriptionPage div.subscriptionFeatures h3 {
  width: 100%;
  margin: 0 0 30px;
  text-align: center;
  color: var(--inner-text-color);
}
div.ceyFlixSubscriptionPage div.subscriptionFeatures div.subscriptionConditionList ul.conditionList li {
  color: var(--inner-text-color);
  margin: 13px 0;
  list-style: decimal;
}
@media all and (max-width: 1024px) {
  div.ceyFlixSubscriptionPage div.subscriptionFeatures {
    padding: 10px 0 80px;
    margin: 0 15px 0 15px;
  }
}

div.ceyFlixHelpCenter {
  padding: 100px 0 80px;
  color: var(--text-color);
}
div.ceyFlixHelpCenter p {
  color: var(--inner-text-color);
  margin-inline-start: 20px;
  margin-top: 20px;
}
div.ceyFlixHelpCenter h2 {
  text-align: center;
  padding-bottom: 60px;
}
div.ceyFlixHelpCenter div.helpPagePoints {
  background: rgba(55, 55, 55, 0.16);
  border-radius: 15px;
  padding: 30px 0;
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint {
  padding: 40px 100px;
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint h3 {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--inner-text-color);
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ul {
  padding: 20px;
  margin-inline-start: 70px;
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ul li {
  list-style: square;
  padding: 10px;
  color: var(--text-hover);
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ul li:last-child {
  padding-bottom: 0px;
}
@media all and (max-width: 1024px) {
  div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ul {
    margin-inline-start: 0px;
  }
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ol {
  padding: 20px;
  margin-inline-start: 70px;
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ol li {
  list-style: decimal;
  padding: 10px;
  color: var(--text-hover);
}
@media all and (max-width: 1024px) {
  div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ol {
    margin-inline-start: 0px;
  }
}
@media all and (max-width: 1024px) {
  div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint {
    padding: 40px 20px;
  }
}
div.ceyFlixHelpCenter .paddingContainer {
  padding: 20px 100px;
}
@media all and (max-width: 1024px) {
  div.ceyFlixHelpCenter .paddingContainer {
    padding: 10px 20px;
  }
}
div.ceyFlixHelpCenter p.note {
  color: var(--inner-text-color);
  margin-inline-start: 20px;
  margin-top: 0px;
}

div.magazineCardContainer {
  width: 100%;
  cursor: pointer;
  position: relative;
}
div.magazineCardContainer.noPointer {
  cursor: default;
}
div.magazineCardContainer div.showMoreOverlay {
  display: none;
}
div.magazineCardContainer.cardHover:hover .square {
  position: absolute;
  z-index: 99;
  transform: translateX(40px) translateY(-40px) scale(1.1);
}
div.magazineCardContainer.cardHover:hover .square div.metaData {
  display: block;
  visibility: visible !important;
  animation: fadeIn 0.3s ease-in-out;
}
div.magazineCardContainer.cardHover:not(:hover) .square {
  transform: none;
}
div.magazineCardContainer.cardHover:not(:hover) div.metaData {
  display: none;
  visibility: hidden;
}
div.magazineCardContainer .square {
  width: 100%;
  transition: transform 500ms ease-out, border-radius 200ms ease-out;
}
div.magazineCardContainer .square.hide div.metaData {
  display: none;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer .square.hide div.metaData {
    display: block;
  }
}
div.magazineCardContainer .square div.metaData {
  display: none;
}
div.magazineCardContainer .square div.metaData.hovered {
  display: block;
  visibility: visible !important;
  animation: fadeIn 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer .square div.metaData {
    display: block;
  }
}
div.magazineCardContainer .square div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.magazineCardContainer .square div.imageContainer img {
  width: 100%;
  display: block;
  transition: 0.2s ease-in-out;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
div.magazineCardContainer .square div.imageContainer div.extraActions {
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.magazineCardContainer .square div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.magazineCardContainer .square div.metaData {
  background: #181818;
  color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 10px;
  box-sizing: border-box;
  visibility: hidden;
  transition: 0.9s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer .square div.metaData {
    visibility: visible;
  }
}
div.magazineCardContainer .square div.metaData h1.title {
  font-size: 11px;
  color: var(--text-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.magazineCardContainer .square div.metaData h1.synopsis {
  font-size: 11px;
  color: var(--text-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.magazineCardContainer .square div.metaData div.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
div.magazineCardContainer .square div.metaData div.buttons span.material-symbols-outlined {
  font-weight: 300 !important;
  font-size: 20px !important;
}
div.magazineCardContainer .square div.metaData div.buttons svg {
  width: 100% !important;
  height: 15px !important;
}
div.magazineCardContainer .square div.metaData div.buttons button {
  color: var(--white-color);
  background-color: var(--button-color);
  width: 50%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  font-size: 10px;
}
div.magazineCardContainer .square div.metaData div.buttons button.play {
  background-color: var(--white-color);
  margin-inline-end: 4px;
  transition: 0.2s ease-in-out;
}
div.magazineCardContainer .square div.metaData div.buttons button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.magazineCardContainer .square div.metaData div.buttons button.play svg {
  color: var(--black-color);
}
div.magazineCardContainer .square div.metaData div.buttons button:hover {
  background-color: var(--secondary-hover-color);
}
div.magazineCardContainer .square div.metaData div.buttons div.left {
  display: flex;
  align-items: center;
}

div.homePageLiveContainer {
  margin-bottom: 40px;
  margin-top: 70px;
}
div.homePageLiveContainer div.videoBannerContainer {
  min-height: 610px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media all and (max-width: 768px) {
  div.homePageLiveContainer div.videoBannerContainer {
    min-height: 396px;
  }
}
@media all and (max-width: 640px) {
  div.homePageLiveContainer div.videoBannerContainer {
    min-height: 280px;
  }
}
@media all and (max-width: 480px) {
  div.homePageLiveContainer div.videoBannerContainer {
    min-height: 230px;
  }
}
div.homePageLiveContainer div.videoContainertop {
  height: 450px;
  margin-bottom: 5px;
}
@media all and (max-width: 768px) {
  div.homePageLiveContainer div.videoContainertop {
    height: 396px;
  }
}
@media all and (max-width: 700px) {
  div.homePageLiveContainer div.videoContainertop {
    height: 363px;
  }
}
@media all and (max-width: 640px) {
  div.homePageLiveContainer div.videoContainertop {
    height: 331px;
  }
}
@media all and (max-width: 572px) {
  div.homePageLiveContainer div.videoContainertop {
    height: 296px;
  }
}
@media all and (max-width: 480px) {
  div.homePageLiveContainer div.videoContainertop {
    height: 250px;
  }
}
@media all and (max-width: 360px) {
  div.homePageLiveContainer div.videoContainertop {
    height: 187px;
  }
}
div.homePageLiveContainer div.videoContainertop div.videoContainer {
  max-width: 800px;
  margin: 0 auto;
}
div.homePageLiveContainer div.liveCardContainer {
  overflow: hidden;
}
div.homePageLiveContainer div.liveCardContainer:hover div.categoryNameContainer div.exploreAll div.rightArrow {
  opacity: 1;
}
div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer {
  cursor: pointer;
  display: inline-block;
  align-items: center;
}
div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer h1 {
  font-size: 19px;
  color: var(--text-color);
  max-width: 99%;
  font-weight: 500;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer h1 {
    font-size: 16px;
  }
}
div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer:hover div.exploreAll div.seeAll {
  opacity: 1;
  max-width: 100%;
  transform: translateX(10px);
}
div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer:hover div.exploreAll div.rightArrow svg {
  width: 10px !important;
  height: 10px !important;
  transform: translateX(10px) rotate(-90deg);
}
div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer div.content {
  display: flex;
}
div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer div.exploreAll {
  color: #54b9c5;
  display: flex;
  align-items: center;
  margin-top: -6px;
}
div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer div.exploreAll div.seeAll {
  cursor: pointer;
  display: inline-block;
  font-size: 0.9vw;
  line-height: 0.8vw;
  margin-inline-end: 4px;
  max-width: 0;
  opacity: 0;
  font-weight: 500;
  transition: max-width 1s, opacity 1s, transform 0.75s;
  vertical-align: bottom;
  white-space: nowrap;
  transform: translateX(-10px);
}
@media all and (max-width: 980px) {
  div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer div.exploreAll div.seeAll {
    font-size: 1vw;
  }
}
div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer div.exploreAll div.rightArrow {
  color: #54b9c5;
  opacity: 0;
  transition: 0.3s ease-in-out;
  line-height: 0;
}
div.homePageLiveContainer div.liveCardContainer div.categoryNameContainer div.exploreAll div.rightArrow svg {
  transform: rotate(-90deg);
  transition: 0.2s ease-in-out;
  width: 20px !important;
  height: 20px !important;
}
div.homePageLiveContainer div.liveCardContainer h2.title {
  font-size: 19px;
  color: var(--text-color);
  max-width: 99%;
  font-weight: 500;
  margin-bottom: 10px;
}
div.homePageLiveContainer div.liveCardContainer div.imageCard {
  width: 129px;
  height: 72px;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  cursor: pointer;
}
div.homePageLiveContainer div.liveCardContainer div.imageCard img {
  width: 100%;
  height: 100%;
}
div.homePageLiveContainer .swiper-button-prev,
div.homePageLiveContainer .swiper-button-next {
  margin-top: -8px;
}

div.noscheduleContainer div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.noscheduleContainer div.closeIconContainer {
  position: fixed;
  top: 80px;
  inset-inline-end: 80px;
  z-index: 999;
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.noscheduleContainer div.closeIconContainer {
    inset-inline-end: 60px;
  }
}
div.noscheduleContainer div.closeIconContainer svg {
  width: 25px !important;
  height: 25px !important;
  stroke: var(--text-color) !important;
}
div.noscheduleContainer div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.noscheduleContainer div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.noscheduleContainer div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.noscheduleContainer div.contents {
    width: 90%;
  }
}
div.noscheduleContainer div.contents h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}

div.socialLoginModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.socialLoginModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 9999999;
}
div.socialLoginModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.socialLoginModal div.contents {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.socialLoginModal div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.socialLoginModal div.contents {
    width: 90%;
  }
}
div.socialLoginModal div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.socialLoginModal div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.socialLoginModal div.contents h1 {
  color: var(--white-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents h1 {
    font-size: 16px;
  }
}
div.socialLoginModal div.contents div.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
div.socialLoginModal div.contents div.buttonContainer button {
  background-color: var(--button-color);
  padding: 10px 12px;
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
  border-radius: 5px;
}
div.socialLoginModal div.contents div.buttonContainer button:first-child {
  margin-inline-end: 6px;
}

:root {
  --primary-color:#141414;
  --secondary-color:#e50914;
  --secondary-hover-color:rgba(229,9,20,0.8);
  --grey-color:#777;
  --text-hover:#b3b3b3;
  --bg-color:#141414;
  --secondary-bg-color:#313131;
  --overlay:rgba(16,20,31,.64);
  --white-color:#fff;
  --border-color:hsl(0deg 0% 100% / 15%);
  --account-settings:#1d1c1c;
  --text-color:#e5e5e5;
  --success-color:#4BB543;
  --error-color:#E72A31;
  --yellow-color:#f8d406;
  --black-color:#000;
  --button-color:#e50914;
  --primary-btn-color:#fff;
  --secondary-btn-color:rgba(109, 109, 110, 0.7);
  --primary-btn-hover:rgba(255, 255, 255, 0.75);
  --secondary-btn-hover:rgba(109, 109, 110, 0.4);
  --heading-color:#fff;
  --modal-bg-color:#ffffff;
  --sub-bg-color:#141414;
  --sub-heading-color:#e50914;
  --default-bg-color:#141414;
  --default-border-color:rgba(182, 183, 190, 0.16);
  --default-text-hover-color:#e50914;
  --transparent-color:rgba(0, 0, 0, 0.9);
  --duration-color:#46d369;
  --freeBtn-color:#2b9947;
  --freeBtn-hover:#46d369;
  --inner-text-color:#9699a3;
  --gradient-left-color:#01c599;
  --gradient-right-color:#0388cc;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}
body .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
body .MuiPaper-root {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  max-width: 779px !important;
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}
@media all and (max-width: 980px) {
  body .MuiPaper-root {
    max-width: 100% !important;
  }
}
body .css-1k2f74c-MuiPaper-root-MuiDialog-paper {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}
body.hideScroll .MuiDialog-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body.hideScroll .MuiDialog-container::-webkit-scrollbar {
  display: none;
}
body.showScrollbar .MuiDialog-container {
  -ms-overflow-style: unset !important; /* IE and Edge */
  scrollbar-width: unset !important; /* Firefox */
}
body.showScrollbar .MuiDialog-container::-webkit-scrollbar {
  display: block !important;
}

.wrapper {
  width: 92%;
  margin: 0 auto;
}

.swiper-button-next.swiper-button-disabled {
  display: none !important;
}

.swiper-button-prev.swiper-button-disabled {
  display: none !important;
}

.swiper-button-next {
  top: 22px !important;
}
.swiper-button-next::after {
  font-size: 25px !important;
  color: var(--white-color);
}

.swiper-button-prev {
  top: 22px !important;
}
.swiper-button-prev::after {
  font-size: 25px !important;
  color: var(--white-color);
  position: absolute;
}

span.error {
  font-size: 14px;
  color: #E72A31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#progressBarContainer {
  position: fixed;
  z-index: 999999999;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 0;
  right: 0;
}

#progressBar {
  background: linear-gradient(to left, var(--button-color), var(--button-color));
  transform-origin: top left;
  transform: scale(0, 0);
  opacity: 1;
  transition: 0.3s ease-in-out;
}

#progressBarContainer,
#progressBar {
  height: 100vh;
  width: 8px;
}

div.faqs {
  padding: 100px 0;
}
div.faqs h1 {
  text-align: center;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.faqs p {
  color: var(--text-color) !important;
}
div.faqs p.nb {
  margin-top: 20px;
}
div.faqs .accordion__button {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}
div.faqs .accordion__panel {
  background-color: #1d1c1c;
}/*# sourceMappingURL=app.css.map */