div.mainLoading{
    padding: 130px 0 350px 60px;
    @media all and (max-width:640px){
      padding: 130px 0 350px 30px;

    }
    div.topLoader{
        width: 100px;
        height: 20px;
        margin-bottom: 20px;
                animation: skeleton-loading1 0.5s linear infinite alternate;

    }
    div.lists{
      overflow-x: scroll;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
      ul.loaders{
          display: flex;
          // flex-wrap: wrap;
          width: 2900px;
          li{
              width: 210px;
              height: 110px;
              background-color: var(--transparent-color);
              margin-inline-end: 3px;
              margin-bottom: 20px;
              opacity:0;
            background-color: hsl(0, 0%, 16%);
              border-radius: 3px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              @media all and (max-width:2500px){
                width: 250px;
              }
              @media all and (max-width:1600px){
                width: 210px;
              }
              @media all and (max-width:980px){
                width: 170px;
                height: 100px;
              }
              @media all and (max-width:640px){
                width: 130px;
                height: 80px;
              }
              @media all and (max-width:480px){
                width: 110px;
                height: 60px;
              }
              &:nth-child(1){
                  opacity: 0.2;
                  animation: skeleton-loading1 0.5s linear alternate;
              }
              &:nth-child(1){
                  opacity: 0.3;
  
                  animation: skeleton-loading2 0.6s linear alternate;
              }
              &:nth-child(2){
                  opacity: 0.4;
  
                  animation: skeleton-loading3 0.7s linear alternate;
              }
              &:nth-child(3){
                  opacity: 0.5;
  
                  animation: skeleton-loading4 0.8s linear alternate;
              }
              &:nth-child(4){
                  opacity: 0.6;
  
                  animation: skeleton-loading5 0.9s linear alternate;
              }
              &:nth-child(5){
                  opacity: 0.7;
  
                  animation: skeleton-loading6 1s linear alternate;
              }
              &:nth-child(6){
                opacity: 0.8;
  
                animation: skeleton-loading6 1s linear alternate;
            }
            &:nth-child(7){
              opacity: 0.9;

              animation: skeleton-loading6 1s linear alternate;
          }
          &:nth-child(7){
            opacity: 1;

            animation: skeleton-loading6 1s linear alternate;
        }
        &:nth-child(8){
          opacity: 1.1;

          animation: skeleton-loading6 1s linear alternate;
      }
      &:nth-child(9){
        opacity: 1.2;

        animation: skeleton-loading6 1s linear alternate;
    }
              // &:last-child{
              //     opacity: 0.8;
  
              //     margin-right: 0;
              //     animation: skeleton-loading6 0.3s linear alternate;
  
              // }
          }
      }
    }
    
    @keyframes skeleton-loading1 {
        0% {
          background-color: hsl(210, 1%, 36%);
        }
        100% {
          background-color: hsl(0, 0%, 16%);
          opacity: 0.3;
        }
      }
      @keyframes skeleton-loading2 {
        0% {
          background-color: hsl(210, 1%, 36%);
        }
        100% {
          background-color: hsl(0, 0%, 16%);
          opacity: 0.4;
        }
      }
      @keyframes skeleton-loading3 {
        0% {
          background-color: hsl(210, 1%, 36%);
        }
        100% {
          background-color: hsl(0, 0%, 16%);
          opacity: 0.5;
        }
      }
      @keyframes skeleton-loading4 {
        0% {
          background-color: hsl(210, 1%, 36%);
        }
        100% {
          background-color: hsl(0, 0%, 16%);
          opacity: 0.6;
        }
      }
      @keyframes skeleton-loading5 {
        0% {
        //   background-color: hsl(210, 1%, 36%);
        }
        100% {
        //   background-color: hsl(0, 0%, 16%);
          opacity: 0.7;
        }
      }
      @keyframes skeleton-loading6 {
        0% {
        //   background-color: hsl(210, 1%, 36%);
        }
        100% {
        //   background-color: hsl(0, 0%, 16%);
          opacity: 0.8;
        }
      }
}

// @keyframes shimmer{
//   0%{
//     background-position: 100%;
//     opacity: 0;
//   }
//   100%{
//     background-position: 0;
//     opacity: 1;
//   }
// }
// background-size: 400%;
//             box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
//             background-image: linear-gradient(to right,hsl(0, 0%, 16%) 0%,hsl(0, 0%, 16%) 30%,#414040 45%,#414040 50%,hsl(0, 0%, 16%) 60%,hsl(0, 0%, 16%) 100%);