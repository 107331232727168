div.similiarShowsCard {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  cursor: pointer;
  &:hover {
    div.imageContainer {
      div.playIconContainer {
        visibility: visible;
        animation: fadeIn 0.2s ease-in-out forwards;
      }
    }
  }
  div.premiumStatus {
    div.premium {
      position: absolute;
      top: 6px;
      inset-inline-start: 6px;
      z-index: 8;
      background-color: rgba(8, 25, 43, 0.65);
      // padding: 6px 6px 3px;
      width: 35px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      @media all and (max-width: 1200px) {
        width: 26px;
      }
      svg {
        width: 18px !important;
        height: 18px !important;
        @media all and (max-width: 1200px) {
          width: 12px !important;
          heigth: 12px !important;
        }
      }
    }
    div.free {
      position: absolute;
      top: 6px;
      inset-inline-start: 6px;
      z-index: 8;
      width: 30px;
      @media all and (max-width: 1200px) {
        width: 30px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
  div.imageContainer {
    width: 100%;
    position: relative;
    &::before{
        background-image: linear-gradient(198deg,rgba(0,0,0,.9),hsla(0,0%,9%,.5) 20%,transparent 28%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    }
    img {
      width: 100%;
      display: block;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    span.duration {
      position: absolute;
      top: 10px;
      inset-inline-end: 10px;
      font-size: 12px;
    }
    div.playIconContainer {
      align-items: center;
      border: 1px solid var(--white-color);
      border-radius: 50%;
      background: rgba(30, 30, 20, 0.5);
      cursor: pointer;
      display: flex;
      height: 50px;
      width: 50px;
      justify-content: center;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      transition: 0.3s ease-in-out;
      z-index: 9;
      visibility: hidden;
      animation: fadeOut 0.2s ease-in-out forwards;
      svg {
        width: 31px !important;
        height: 31px !important;
      }
    }
  }
  div.metaInformation {
    padding: 15px 13px 30px;
    background-color: #2f2f2f;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-height: 217px;
    max-height: 217px;
    h1.showName {
      color: var(--duration-color);
      font-size: 14px;
      margin-bottom: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    div.extraInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
      div.left {
        span {
          font-size: 14px;
          margin-inline-end: 6px;
        }
        span.rating {
          padding: 1px 6px;
          border: 1px solid var(--border-color);
        }
        span.year {
          margin-inline-end: 0;
        }
      }
      div.right {
        button.addToList {
          background-color: rgba(42, 42, 42, 0.6);
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 1px solid hsla(0, 0%, 100%, 0.5);
          cursor: pointer;
          color: var(--white-color);
          transition: 0.2s ease-in-out;
          position: relative;
          svg {
            width: 20px !important;
            height: 20px !important;
          }
          &:hover {
            background-color: var(--secondary-btn-hover);
            border-color: var(--white-color);
            span.tooltip {
              display: flex;
              animation: fadeIn 0.3s ease-in-out;
            }
          }
          span.tooltip {
            display: none;
            justify-content: center;
            align-items: center;
            background-color: #d6d5d5;
            position: absolute;
            top: -47px;
            inset-inline-end: -18px;
            width: 10rem;
            padding: 6px 8px 6px;
            color: var(--bg-color);
            font-weight: 500;
            border-radius: 2px;
            font-size: 16px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            z-index: 99;
            &.remove{
                width: 12rem;
            }
            &::after {
              content: "";
              position: absolute;
              top: 99%;
              inset-inline-end: 31px;
              margin-inline-start: -5px;
              border-width: 6px;
              border-style: solid;
              border-color: #d6d5d5 transparent transparent transparent;
            }
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }
        }
      }
    }
    p.description {
      font-size: 0.78em;
      color: var(--text-color);
      font-weight: 300;
      line-height: 1.7em;
      letter-spacing: 0.3px;
      word-wrap: break-word;
    }
  }
}
