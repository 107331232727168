div.playerShowCard {
  height: 100%;

  div.imageContainer {
    width: 100%;
    position: relative;
    height: 100%;

    @media all and (max-width: 640px) {
      height: 400px;
    }

    img {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;

      @media all and (max-width: 640px) {
        height: 400px;
        object-fit: contain;
      }
    }

    div.playIconContainer {
      position: absolute;
      transform: translate(-50%, -50%);
      border: 1px solid var(--white-color);
      z-index: 999;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-radius: 50%;
      transition: 0.5s ease-in-out;
      cursor: pointer;

      svg {
        color: var(--white-color);
        width: 30px !important;
        height: 30px !important;
      }

      @media all and (max-width: 640px) {
        padding: 9px;

        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }

      &:hover {
        border: 1px solid var(--button-hover);
        background-color: var(--button-hover);
      }
    }

    div.metaData {
      position: absolute;
      z-index: 999;
      width: 100%;
      inset-inline-start: 50px;
      top: 30%;
      transition: 0.4s ease-in-out;
      visibility: hidden;

      @media all and (max-width: 2600px) {
        inset-inline-start: 77px;
      }

      @media all and (max-width: 640px) {
        width: 90%;
      }

      &.show {
        visibility: visible;
        // transition: 0.6s ease-in-out;

        animation: fadeIn 1.5s ease-in-out;
      }

      @media all and (max-width: 1200px) {
        inset-inline-start: 42px;
      }

      @media all and (max-width: 980px) {
        inset-inline-start: 22px;
      }

      &.gagalin {
        h1.showName {
          animation: fadeIn 0.5s ease-in-out;

          font-family: "Gagalin-Regular", sans-serif !important;
        }
      }

      &.gota {
        h1.showName {
          animation: fadeIn 0.5s ease-in-out;
          font-size: 40px;
          font-family: "gota-Regular", sans-serif !important;

          @media all and (max-width: 640px) {
            font-size: 25px;
          }
        }
      }

      img.showTitleImage {
        width: 30rem;
        transition: 0.9s ease-in-out;
        animation: fadeIn 0.5s ease-in-out;
        position: relative;
        inset-inline-start: 0;
        margin-bottom: 10px;
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media all and (max-width: 980px) {
          width: 30rem;
          height: auto;
          bottom: 20px;
        }

        @media all and (max-width: 640px) {
          width: 30rem;
          height: auto;
          bottom: 20px;
        }

        @media all and (max-width: 480px) {
          width: 30rem;
          height: auto;
          bottom: 20px;
        }

        @media all and (max-width: 980px) {
          width: 30rem;
          height: auto;
          bottom: 20px;
        }

        @media all and (max-width: 640px) {
          width: 30rem;
          height: auto;
          bottom: 20px;
        }
      }

      h1.showName {
        font-size: 50px;
        font-weight: 700;
        color: var(--white-color);
        text-transform: uppercase;
        // transform: scale(1.1);
        transition: 0.9s ease-in-out;
        animation: fadeIn 0.5s ease-in-out;
        position: relative;
        inset-inline-start: 0;
        margin-bottom: 10px;
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media all and (max-width: 980px) {
          // transform: scale(0.9);
          inset-inline-start: 0;
        }

        @media all and (max-width: 640px) {
          // transform: scale(1.1);
          inset-inline-start: 0;
        }

        @media all and (max-width: 480px) {
          // transform: scale(1);
          inset-inline-start: 0;
        }

        // &.scaleDown {
        //   transform: scale(0.9) translateX(-130px);
        //   // left: -5%;
        //   @media all and (max-width:980px){
        //     transform: scale(0.7);
        //     left: -15%;
        //   }
        //   @media all and (max-width:640px){
        //     transform: scale(1);
        //     left: 0;
        //   }
        //   @media all and (max-width:480px){
        //     transform: scale(1) translateX(0);
        //   left: 0;

        //   }
        // }

        @media all and (max-width: 980px) {
          font-size: 45px;
        }

        @media all and (max-width: 640px) {
          font-size: 25px;
        }
      }

      p.description {
        color: var(--text-color);
        font-size: 16px;
        max-width: 70%;
        position: relative;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);

        @media all and (max-width: 980px) {
          font-size: 13px;
          max-width: 59%;
        }

        @media all and (max-width: 640px) {
          max-width: 100%;
        }
      }

      div.buttonContainer {
        display: flex;
        margin-top: 20px;

        button {
          display: flex;
          padding: 7px 8px;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.2s ease-in-out;

          &:focus {
            border: 2px solid var(--white-color);
          }

          @media all and (max-width: 980px) {
            padding: 3px 7px;
          }

          @media all and (max-width: 640px) {
            padding: 5px 7px;
          }

          div.icon {
            margin-inline-end: 10px;

            svg {
              width: 27px !important;
              height: 27px !important;
              display: block;

              @media all and (max-width: 980px) {
                width: 20px !important;
                height: 28px !important;
              }

              @media all and (max-width: 640px) {
                width: 15px !important;
                height: 15px !important;
              }
            }

            img {
              width: 100%;
              display: block;
            }
          }

          span {
            font-size: 17px;
            font-weight: 500;

            @media all and (max-width: 980px) {
              font-size: 15px;
            }

            @media all and (max-width: 640px) {
              font-size: 12px;
            }
          }
        }

        button.play {
          width: 8rem;
          background-color: var(--primary-btn-color);
          margin-inline-end: 10px;
          border: 2px solid transparent;
          color: var(--black-color);

          &:hover {
            background-color: var(--primary-btn-hover);
          }

          svg {
            color: var(--black-color);
          }

          @media all and (max-width: 980px) {
            width: 7rem;
          }

          @media all and (max-width: 640px) {
            width: 6rem;
          }
        }

        button.moreInfo {
          width: 10rem;
          background-color: var(--secondary-btn-color);
          color: var(--text-color);

          &:hover {
            background-color: var(--secondary-btn-hover);
          }

          @media all and (max-width: 980px) {
            width: 8rem;
          }

          @media all and (max-width: 640px) {
            width: 7rem;
          }

          div.icon {
            svg {
              width: 25px !important;
              height: 25px !important;

              @media all and (max-width: 640px) {
                width: 18px !important;
                height: 18px !important;
              }
            }
          }
        }
      }
    }

    div.bottomGradient {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      background: linear-gradient(78.83deg,
          rgb(16, 20, 31) 0%,
          rgba(16, 20, 31, 0) 61.46%),
        linear-gradient(360deg,
          rgb(16, 20, 31) 0%,
          rgba(16, 20, 31, 0) 48.44%,
          rgba(16, 20, 31, 0.4) 100%);
    }
  }
}