div.subscriptionDetailsContainer {
    padding: 0 100px 10px 100px;

    color: whitesmoke;

    div.planContainer {
        box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;        
        border-radius: 8px;
        margin: 20px auto;
        padding: 20px;

        h3,h2{
            text-align: center;
            padding: 15px;
        }
        h3{
            font-weight: 200;
        }

        div.tableContainer {
            width: 100%;
            overflow: auto;

            table {

                
                border-collapse: collapse;
                @media all and (min-width:980px) {
                    width: 100%;
                }
            }

            tr{
                &:nth-child(2){
                    td{
                        &:nth-child(1){
                            min-width: 123px;
                            text-align: center;
                        }
                    }
                }
                &:nth-child(2n+1){
                    background-color: #3032385b;
                }
                &:nth-child(2n){
                    background-color: #2020215b;
                }
                &:nth-child(3){
                    background-color: #e9b85f30;
                }
            }
            td {
                text-align: center;
                padding: 15px 20px;
                min-width: 123px;
                &:nth-child(1){
                    min-width: 150px;
                    text-align: start;
                    border: none;
                }
                
            }

        }
    }
    @media all and (max-width:1024px){
        padding: 0 0 10px ;
    }
}