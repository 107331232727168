div.noscheduleContainer{
    div.overlayModal{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.closeIconContainer{
        position: fixed;
        top: 80px;
        inset-inline-end: 80px;
        z-index: 999;
        cursor: pointer;
        @media all and (max-width:980px){
            inset-inline-end: 60px;
        }
        svg{
            width: 25px !important;
            height: 25px !important;
            stroke:var(--text-color) !important;
        }
    }
    div.contents{
        z-index: 999;
        text-align: center;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background-color: var(--bg-color);
        width: 40%;
        margin: 0 auto;
        padding: 60px 60px 60px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        @media all and (max-width:1200px){
            padding: 30px;
        }
        @media all and (max-width:768px){
            width: 60%;
        }
        @media all and (max-width:480px){
            width: 90%;
        }
       h1{
        color: var(--inner-text-color);
        font-size: 22px;
       }
      
    }
}