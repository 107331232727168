div.episodeContainer {
  margin-top: 80px;
  div.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    div.left {
      h1.heading {
        font-size: 22px;
        color: var(--heading-color);
      }
    }
    div.right {
      div.seasonContainer {
        border: 0.1em solid rgb(77, 77, 77);;
           background-color: rgb(36, 36, 36);
        padding: 6px 12px;
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 3px;
        cursor: pointer;
        svg{
            width: 23px !important;
            height: 23px !important;
        }
        ul.listContainer {
          position: absolute;
          top: 45px;
          max-height: 150px;
          overflow-y: scroll;
          border: 0.1em solid rgb(77, 77, 77);;
             background-color: rgb(36, 36, 36);
          width: 200px;
          inset-inline-end: 0;
          z-index: 9;
          &.active{
            display: block;
          }
          &.noActive{
            display: none;
          }
          &::-webkit-scrollbar{
            width: 5px;
            border-radius: 30px;
          }
          &::-webkit-scrollbar-thumb{
            background-color: var(--grey-color);
            border-radius: 30px;
          }
          &::-webkit-scrollbar-track{
            background-color: rgb(36, 36, 36);
          }
          li {
            padding: 8px;
            font-size: 16px;
            font-weight: 600;
            &:hover{
                background-color: var(--bg-color);
            }
            span.episodes{
                font-size: 14px;
                font-weight: normal;
                margin-inline-start: 6px;
            }
          }
        }
      }
    }
  }
}
