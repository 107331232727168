div.latestNews {
  padding: 130px 0 80px;
  position: relative;
  div.head {
    display: flex;
    justify-content: end;
    @media all and (max-width: 640px) {
      padding-inline-end: 20px;
    }
    div.logoContainer {
      width: 150px;
      @media all and (max-width: 640px) {
        width: 120px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
  div.body {
    h1.heading {
      font-size: 34px;
      color: var(--white-color);
      margin-bottom: 30px;
      
    }
    div.gradientBg {
      background: url(./assets/Icons/gradient.svg) repeat-x center bottom;
      background-size: contain;
      position: absolute;
      inset-inline-start: 0;
      bottom: 0;
      width: 100%;
      height: 200px;
      div.buttonContainer{
        text-align: center;
        button.showMore {
          border: 1px solid var(--text-hover);
          background-color: transparent;
          color: var(--text-hover);
          padding: 6px 20px;
          font-size: 14px;
          border-radius: 6px;
          width: 15%;
          transition: 0.3s ease-in-out;
          margin: 140px auto 0;
          cursor: pointer;
          @media all and (max-width: 1200px) {
            width: 20%;
          }
          @media all and (max-width: 980px) {
            width: 25%;
          }
          @media all and (max-width: 769px) {
            width: 30%;
          }
          @media all and (max-width: 769px) {
            width: 30%;
          }
          @media all and (max-width: 640px) {
            width: 35%;
          }
          @media all and (max-width: 480px) {
            width: 45%;
          }
          @media all and (max-width: 360px) {
            width: 50%;
          }
          svg {
            transition: 0.3s ease-in-out;
            margin-inline-start: 6px;
          }
          &:hover {
            background-color: var(--text-hover);
            color: var(--white-color);
          }
  
         
        }
      }
    }
  }
}
