div.episodeCard{
    display: flex;
    padding: 25px;
    border-bottom: 1px solid var(--border-color);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    position: relative;
    @media all and (max-width:640px){
        flex-direction: column;
    }
    &.episodePortrait{
        align-items: center;
    }
    &:hover{
        background-color: #333;
    }
    &:hover{
        div.leftContainer{
            div.imageContainer{
                div.playIconContainer{
                    visibility: visible;
          animation: fadeIn 0.2s ease-in-out forwards;
                }
            }
        }
    }
    div.premiumStatus {
        div.premium {
          position: absolute;
          top: 6px;
          inset-inline-start: 6px;
          z-index: 8;
          background-color: rgba(8, 25, 43, 0.65);
          // padding: 6px 6px 3px;
          width: 35px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          @media all and (max-width: 1200px) {
            width: 26px;
          }
          svg {
            width: 18px !important;
            height: 18px !important;
            @media all and (max-width: 1200px) {
              width: 12px !important;
              heigth: 12px !important;
            }
          }
        }
        div.free {
          position: absolute;
          top: 6px;
          inset-inline-start: 6px;
          z-index: 8;
          width: 30px;
          @media all and (max-width: 1200px) {
            width: 30px;
          }
          img {
            display: block;
            width: 100%;
          }
        }
      }
    div.leftContainer{
        display: flex;
        align-items: center;
        width: 25%;
        margin-inline-end: 10px;

        @media all and (max-width:768px){
            margin-inline-end: 15px;
        }
        @media all and (max-width:640px){
            width: 80%;
            flex-direction: column;
            margin: 0 auto 20px;
        }
        @media all and (max-width:480px){
            width: 45%;
        }
        span.sNo{
            font-size: 22px;
            @media all and (max-width:640px){
                display: none;
            }
        }
        div.imageContainer{
            width: 110px;
            margin-inline-start: 18px;
            position: relative;
            @media all and (max-width:640px){
                margin: 0 auto;
            }
            img{
                width: 100%;
                display: block;
                border-radius: 3px;
            }
            div.playIconContainer{
                align-items: center;
                border: 1px solid var(--white-color);
                border-radius: 50%;
                background: rgba(30,30,20,.5);;
                cursor: pointer;
                display: flex;
                height: 40px;
                width: 40px;
                justify-content: center;
                left: 50%;
                position: absolute;
                top: 50%;
                -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                transition: .3s ease-in-out;
                z-index: 9;
                visibility: hidden;
                animation: fadeOut 0.2s ease-in-out forwards;
                svg{
                    width: 25px !important;
                    height: 25px !important;
                }
            }
            div.continueWatchingBar{
                position: absolute;
                bottom: 0;
                height: 0.2em;
                background-color: var(--grey-color);
                width: 100%;
                border-radius: 3px;
                div.line{
                  height: 100%; 
                border-radius: 3px;

                  background-color: var(--secondary-color);
                }
            }
        }
    }
    div.rightContainer{
        width: 68%;
        @media all and (max-width:640px){
            width: 100%;
        }
        div.top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 6px;
            div.left{
                @media all and (max-width:640px){
                    margin-inline-end: 20px;
                }
                h4{
                    font-size: 13px;
                    font-weight: 600;
                    color: var(--heading-color);
                    @media all and (max-width:480px){
                        font-size: 11px;
                    }
                }
            }
            div.right{
                span{
                    font-size: 15px;
                }
            }
        }
        div.bottom{
            p.description{
                font-size: 12px;
                color: var(--text-color);
                @media all and (max-width:480px){
                    font-size: 10px;
                }
                span.moreLess{
                    cursor: pointer;
                    color: var(--secondary-color);
                font-size: 11px;
                @media all and (max-width:480px){
                    font-size: 10px;
                }

                    &:hover{
                    text-decoration: underline;
                    }
                }
            }
    }
    }
}