div.liveChannelsContainer {
  background-color: var(--secondary-bg-color);
  position: relative;
  div.topContainer{
    display: flex;
    justify-content: space-between;
    position: relative;
    min-height: 580px;
    @media all and (max-width:1200px){
      min-height: 508px;
    }
    div.wrapper{
      display: flex;
      justify-content: space-between;
      padding-top: 100px;
      @media all and (max-width:980px){
        flex-direction: column-reverse;
      }
      @media all and (max-width:480px){
       width: 100%;
      }
    }
    div.bgImage{
      padding: 200px 0;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 800px;
    position: absolute;
    z-index: 3;
    bottom: 0;
    @media all and (max-width:1200px){
      padding: 200px 0 150px;
    }
    @media all and (max-width:980px){
      display: none;
    }
    }
    div.gradient {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -20px;
      z-index: 4;
      height: 500px;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 94.15%);
      // @media all and (max-width:980px){
      //  height: 465px;
      //  bottom: 162px;
      // }
      // @media all and (max-width:768px){
      //   bottom: 211px;
      //  }
      //  @media all and (max-width:640px){
      //   bottom: 284px;
      //  }
      
    }
    div.bannerContainer {
      position: relative;
      // height: 500px;
      margin-bottom: 60px;
      width: 55%;
      margin-inline-end: 30px;
      @media all and (max-width:980px){
        width: 100%;
      }
      
      div.info {
        color: var(--text-color);
        position: relative;
        z-index: 9;
        div.liveLabel {
          background-color: #f52d2d;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3px 3px;
          width: 3.5rem;
          border-radius: 3px;
          margin-bottom: 10px;
          span {
            font-weight: 600;
            font-size: 11px;
          }
          svg {
            width: 16px !important;
            height: 16px !important;
            margin-inline-end: 6px;
          }
        }
        div.channelLogo{
          width: 80px;
          height: 80px;
          margin-bottom: 10px;
          img{
            display: block;
              width: 100%;
              height: 100%;
              object-fit: contain;
          }
        }
        h1.title {
          font-size: 30px;
          margin-bottom: 15px;
        }
        div.additionalInfo {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          h6.timeLeft {
            color: var(--white-color);
            font-size: 15px;
            margin-inline-end: 15px;
          }
          span.caption {
            background-color: var(--white-color);
            color: var(--black-color);
            font-size: 10px;
            padding: 2px 4px;
            font-weight: 700;
            border-radius: 3px;
            outline: none;
            border: none;
            margin-inline-end: 15px;
          }
          span.rating {
            background-color: hsla(226, 7%, 61%, 0.32);
            border-radius: 4px;
            color: var(--white-color);
            color: var(--text-color);
            font-size: 12px;
            font-weight: 700;
            padding: 1px 8px;
          }
        }
        p.description {
          max-width: 100%;
          font-size: 15px;
          span.readMore,span.readLess{
            color: var(--button-color);
            margin-inline-start: 6px;
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
    div.videoContainer {
      inset-inline-end: 40px;
      z-index: 9;
      width: 70%;
      top: 90px;
      border-radius: 15px;
      @media all and (max-width:1600px){
        width: 70%;

      }
      @media all and (max-width:1400px){
        width: 60%;

      }
      @media all and (max-width:1200px){
        width: 60%;

      }
      @media all and (max-width:980px){
        margin-bottom: 20px;
        width: 100%;
      }
      @media all and (max-width:480px){
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        border-radius: 0;
        overflow: hidden; 
        padding: 0;
        margin: 0;  
      }
      div.video-js{
        padding-top: 0 !important;
        height: 408px;
        border-radius: 15px;
        @media all and (max-width:1200px){
          height: 242px;
        }
        @media all and (max-width:980px){
          height: 300px;
        }  
        @media all and (max-width:480px){
          position: relative;
          padding-top: 0 !important;
          width: 100%;
          height: 100%;
          border-radius: 0;
          object-fit: cover;
          margin: 0;
          padding: 0;
        }  
      }
      video {
        border-radius: 15px;
        @media all and (max-width:480px){
         border-radius: 0px;
         width: 100%;
        } 
      }

      div.videoImageContainer{
        width: 100%;
        background: var(--bg-color);
        div.bottomGradient {
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          z-index: 1;
          height: 92%;
          background-position: top;
          background-size: cover;
          background-repeat: no-repeat;
          background: linear-gradient(
              78.83deg,
              rgb(16, 20, 31) 0%,
              rgba(16, 20, 31, 0) 61.46%
            ),
            linear-gradient(
              360deg,
              rgb(16, 20, 31) 0%,
              rgba(16, 20, 31, 0) 15.22%,
              rgba(16, 20, 31, 0.4) 100%
            );


        }
        div.banner-image{
          img{
            width: 100%;
          }
        }


      }
    }  
  }

  div.banner-text{
    height: 75px;
    background-color: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin: 40px 5px 0px;
    @media all and (max-width:1200px){
      margin: 20px 5px 0px;
    }
    @media all and (max-width:980px){
      margin: 30px 5px 5px;
    }

    h2{
      font-size: 20px;
      color: var(--text-color);
      z-index: 9;
      font-weight: 400;
      @media all and (max-width:640px){
        font-size: 16px;
        margin-inline-start: 15px;
      }
      @media all and (max-width:360px){
        font-size: 13px;
      }

    }
    button{
      display: flex;
      padding: 18px 20px;
      border-radius: 4px;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      margin-inline-start: 2%;
      transition: 0.2s ease-in-out;
      justify-content: center;
      position: relative;
      width: 10rem;
      background-color: var(--button-color);
      color: var(--text-color);
      height: 3rem;
      z-index: 9;
      @media all and (max-width:640px){
        margin-inline-end: 3%;
      }
      @media all and (max-width:360px){
        margin-inline-end: 3%;
        width: 6rem;
        font-size: 16px;
      }

     
      &:hover{
            transform: scale(1.0476190476,1.0357142857);
            background-color: var(--button-hover);
      }
      &.fullscreen {
        width: 100vw;
        height: 100vh;
        top: 0;
        inset-inline-start: 0;
        position: fixed;
        z-index: 9999;
        background-color: var(--black-color);
      }
      &.rotate-fullscreen {
        @media all and (max-width: 480px) {
        transform: rotate(90deg) !important;
        transform-origin: center center !important;  
        width: 100vh !important;  
        height: 100vw !important;
        position: fixed !important;
        top: 0 !important;
        inset-inline-start: 0 !important;
        .vjs-tech  
          {  &.video-rotate{
              transform: rotate(90deg);
              width: 145%;
              left: -20%;
            }
        }
        .vjs-control-bar{
          transform: rotate(90deg);
          left: -64%;
          top: 48%;
          width: 145%;
        }
        .ima-ad-container,
        .ima-controls-div{
          transform: rotate(90deg);
        }

        .vjs-text-track-cue{
          transform: rotate(90deg);
          margin-left: -23%;
          margin-top: -63%;
          font: 25px sans-serif !important;
        
        }


        }
        @media all and (max-width: 360px) {
          transform: rotate(90deg) !important;
          transform-origin: center center !important;  
          width: 100vh !important;  
          height: 100vw !important;
          position: fixed !important;
          top: 0 !important;
          left: 0 !important;
          .vjs-tech  
            {  &.video-rotate{
                transform: rotate(90deg);
              }
          }
          .vjs-control-bar{
            transform: rotate(90deg);
            left: -65%;
            top: 48%;
          }
          .ima-ad-container,
          .ima-controls-div {
            transform: rotate(90deg);
          }
          .vjs-text-track-cue{
            transform: rotate(90deg);
            margin-left: -32%;
            margin-top: -80%;
           }
          }
      }
    }

    .videoContainer video.video-fullscreen {
      @media all and (max-width:480px){
        object-fit: contain;
        transform: rotate(90deg) !important;
        margin: 0;
        padding: 0;
        width: 180%;
        height: 100%;
        margin-inline-start: -40%;
      }
      @media all and (max-width:360px){
        transform: rotate(90deg) !important;
      }
    }
  }
  div.wrapper {
    overflow: hidden;

    div.categories {
      margin-bottom: 30px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      div.buttons{
        width: 1200px;
        button {
          &.active {
            background-color: var(--white-color);
            color: var(--black-color);
          }
          background-color: var(--black-color);
  
          padding: 5px 10px;
          color: var(--white-color);
          margin-inline-end: 10px;
          font-weight: 600;
          font-size: 13px;
          border-radius: 30px;
          cursor: pointer;
          &:last-child {
            margin-inline-end: 0;
          }
        }
      }
    }

    div.scheduleTime {
      width: 90%;
      margin: 0 0 6px auto;
      display: flex;
      align-items: center;
      position: relative;

      div.time {
        border-inline-start: 2px solid var(--border-color);
        padding-inline-start: 10px;
        width: 100%;
        span {
          color: var(--inner-text-color);
          font-size: 10px;
          font-weight: 700;
        }
      }
    }

    div.scheduleContainer {
      position: relative;
      div.rightGradient {
        position: absolute;
        top: 0;
        bottom: 0;
        inset-inline-end: 0;
        width: 10rem;
        z-index: 9;
        background: linear-gradient(95deg, rgba(0, 0, 0, -26.99), #000 101.15%);
      }
      div.bottomGradient {
        position: absolute;
        bottom: 0;
        inset-inline-end: 0;
        width: 10rem;
        z-index: 9;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 62.15%);
      }
      div.channel {
        display: flex;
        align-items: center;
        width: 3000px;
        height: 75px;
        margin-bottom: 4px;
        &.showTimeline {
          div.right {
            div.scheduledItem {
              &.active {
                div.progress {
                  div.currentTime {
                    position: absolute;
                    top: -26px;
                    inset-inline-end: 0;
                    background-color: #f52d2d;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    padding: 3px 3px;
                    width: 5rem;
                    border-radius: 3px;
                    color: var(--white-color);
                    span {
                      font-weight: 600;
                      font-size: 11px;
                    }
                    div.icon {
                      line-height: 0;
                      svg {
                        width: 16px !important;
                        height: 16px !important;
                        margin-inline-end: 6px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        div.left {
          width: 5%;
          margin-inline-end: 4px;
          height: 100%;
          div.currentPlayingCard {
            width: 100%;
            height: 100%;

            div.imageContainer {
              width: 100%;
              height: 100%;

              img {
                display: block;
                width: 100%;
                border-radius: 4px;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        div.right {
          display: flex;
          align-items: center;
          width: 95%;
          height: 100%;
          position: relative;

          div.scheduledItem {
            background-color: rgb(25, 25, 25);
            padding: 25px 20px;
            border-radius: 4px;
            margin-inline-end: 4px;
            height: 100%;
            // display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            &.active {
              border: 2px solid var(--text-hover);
              div.progress {
                display: block;
                position: absolute;
                // background: rgba(150,153,163,.16);
                background: linear-gradient(
                  95deg,
                  rgba(25, 25, 25, -26.99),
                  #363434 101.15%
                );

                width: 10%;
                top: 0;
                bottom: 0;
                inset-inline-start: 0;
                z-index: 9;
                div.currentTime {
                  display: none;
                }
              }
            }
            &:hover {
              div.metaData {
                display: flex;
              }
            }
            div.progress {
              display: none;
            }

            h1 {
              font-size: 16px;
              color: var(--white-color);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              position: relative;
              z-index: 9;
            }
            div.metaData {
              display: none;
              justify-content: space-between;
              p.description {
                position: absolute;
                z-index: 99;
                color: var(--inner-text-color);
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 90%;
              }
              div.infoIcon {
                text-align: end;
                position: absolute;
                z-index: 9999;
                inset-inline-end: 10px;
                top: 10px;
                svg{
                  color: var(--inner-text-color);
                  width: 20px !important;
                  height: 20px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  div.categoryAll{
    padding: 1px 0px 20px 0px;
    background-color:var(--black-color);
    div.categoryContainer{
      border: 1px solid var(--border-color);
      border-radius: 2px;
      width: 20rem;
      background: transparent;
      padding: 15px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      color: var(--text-color);
      cursor: pointer;
      margin-top: 2%;
      margin-inline-start: 20px;
      @media all and (max-width:980px){
        margin-top: 5%;
        margin-inline-start: 2px;
      }
      @media all and (max-width:480px){
        width: 98%;
        margin-top: 6%;
        margin-inline-start: 4px;
      }
      @media all and (max-width:360px){
        width: 97%;
        margin-top: 8%;
        margin-inline-start: 2px;
      }
     
      svg{
        width: 26px !important;
        height: 26px !important;
        transition: 0.3s ease-in-out;
        &.active{
          transform: rotate(-180deg);
        }
      }
      div.list{
        position: absolute;
        top: 55px;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 9;
        cursor: pointer;
        height: 300px;
        overflow-y: scroll;
        
        border: 1px solid var(--border-color);
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        display: none;
        &::-webkit-scrollbar{
          width: 5px;
          border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb{
          background-color: var(--bg-color);
          border-radius: 30px;
        }
        &::-webkit-scrollbar-track{
          background-color: var(--grey-color);
        }
        &.active{
          display: block;
          z-index: 99999;
        }
        div.item{
          width: 100%;
          padding: 15px 10px;
          border-bottom: 1px solid var(--border-color);
          background-color: #1c1f29;
         
          &:last-child{
  
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          span{
            font-size: 16px;
            color: var(--grey-color);
            &:hover{
              color: var(--white-color);
            }
          }
        }
      }
    }
  }
}

div.liveChannelsContainer.rtl{
  direction: ltr;
  text-align: left;
  .categoryAll{
    direction: rtl;
    text-align: right;
    // .categoryContainer{
    //   margin-left: unset;
    //   margin-right: 20px;
    // }
  }
  .topContainer{
    direction: rtl;
    text-align: right;
  }
}