div.homePageLiveContainer {
    margin-bottom: 40px;
    margin-top: 70px;
    div.videoBannerContainer{
      min-height: 610px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @media all and (max-width:768px) {
        min-height: 396px;
      }
      @media all and (max-width:640px) {
        min-height: 280px;
      }
      @media all and (max-width:480px) {
        min-height: 230px;
      }
    }
    div.videoContainertop{

      height: 450px;
      margin-bottom: 5px;
      @media all and (max-width:768px) {
        height: 396px;
      }
      @media all and (max-width:700px) {
        height: 363px;
      }
      @media all and (max-width:640px) {
        height: 331px;
      }
      @media all and (max-width:572px) {
        height: 296px;
      }
      @media all and (max-width:480px) {
        height: 250px;
      }
      @media all and (max-width:360px) {
        height: 187px;
      }
      div.videoContainer {
        max-width: 800px;
        margin: 0 auto;



    }
    }

    div.liveCardContainer {
        overflow: hidden;
        &:hover{
            div.categoryNameContainer {
                div.exploreAll {
            div.rightArrow {
                opacity: 1;
            }}}
        }
        div.categoryNameContainer {
            cursor: pointer;
            display: inline-block;
            align-items: center;
            h1 {
              font-size: 19px;
              color: var(--text-color);
              max-width: 99%;
              font-weight: 500;
              margin-bottom: 10px;
              @media all and (max-width: 980px) {
                font-size: 16px;
              }
            }
            &:hover {
              div.exploreAll {
                div.seeAll {
                  opacity: 1;
                  max-width: 100%;
                  transform: translateX(10px);
                }
                div.rightArrow {
                  svg {
                    width: 10px !important;
                    height: 10px !important;
                    transform: translateX(10px) rotate(-90deg);
                  }
                }
              }
            }
            div.content {
              display: flex;
            }
            div.exploreAll {
              color: #54b9c5;
              display: flex;
              align-items: center;
              margin-top: -6px;
              div.seeAll {
                cursor: pointer;
                display: inline-block;
                font-size: 0.9vw;
                line-height: 0.8vw;
                margin-inline-end: 4px;
                max-width: 0;
                opacity: 0;
                //  margin-left: 10px;
                font-weight: 500;
                transition: max-width 1s, opacity 1s, transform 0.75s;
                vertical-align: bottom;
                white-space: nowrap;
                transform: translateX(-10px);
                @media all and (max-width: 980px) {
                  font-size: 1vw;
                }
              }
              div.rightArrow {
                // &::before{
                //     content: "\e658";
                //     color: #54b9c5;
                //     display: block;
                // }
                color: #54b9c5;
                opacity: 0;
                transition: 0.3s ease-in-out;
                line-height: 0;
                svg {
                  transform: rotate(-90deg);
                  transition: 0.2s ease-in-out;
                  width: 20px !important;
                  height: 20px !important;
                }
              }
            }
          }
        h2.title {
            font-size: 19px;
            color: var(--text-color);
            max-width: 99%;
            font-weight: 500;
            margin-bottom: 10px;
        }

        div.imageCard {
            width: 129px;
            height: 72px;
            border-radius: 8px;
            overflow: hidden;
            background-color: white;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        margin-top: -8px;
    }
}