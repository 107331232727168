div.replyField {
    display: flex;
    width: 100%;
    padding-top: 30px;
    animation: fadeIn 0.4s ease-in-out;
    &.child{
      width: 92%;
      margin: 0 0 0 auto;
    }
    div.left{
      margin-right: 20px;
        div.imageContainer {
          width: 45px;
          height: 45px;
         
            img{
              width: 100%;
              display: block;
              height: 100%;
              border-radius: 50%;

          }
            span.nameAvatar {
              width: 100%;
              height: 45px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--white-color);
              background-color: green;
              border-radius: 50%;
            }
          }
    }
    div.right{
      width: 100%;
        div.inputContainer {
          textarea {
            display: block;
            width: 100%;
            height: 45px;
            padding: 10px 15px;
            border-radius: 5px;
            margin-bottom: 20px;
            &:focus-visible {
              border: 3px solid var(--button-color) !important;
              outline: none;
            }
          }
          div.actions {
            display: flex;
            justify-content: flex-end;
  
            p {
              font-size: 14px;
              color: var(--text-color);
              span {
                color: var(--grey-color);
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
  
            div.buttonContainer {
              button {
                background-color: var(--button-color);
                color: var(--white-color);
                font-weight: 600;
                padding: 10px 20px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
                border: 1px solid var(--button-color);
                transition: 0.4s ease-in-out;
                &:hover {
                  background-color: transparent;
                }
                &.cancel{
                  background-color: var(--grey-color);
                  border: 1px solid var(--grey-color);
                  margin-right: 20px;
                  &:hover{
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
    }
}