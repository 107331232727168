div.videoPlayer{
    background-color: var(--black-color);
    height: 100vh;
    position: relative;
    // overflow: hidden;
    div.topContainer{
        position: absolute;
        left: 3%;
        top: 5%;
        z-index: 99;
        display: flex;
        align-items: center;
        div.backButton{
            margin-right: 20px;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            &:hover{
                transform: scale(1.2);
            }
            svg{
                color: var(--white-color) !important;
                width: 35px !important;
                height: 35px !important;
            }
        }
        h1.title{
            color: var(--white-color);
            font-size: 28px;
        }
    }
    video{
        height: 100% !important;
        display: block !important;
        width: 100% !important;
        @media all and (max-width: 480px) {
      &.video-fullscreen {
        object-fit: contain;
        transform: rotate(90deg) !important;
        margin-left: -65px;
        padding: 0;
        width: 130% !important;
        height: 100%;
        left: 0;
      }
      
    }
    @media all and (max-width: 360px) {
      &.video-fullscreen {
        object-fit: contain;
        transform: rotate(90deg) !important;
        margin-left: -60px;
        padding: 0px;
        width: 140% !important;
        height: 100%;
      }
      
    }
    }
    &.fullscreen {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      background-color:var(--black-color);
    }
  
    &.rotate-fullscreen {
      @media all and (max-width: 480px) {
      transform: rotate(90deg) !important;
      transform-origin: center center !important;  
      width: 100vh !important;  
      height: 100vw !important;
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      .vjs-tech  
        {  &.video-rotate{
            transform: rotate(90deg);
            width: 150% !important;
            left: -24%;
            top: -2%;
          }
      }
      .vjs-control-bar{
        transform: rotate(90deg);
        left: -62%;
        top: 46%;
        width: 150%;
    
      }
      .ima-ad-container,
      .ima-controls-div{
        transform: rotate(90deg);
      }
      .vjs-text-track-cue{
        transform: rotate(90deg);
        margin-left: -43%;
        margin-top: -86%;
        font: 20px sans-serif !important;
      
      }
      }
      @media all and (max-width: 360px) {
        transform: rotate(90deg) !important;
        transform-origin: center center !important;  
        width: 100vh !important;  
        height: 100vw !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        .vjs-tech  
          {  &.video-rotate{
              transform: rotate(90deg);
              width: 160% !important;
              left: -27%;
            }
        }
        .vjs-control-bar{
          transform: rotate(90deg);
          left: -68%;
          top: 46%;
          width: 160%;
  
        }
        .ima-ad-container,
        .ima-controls-div{
          transform: rotate(90deg);
        }

        .vjs-text-track-cue{
          transform: rotate(90deg);
          margin-left: -41%;
          margin-top: -100%;
         }
        }
    
    }
    .vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
        padding-top: 0 !important; 
    }
    .vjs-fluid:not(.vjs-audio-only-mode) {
        padding-top: 0 !important; 
    }
    .video-js.vjs-fluid:not(.vjs-audio-only-mode){
        height: 100% !important;
    }
    .video-js.vjs-fluid:not(.vjs-audio-only-mode) video.video-fullscreen{
      @media all and (max-width:480px)
      {
        object-fit: contain;
        transform: rotate(90deg) !important;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        margin-left: 0%;
      }
    }
    @media all and (min-width:768px){
        &:hover {
          div.bottom {
            div.details {
              transform: translateY(0);
              
            }
          }
        }
      }
    div.bottom {
        width: 90%;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        height: 200px;
        overflow: hidden;
        @media all and (max-width:768px){
          position: relative;
          bottom: unset;
          left: unset;
          transform: unset;
          width: 100%;
          height: auto;
      }
  
        div.details {
          display: flex;
          justify-content: space-between;
          height: 100%;
          transform: translateY(200px);
          transition: 0.3s ease-in-out;
          background-color: rgba(41,36,35,0.6);
          padding: 20px;
          border-radius: 8px;
          @media all and (max-width:768px){
             flex-direction: column;
             transform: unset;
          }
         
  
          h1.heading {
            font-size: 28px;
            color: var(--text-color);
            margin-bottom: 10px;
            @media all and (max-width:768px){
              font-size: 22px;
           }
          }
          h1.title {
            font-size: 20px;
            color: var(--text-hover);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          //   width: 100%;
        
          }
          span.time{
              font-size: 14px;
              color: var(--inner-text-color);
              display: inline-block;
              margin-bottom: 10px;
          }
          p.description {
            color: var(--text-color);
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          div.left {
            width: 48%;
            height: 100%;
            @media all and (max-width:768px){
              width: 100%;
              margin-bottom: 20px;
           }
          }
          div.right {
            width: 48%;
            height: 100%;
            @media all and (max-width:768px){
              width: 100%;
           }
          }
        }
      }
}