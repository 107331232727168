*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Netflix Sans;
  font-weight: 300;
  src: url('./assets/Fonts/NetflixSans-Light.woff2') format("woff2"), url('./assets/Fonts/NetflixSans-Light.woff') format("woff"), url('./assets/Fonts/NetflixSans-Light.ttf') format("truetype")
}

@font-face {
  font-family: Netflix Sans;
  font-weight: 400;
  src: url('./assets/Fonts/NetflixSans-Regular.woff2') format("woff2"), url('./assets/Fonts/NetflixSans-Regular.woff') format("woff"), url('./assets/Fonts/NetflixSans-Regular.ttf') format("truetype")
}

@font-face {
  font-family: Netflix Sans;
  font-weight: 500;
  src: url('./assets/Fonts/NetflixSans-Medium.woff2') format("woff2"), url('./assets/Fonts/NetflixSans-Medium.woff') format("woff"), url('./assets/Fonts/NetflixSans-Medium.ttf') format("truetype")
}

@font-face {
  font-family: Netflix Sans;
  font-weight: 700;
  src: url('./assets/Fonts/NetflixSans-Bold.woff2') format("woff2"), url('./assets/Fonts/NetflixSans-Bold.woff') format("woff"), url('./assets/Fonts/NetflixSans-Bold.ttf') format("truetype")
}
@font-face {
  font-family: gota-regular;
  font-weight: 700;
  src: url('./assets/Fonts/Gota-Regular.woff2') format("woff2"), url('./assets/Fonts/Gota-Regular.woff') format("woff"), url('./assets/Fonts/Gota-Regular.ttf') format("truetype")
}
@font-face {
  font-family: Gagalin-Regular;
  font-weight: 700;
  src: url('./assets/Fonts/Gagalin-Regular.woff2') format("woff2"), url('./assets/Fonts/Gagalin-Regular.woff') format("woff"), url('./assets/Fonts/Gagalin-Regular.ttf') format("truetype")
}

@font-face {
  font-family: 'Avenir-Roman';
  src: url('./assets/Fonts/avenir-roman-maisfontes.d562/avenir-roman.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family:Netflix Sans,Helvetica, sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: , Helvetica, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
  color: #fff;
}
button{
  background-color: none;
  outline: none;
  border: none;
  font-family:Netflix Sans,Helvetica, sans-serif;

}
.rtl{
  direction: rtl;
  text-align: right;
}
