div.languageModal{
    div.overlayModal {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(32, 32, 38, 0.8);
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        &.root{
            background: var(--bg-color);
        }
      }
    div.closeIconContainer{
        position: fixed;
        top: 20px;
        inset-inline-end: 30px;
        z-index: 999;
        cursor: pointer;
        svg{
            width: 20px !important;
            height: 20px !important;
        }
    } 
    div.contents{
        z-index: 999;
        text-align: center;
        position: fixed;
        left: 50%;
        // right: 0;
        top: 50%;
        // bottom: 0;
        transform: translate(-50%,-50%);
        background-color: var(--white-color);
        width: 30%;
        margin: 0 auto;
        padding: 25px 25px 25px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        @media all and (max-width:1200px){
            padding: 30px;
            width: 40%;
        }
        @media all and (max-width:980px){
            width: 50%;
        }
        @media all and (max-width:768px){
            width: 60%;
        }
        @media all and (max-width:640px){
            width: 90%;
        }
        @media all and (max-width:480px){
            width: 90%;
        }
       h1{
        color: var(--button-color);
        font-size: 22px;
        margin-bottom: 10px;
       }
       div.inputContainer{
        width: 100%;
        margin-bottom: 20px;
        select{
            width: 100%;
            padding: 12px 10px;
            outline: none;
            border:1px solid rgb(231, 238, 239);
            color:var(--button-color);
            option{
                padding: 12px;
                
            }
        }
       }
       div.buttonContainer{
        button{
            width: 100%;
            display: block;
            padding: 10px;
            background-color: var(--button-color);
            color: var(--white-color);
            cursor: pointer;
            border-radius: 5px;
            border: 1px solid var(--button-color);
            transition: 0.4s ease-in-out;

            &:hover{
                background-color: transparent;
                color: var(--button-color);
            }
        }
       }
    }
}