div.searchPage{
    padding: 100px 5px 40px;
    background-color: var(--bg-color);
    div.top{
        border-bottom: 1px solid var(--border-color);
        h1.heading{
            font-size: 28px;
            margin-bottom: 10px;
            color: var(--heading-color);
            @media all and (max-width:640px){
                font-size: 26px;
            }
        }
        p.desc{
          font-size: 18px;
          color: var(--heading-color);
          margin-bottom: 15px;
          @media all and (max-width:640px){
              font-size: 14px;
          }
      }
       
    }
    div.bottom{
        margin-top: 10px;
        div.categories {
          display: flex;
          margin-bottom: 20px;
          button {
              background-color: transparent;
              outline: none;
              color: var(--white-color);
              padding: 8px 30px;
              border-radius: 30px;
              font-size: 16px;
              margin-inline-end: 10px;
              cursor: pointer;
              border:1px solid transparent;
              @media all and (max-width:768px){
                padding: 8px 15px;
            }
            @media all and (max-width:640px){
                padding: 8px 6px;
                font-size: 13px;
            }
            @media all and (max-width:480px){
                padding: 8px 8px;
                font-size: 10px;

            }
            @media all and (max-width:360px){
              padding: 8px 5px;
            }
              &:last-child{
                margin-inline-end: 0;
              }
              &.active {
              border:1px solid var(--grey-color);
              background-color:var(--secondary-btn-hover);
              color: var(--white-color);
              }
              &:hover {
                  background-color:var(--button-hover);
                  color: var(--grey-color);
              }
            }
      }
        div.itemsContainer{
            display: flex;
            flex-wrap: wrap;
            div.items {
              &.hidden {
                visibility: hidden;
              }
              &.rowCount3 {
                width: 32.5%;
                margin-inline-end: 13px;
                margin-bottom: 30px;
                &:nth-child(3n) {
                  margin-inline-end: 0;
                }
                @media all and (max-width: 1200px) {
                  width: 32%;
                }
                &.landscape {
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.1);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(3n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.1);
                          }
                        }
                      }
                      // &:last-child {
                      //   div.showCardContainer {
                      //     div.square {
                      //       transform: translateX(-60px) translateY(-60px) scale(1.1);
                      //     }
                      //   }
                      // }
                    }
                  }
      
                  // @media all and (max-width: 1400px) {
                  //   width: 16.2%;
                  // }
                  // @media all and (max-width: 1200px) {
                  //   width: 16.1%;
                  //   //
                  // }
                  @media all and (max-width: 980px) {
                    width: 24.3%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32.3%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48.2%;
      
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 480px) {
                    width: 100%;
                    margin-inline-end: 0;
                    &:nth-child(6n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
      
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                    @media all and (max-width: 360px) {
                      width: 100%;
      
                      &:nth-child(3n) {
                        margin-inline-end: 0;
                      }
                      margin-inline-end: 0;
                    }
                  }
                }
                &.portrait {
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(3n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                      @media all and (max-width: 1300px) {
                        &:nth-child(6n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(-60px) translateY(-60px)
                                scale(1.2);
                            }
                          }
                        }
                        &:nth-child(7n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(60px) translateY(-60px) scale(1.2);
                            }
                          }
                        }
                      }
                      &:last-child {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                  }
                  // width: 13.8%;
                  // &:nth-child(7n) {
                  //   margin-right: 0;
                  // }
                  // @media all and (max-width: 1300px) {
                  //   width: 16%;
                  //   &:nth-child(7n) {
                  //     margin-right: 6px;
                  //   }
                  //   &:nth-child(6n) {
                  //     margin-right: 0;
                  //   }
                  // }
                  @media all and (max-width: 980px) {
                    width: 24%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(5n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48%;
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                }
              }
              &.rowCount4 {
                width: 24%;
                margin-inline-end: 13px;
                margin-bottom: 30px;
                &:nth-child(4n) {
                  margin-inline-end: 0;
                }
                @media all and (max-width: 1200px) {
                  width: 23.8%;
                }
                &.landscape {
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.1);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(4n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.1);
                          }
                        }
                      }
                      // &:last-child {
                      //   div.showCardContainer {
                      //     div.square {
                      //       transform: translateX(-60px) translateY(-60px) scale(1.1);
                      //     }
                      //   }
                      // }
                    }
                  }
      
                  // @media all and (max-width: 1400px) {
                  //   width: 16.2%;
                  // }
                  // @media all and (max-width: 1200px) {
                  //   width: 16.1%;
                  //   //
                  // }
                  @media all and (max-width: 980px) {
                    width: 24.3%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32.3%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48.2%;
      
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 480px) {
                    width: 100%;
                    margin-inline-end: 0;
                    &:nth-child(6n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
      
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                    @media all and (max-width: 360px) {
                      width: 100%;
      
                      &:nth-child(3n) {
                        margin-inline-end: 0;
                      }
                      margin-inline-end: 0;
                    }
                  }
                }
                &.portrait {
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(4n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                      @media all and (max-width: 1300px) {
                        &:nth-child(6n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(-60px) translateY(-60px)
                                scale(1.2);
                            }
                          }
                        }
                        &:nth-child(7n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(60px) translateY(-60px) scale(1.2);
                            }
                          }
                        }
                      }
                      &:last-child {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                  }
                  // width: 13.8%;
                  // &:nth-child(7n) {
                  //   margin-right: 0;
                  // }
                  // @media all and (max-width: 1300px) {
                  //   width: 16%;
                  //   &:nth-child(7n) {
                  //     margin-right: 6px;
                  //   }
                  //   &:nth-child(6n) {
                  //     margin-right: 0;
                  //   }
                  // }
                  @media all and (max-width: 980px) {
                    width: 24%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(5n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48%;
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                }
              }
              &.rowCount5 {
                width: 19.3%;
                margin-inline-end: 6px;
                margin-bottom: 30px;
                &:nth-child(5n) {
                  margin-inline-end: 0;
                }
                @media all and (max-width: 1200px) {
                  width: 19.3%;
                }
                &.landscape {
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.1);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(5n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.1);
                          }
                        }
                      }
                      // &:last-child {
                      //   div.showCardContainer {
                      //     div.square {
                      //       transform: translateX(-60px) translateY(-60px) scale(1.1);
                      //     }
                      //   }
                      // }
                    }
                  }
      
                  // @media all and (max-width: 1400px) {
                  //   width: 16.2%;
                  // }
                  // @media all and (max-width: 1200px) {
                  //   width: 16.1%;
                  //   //
                  // }
                  @media all and (max-width: 980px) {
                    width: 24.3%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32.3%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48.2%;
      
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 480px) {
                    width: 100%;
                    margin-inline-end: 0;
                    &:nth-child(6n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
      
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                    @media all and (max-width: 360px) {
                      width: 100%;
      
                      &:nth-child(3n) {
                        margin-inline-end: 0;
                      }
                      margin-inline-end: 0;
                    }
                  }
                }
                &.portrait {
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(5n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                      @media all and (max-width: 1300px) {
                        &:nth-child(6n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(-60px) translateY(-60px)
                                scale(1.2);
                            }
                          }
                        }
                        &:nth-child(7n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(60px) translateY(-60px) scale(1.2);
                            }
                          }
                        }
                      }
                      &:last-child {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                  }
                  // width: 13.8%;
                  // &:nth-child(7n) {
                  //   margin-right: 0;
                  // }
                  // @media all and (max-width: 1300px) {
                  //   width: 16%;
                  //   &:nth-child(7n) {
                  //     margin-right: 6px;
                  //   }
                  //   &:nth-child(6n) {
                  //     margin-right: 0;
                  //   }
                  // }
                  @media all and (max-width: 980px) {
                    width: 24%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(5n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48%;
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                }
              }
              &.rowCount6 {
                width: 16%;
                margin-inline-end: 6px;
                margin-bottom: 30px;
                &:nth-child(6n){
                  margin-inline-end: 0;
                }
                @media all and (max-width:1400px){
                    width: 16%;
                }
                @media all and (max-width:1200px){
                    width: 16%;
                }
                &.landscape {
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.1);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(6n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.1);
                          }
                        }
                      }
                      // &:last-child {
                      //   div.showCardContainer {
                      //     div.square {
                      //       transform: translateX(-60px) translateY(-60px) scale(1.1);
                      //     }
                      //   }
                      // }
                    }
                  }
      
                  // @media all and (max-width: 1400px) {
                  //   width: 16.2%;
                  // }
                  // @media all and (max-width: 1200px) {
                  //   width: 16.1%;
                  //   //
                  // }
                  @media all and (max-width: 980px) {
                    width: 24.3%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32.3%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48.2%;
      
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 480px) {
                    width: 100%;
                    margin-inline-end: 0;
                    &:nth-child(6n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
      
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                    @media all and (max-width: 360px) {
                      width: 100%;
      
                      &:nth-child(3n) {
                        margin-inline-end: 0;
                      }
                      margin-inline-end: 0;
                    }
                  }
                }
                &.portrait {
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(6n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                      @media all and (max-width: 1300px) {
                        &:nth-child(6n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(-60px) translateY(-60px)
                                scale(1.2);
                            }
                          }
                        }
                        &:nth-child(7n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(60px) translateY(-60px) scale(1.2);
                            }
                          }
                        }
                      }
                      &:last-child {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                  }
                  // width: 13.8%;
                  // &:nth-child(7n) {
                  //   margin-right: 0;
                  // }
                  // @media all and (max-width: 1300px) {
                  //   width: 16%;
                  //   &:nth-child(7n) {
                  //     margin-right: 6px;
                  //   }
                  //   &:nth-child(6n) {
                  //     margin-right: 0;
                  //   }
                  // }
                  @media all and (max-width: 980px) {
                    width: 24%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(5n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48%;
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                }
              }
              &.rowCount7 {
                width: 13.9%;
                margin-inline-end: 6px;
                margin-bottom: 30px;
                &:nth-child(7n){
                  margin-inline-end: 0;
                }
                &.landscape {
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.1);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(7n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.1);
                          }
                        }
                      }
                     
                    }
                  }
      
                  
                  @media all and (max-width: 980px) {
                    width: 24.3%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32.3%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48.2%;
      
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 480px) {
                    width: 100%;
                    margin-inline-end: 0;
                    &:nth-child(6n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
      
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                    @media all and (max-width: 360px) {
                      width: 100%;
      
                      &:nth-child(3n) {
                        margin-inline-end: 0;
                      }
                      margin-inline-end: 0;
                    }
                  }
                }
                &.portrait {
                 @media all and (max-width: 2600px) {
                  width: 14%;
                  margin-inline-end: 7px;
                    &:nth-child(7n){
                      margin-inline-end: 0;
                  }
                  }
                  @media all and (max-width: 2500px) {
                    width: 14%;
                    margin-inline-end: 6.5px;
                  }
                  @media all and (max-width: 2200px) {
                    width: 14%;
                    margin-inline-end: 6px;
                  }
                  @media all and (max-width: 1980px) {
                    width: 14%;
                    margin-inline-end: 5px;
                  }
                  @media all and (max-width: 1768px) {
                    width: 14%;
                    margin-inline-end: 4.8px;
                  }
                  @media all and (max-width: 1600px) {
                    width: 13.8%;
                    margin-inline-end: 7px;
                  }
                  @media all and (max-width: 1400px) {
                    width: 14%;
                    margin-inline-end: 3px;
                  }
                  @media all and (max-width: 1200px) {
                    width: 13.7%;
                    margin-inline-end: 6.1px;
                  }
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(6n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                      @media all and (max-width: 1300px) {
                        &:nth-child(6n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(-60px) translateY(-60px)
                                scale(1.2);
                            }
                          }
                        }
                        &:nth-child(7n) {
                          div.showCardContainer {
                            div.square {
                              transform: translateX(60px) translateY(-60px) scale(1.2);
                            }
                          }
                        }
                      }
                      &:last-child {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                  }
                 
                  @media all and (max-width: 980px) {
                    width: 24%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(5n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48%;
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                }
              }
              &.rowCount8 {
                width: 12%;
                margin-inline-end: 8px;
                margin-bottom: 30px;
                &:nth-child(8n) {
                  margin-inline-end: 0;
                }
                
                &.landscape {
                  @media all and (max-width: 2500px) {
                    width: 12%;
                    margin-inline-end: 10px;
                  }
                  @media all and (max-width: 2200px) {
                    width: 12%;
                    margin-inline-end: 9px;
                  }
                  @media all and (max-width: 1980px) {
                    width: 12%;
                    margin-inline-end: 8px;
                  }
                  @media all and (max-width: 1768px) {
                   width: 12%;
                   margin-inline-end: 7px;
                  }
                  @media all and (max-width: 1600px) {
                    width: 12%;
                    margin-inline-end: 6px;
                  }
                  @media all and (max-width: 1400px) {
                    width: 12%;
                    margin-inline-end: 5px;
                  }
                  @media all and (max-width: 1200px) {
                    width: 11.9%;
                    margin-inline-end: 5px;
                  }
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.1);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(5n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.1);
                          }
                        }
                      }
                     
                    }
                  }
      
                 
                  @media all and (max-width: 980px) {
                    width: 24.3%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32.3%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48.2%;
      
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 480px) {
                    width: 100%;
                    margin-inline-end: 0;
                    &:nth-child(6n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
      
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                    @media all and (max-width: 360px) {
                      width: 100%;
      
                      &:nth-child(3n) {
                        margin-inline-end: 0;
                      }
                      margin-inline-end: 0;
                    }
                  }
                }
                &.portrait {
                   width: 11%;
                   margin-inline-end: 21px;
                @media all and (max-width: 2600px) {
                    width: 12%;
                    margin-inline-end: 13px;
                    &:nth-child(8n){
                      margin-inline-end: 0;
                  }
                  }
                  @media all and (max-width: 2500px) {
                    width: 12%;
                    margin-inline-end: 11px;
                  }
                  @media all and (max-width: 2200px) {
                    width: 12%;
                    margin-inline-end: 10px;
                  }
                  @media all and (max-width: 1980px) {
                    width: 12%;
                    margin-inline-end: 9px;
                  }
                  @media all and (max-width: 1768px) {
                   width: 12%;
                   margin-inline-end: 8px;
                  }
                  @media all and (max-width: 1600px) {
                    width: 12%;
                    margin-inline-end: 7px;
                  }
                  @media all and (max-width: 1400px) {
                    width: 12%;
                    margin-inline-end: 6px;
                  }
                  @media all and (max-width: 1200px) {
                    width: 11.9%;
                    margin-inline-end: 6px;
                  }
                  @media all and (min-width: 981px) {
                    &:hover {
                      div.showCardContainer {
                        .square {
                          position: absolute;
                          z-index: 9999999;
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                          div.metaData {
                            display: block;
                            visibility: visible !important;
                            animation: fadeIn 0.3s ease-in-out;
                          }
                        }
                      }
                      &:nth-child(8n) {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                     
                      &:last-child {
                        div.showCardContainer {
                          div.square {
                            transform: translateX(-60px) translateY(-60px) scale(1.2);
                          }
                        }
                      }
                    }
                  }
                 
                  @media all and (max-width: 980px) {
                    width: 24%;
                    margin-inline-end: 6px;
                    margin-bottom: 40px;
                    &:nth-child(5n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(4n) {
                      margin-inline-end: 0;
                    }
                    &:nth-child(6n) {
                      margin-inline-end: 6px;
                    }
                  }
                  @media all and (max-width: 768px) {
                    width: 32%;
                    &:nth-child(4n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                  }
                  @media all and (max-width: 640px) {
                    width: 48%;
                    &:nth-child(3n) {
                      margin-inline-end: 6px;
                    }
                    &:nth-child(2n) {
                      margin-inline-end: 0;
                    }
                  }
                }
              }
              &.landscape {
                @media all and (max-width: 980px) {
                  width: 24.3%;
                  margin-inline-end: 6px;
                  margin-bottom: 40px;
                  &:nth-child(6n) {
                    margin-inline-end: 6px;
                  }
                  &:nth-child(4n) {
                    margin-inline-end: 0;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32.3%;
                  &:nth-child(4n) {
                    margin-inline-end: 6px;
                  }
                  &:nth-child(3n) {
                    margin-inline-end: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48.2%;
      
                  &:nth-child(3n) {
                    margin-inline-end: 6px;
                  }
                  &:nth-child(2n) {
                    margin-inline-end: 0;
                  }
                }
                @media all and (max-width: 480px) {
                  width: 100%;
                  margin-inline-end: 0;
                  &:nth-child(6n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(4n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(3n) {
                    margin-inline-end: 0;
                  }
      
                  &:nth-child(2n) {
                    margin-inline-end: 0;
                  }
                  @media all and (max-width: 360px) {
                    width: 100%;
      
                    &:nth-child(3n) {
                      margin-inline-end: 0;
                    }
                    margin-inline-end: 0;
                  }
                }
              }
              &.portrait {
              @media all and (max-width: 980px) {
                  width: 24%;
                  margin-inline-end: 6px;
                  margin-bottom: 40px;
                  &:nth-child(5n) {
                    margin-inline-end: 6px;
                  }
                  &:nth-child(4n) {
                    margin-inline-end: 0;
                  }
                  &:nth-child(6n) {
                    margin-inline-end: 6px;
                  }
                }
                @media all and (max-width: 768px) {
                  width: 32%;
                  &:nth-child(4n) {
                    margin-inline-end: 6px;
                  }
                  &:nth-child(3n) {
                    margin-inline-end: 0;
                  }
                }
                @media all and (max-width: 640px) {
                  width: 48%;
                  &:nth-child(3n) {
                    margin-inline-end: 6px;
                  }
                  &:nth-child(2n) {
                    margin-inline-end: 0;
                  }
                }
              }
            }
        }
    }
}
